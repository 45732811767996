import * as React from "react"

const EJDLVMini = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 26 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.467 9h-1.092a.4.4 0 0 0-.005 0h-1.014c-.052 0-.1.02-.13.053l-2.229 2.534-1.431-1.331a.178.178 0 0 0-.12-.044H7.49a.174.174 0 0 0-.126.05L3.11 14.758H1.156c-.086 0-.156.054-.156.12 0 .068.07.122.156.122H3.19c.05 0 .096-.018.126-.05l4.254-4.495h.573l-1.81 1.964c-.026.028-.018.065.018.085.035.02.084.013.109-.014l1.875-2.035h.512L7.818 11.57c-.025.027-.017.065.018.084.036.02.084.014.11-.014l1.093-1.186h.334l1.44 1.34-1.417 1.56c-.05.054-.033.13.038.168.07.039.168.026.218-.029l1.486-1.636.001-.002 2.3-2.614h.647l-2.66 3.208c-.047.056-.026.13.047.167.072.036.17.02.216-.036l2.769-3.339h.658l-1.904 2.27c-.023.028-.013.066.023.084.036.018.085.01.108-.018l1.96-2.336h.079l4.724 5.702a.172.172 0 0 0 .132.056h3.98c.086 0 .156-.054.156-.121s-.07-.121-.156-.121h-3.895L17.46 11.3l1.21-1.15h.432l-.805.937c-.024.028-.014.065.022.084.035.018.084.01.108-.017l.863-1.003h.09l3.316 3.286a.188.188 0 0 0 .22.021c.068-.041.08-.117.027-.17l-3.363-3.333a.176.176 0 0 0-.123-.047h-.86a.177.177 0 0 0-.122.045l-1.193 1.132-1.682-2.03A.172.172 0 0 0 15.467 9Z"
      fill="#5D7086"
    />
    <path
      d="M24.374 15c.087 0 .157-.054.157-.121s-.07-.121-.157-.121m.157.242c.086 0 .156-.054.156-.121s-.07-.121-.156-.121m.156.242c.087 0 .157-.054.157-.121s-.07-.121-.157-.121m.157.242c.086 0 .156-.054.156-.121s-.07-.121-.156-.121M14.375 9h1.092c.054 0 .103.021.132.056l1.682 2.03 1.193-1.132a.177.177 0 0 1 .122-.045h.86c.048 0 .093.017.123.047l3.363 3.333c.053.053.04.129-.027.17a.188.188 0 0 1-.22-.02l-3.316-3.287h-.09l-.863 1.003c-.024.028-.072.035-.108.017-.036-.019-.046-.056-.022-.084l.805-.936h-.431L17.46 11.3l2.863 3.457h3.895c.086 0 .156.054.156.12 0 .068-.07.122-.156.122h-3.98a.172.172 0 0 1-.132-.056l-4.724-5.702h-.08l-1.959 2.336c-.023.029-.072.037-.108.018-.036-.018-.046-.056-.023-.084l1.904-2.27h-.658l-2.77 3.339c-.046.056-.143.072-.215.036-.073-.036-.094-.111-.047-.167l2.66-3.208h-.647l-2.3 2.614-.001.002-1.486 1.636c-.05.055-.147.068-.218.03-.07-.04-.088-.115-.038-.17l1.417-1.56-1.44-1.34h-.334l-1.094 1.187c-.025.027-.073.034-.109.014-.035-.02-.043-.057-.018-.084l1.029-1.116h-.512L6.46 12.49c-.025.027-.074.033-.11.014-.035-.02-.043-.057-.018-.085l1.811-1.964H7.57L3.316 14.95a.175.175 0 0 1-.126.05H1.156C1.07 15 1 14.946 1 14.879s.07-.121.156-.121H3.11l4.255-4.496a.174.174 0 0 1 .126-.05h1.955c.046 0 .09.016.12.044l1.431 1.33 2.23-2.533A.173.173 0 0 1 13.356 9h1.014a.4.4 0 0 1 .005 0Z"
      stroke="#5D7086"
      strokeWidth={0.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EJDLVMini
