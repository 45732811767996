import { useMemo, useState, useEffect } from "react"
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import styled from "styled-components"
import dayjs from "dayjs"
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import ScrollContainer from "react-indiana-drag-scroll"
import { supabase } from '../../../supabaseClient'

import * as Styles from "./CDashboardStyles"
import './CDashboard.scss'
import { Info, Search, Received, FacebookCircled, Twitter, Instagram  } from '../../../assets/icons/Index'
import { useWindowSize } from "../../../hooks/useWindowSize/useWindowSize"
import { OccupancyChart, EconomyChart, ECategory, ESubCat, ICategory } from './Charts/Index'

const ChatPillNavLink = styled(NavLink)`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  background: none;
  text-decoration: none;
  background-color: var(--neutral-light_2);
  border-radius: clamp(12px, 16px, 1.25vw);
  padding: clamp(8px, 12px, 1.25vw);
  cursor: pointer;

  div:nth-child(1) {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    gap: 12px;
    background: none;

    div:nth-child(1) {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: none;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }

    div:nth-child(2) {
      display: flex;
      position: relative;
      flex-direction: column:
      flex-wrap: nowrap;
      width: fit-content;
      max-width: 120px;
      height: fit-content;
      align-items: flex-start;
      justify-content: left;
      gap: 4px;
      background: none;
      overflow: hidden;
      
  
      h5 {
        font-style: normal;
        font-size: 12px;
        font-weight: 700;
        text-align: left;
        color: #374758;
        background: none;
        text-transform: capitalize;
      }
  
      p {
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #5D7086;
        background: none;
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  div:nth-child(2) {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    gap: 4px;
    background: none;

    p {
      font-style: normal;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #5D7086;
      background: none;
      text-transform: capitalize;
    }

    div {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      height: fit-content;
      align-items: flex-start;
      justify-content: space-between;
      background: none;

      svg {
        font-size: 1em;
        color: #5D7086;
        background: none;
      }

      div {
        width: 16px;
        height: 16px;
        background-color: #7D83FF;
        align-items: center;
        justify-content: center;
        border-radius: 50%;


        p {
          font-style: normal;
          font-size: 10px;
          font-weight: 700;
          color: #fff;
          background: none;
        }
      }
    }
  }
`

const BubbleLink =  styled(NavLink)`
  display: flex;
  position:relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  background: none;
  cursor: pointer;

  div {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #D4E1F0;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  p {
    font-style: normal;
    font-size: 12PX;
    font-weight: 500;
    color: #374758;
    text-align: center;
    background: none;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const CDashboard = ({ session }) => {
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);
  const currentDay = useMemo(() => dayjs().toDate(), [])

  const [supabaseloaded, setSupabaseLoaded] = useState(false);
  const [gettingrooms, setGettingRooms] = useState(true);
  const [gettingclientsrooms, setGettingClientsRooms] = useState(true);
  const [gettingmessages, setGettingMessages] = useState(true);
  const [noactiverooms, setNoActiveRooms] = useState(null);
  const [roomsdata, setRoomsData] = useState(null);
  const [noclientsroomsdata, setNoClientsRoomsData] = useState(null);
  const [clientsroomsdata, setClientsRoomsData] = useState(null);
  const [nomessages, setNoMessages] = useState(null);
  const [allmessagesdata, setAllMessagesData] = useState([]);

  useEffect(() => {
    getRooms()
  }, [])

  const getRooms = async () => {
    setSupabaseLoaded(false);
    setGettingRooms(true);
    try {
      let { data, error, status } =  await supabase
        .from('rooms')
        .select()
        .match({
          profile_id: session.user.id,
          role: 'admin'
        })
        .order('created_at', {ascending: true})
      
      if (data.length === 0) {
        setNoActiveRooms(true);
      }

      if (error) {
        throw error
      }

      if (data.length !== 0 ) {
        setRoomsData(data);
        setNoActiveRooms(false);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingRooms(false);
    }
  }

  useEffect(() => {
    if (gettingrooms === false) {
      getClientsRooms();
    }
  }, [gettingrooms])

  const getClientsRooms = async () => {
    setGettingClientsRooms(true);
    try {
      let { data, error, status } =  await supabase
        .from('rooms')
        .select(`
          *,
          profiles(
            *
          )
        `)
        .neq('role', 'admin')
        .order('created_at', {ascending: true})


      if (data.length === 0) {
        setNoClientsRoomsData(true);
      }

      if (error) {
        setNoClientsRoomsData(true);
      }

      if (data.length !== 0) {
        setClientsRoomsData(data)
      }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingClientsRooms(false);
    }
  }

  useEffect(() => {
    if (gettingclientsrooms === false) {
      getMessages()
    }
  }, [gettingclientsrooms])

  const getMessages = async () => {
    setGettingMessages(true);

    try {
      let { data, error, status } = await supabase
        .from('messages')
        .select()
        .order('created_at', {ascending: false})

      if (data.length === 0) {
        setNoMessages(true);
      }

      if (error) {
        setNoMessages(true);
      }

      if (data.length !== 0) {
        setNoMessages(false);
        setAllMessagesData(data);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingMessages(false);
      setSupabaseLoaded(true);
    }
  }

  return (
    <>
      {supabaseloaded ?

      (
        <Styles.MainWrapper
          as={motion.div}
          initial={{
            opacity: 0
          }}
          whileInView={{
            opacity: 1
          }}
          transition={{
            duration: 0.4
          }}
          viewport={{
            once: true
          }}
        >
          <Styles.RowsWrapper>
            <Styles.ChatsWrapper>
              <Styles.ChatsHeaderWrapper>
                <Styles.ChatsHeader>
                  <p>
                    Hola Benjamín,
                  </p>
                  <h3>
                    Últimos Chats.
                  </h3>
                </Styles.ChatsHeader>
                {/* <Styles.ChatsBubblesWrapper>
                  <div>
                    <div>
                      <Search />
                    </div>
                    <p>
                      Buscar
                    </p>
                  </div>
                  {
                    roomsdata.map((roomsdata) => (
                      <>
                        <BubbleLink key={roomsdata.id} to="/chat" state={{ roomid: roomsdata.id, profileid: clientsroomsdata.filter((f) => roomsdata.id === f.id).map((f) => f.profiles.id) }}
                        >
                          <div>
                            <img 
                              src={
                                clientsroomsdata.filter(
                                  (f) => roomsdata.id === f.id
                                ).map(
                                  (f) => f.profiles.avatar_url
                                )
                              }
                              alt="Chat Bubbles Avatar"
                            />
                          </div>
                          <p>
                            {
                              clientsroomsdata.filter(
                                (f) => roomsdata.id === f.id
                              ).map(
                                (f) => f.profiles.username
                              )
                            }
                          </p>
                        </BubbleLink>
                      </>
                    ))
                  }
                </Styles.ChatsBubblesWrapper> */}
              </Styles.ChatsHeaderWrapper>
              <ScrollContainer className="chatscard__wrapper">
                {
                  roomsdata.map((roomsdata) => (
                    <ChatPillNavLink key={roomsdata.id} to="/chat" state={{ roomid: roomsdata.id, profileid: clientsroomsdata.filter((f) => roomsdata.id === f.id).map((f) => f.profiles.id) }}
                    >
                      <div>
                        <div>
                          <img 
                            src={
                              clientsroomsdata.filter(
                                (f) => roomsdata.id === f.id
                              ).map(
                                (f) => f.profiles.avatar_url
                              )
                            } 
                            alt="Chat Bubble Avatar" 
                          />
                        </div>
                        <div>
                          <h5>
                            {
                              clientsroomsdata.filter(
                                (f) => roomsdata.id === f.id
                              ).map(
                                (f) => f.profiles.username
                              )
                            }
                          </h5>
                          <p>
                            {
                              allmessagesdata.find(
                                (f) => (roomsdata.id === f.room_id && f.profile_id !== '5bda195a-33f9-4719-988b-9d89bedd3536' && f.profile_id !== 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                              )?.content
                            }
                          </p>
                        </div>
                      </div>
                      <div>
                        <p>
                            Hace{' '}
                            {
                              dayjs(currentDay).diff(dayjs(allmessagesdata.find(
                                (f) => (roomsdata.id === f.room_id && f.profile_id !== '5bda195a-33f9-4719-988b-9d89bedd3536' && f.profile_id !== 'd2c01f1b-3392-4da2-bb47-598532a521a5') 
                              )?.created_at), 'hour')
                            }
                            {' '}horas
                        </p>
                        <div>
                          <Received />
                          <FacebookCircled 
                            style={{
                              display: clientsroomsdata.some(
                                (s) => (roomsdata.id === s.id && s.profiles.provider === 'facebook')
                              )
                              ? 'flex'
                              : 'none'
                            }}
                          />
                          <Twitter 
                            style={{
                              display: clientsroomsdata.some(
                                (s) => (roomsdata.id === s.id && s.profiles.provider === 'twitter')
                              )
                              ? 'flex'
                              : 'none'
                            }}
                          />
                          <div>
                            <p>1</p>
                          </div>
                        </div>
                      </div>
                      
                    </ChatPillNavLink>
                  ))
                }
                  <div className='chatscard_wrapper-footer'>
                      <p>
                        no hay más conversaciones
                      </p>
                  </div>
              </ScrollContainer>
            </Styles.ChatsWrapper>
              <Styles.ChartCard>
                <Styles.ChartHeader>
                  <h3>
                    ocupación
                  </h3>
                  <Info />
                </Styles.ChartHeader>
                <Styles.ChartComponentContainer>
                  <OccupancyChart session={session} />
                </Styles.ChartComponentContainer>
              </Styles.ChartCard>
              <Styles.ChartCard>
                <Styles.ChartHeader>
                  <h3>
                    economía
                  </h3>
                  <Info />
                </Styles.ChartHeader>
                <Styles.ChartComponentContainer>
                  <EconomyChart session={session} />
                </Styles.ChartComponentContainer>
              </Styles.ChartCard>
          </Styles.RowsWrapper>
          <Styles.RowsWrapper style={{ order: '1' }} >
            <Styles.ChartCard>
                <Styles.ChartHeader>
                  <h3>
                    gastos por area
                  </h3>
                  <Info />
                </Styles.ChartHeader>
                <Styles.ChartComponentContainer>
                  <ECategory session={session} />
                </Styles.ChartComponentContainer>
              </Styles.ChartCard>
            <Styles.ChartCard style={{ zIndex: '1' }} >
                <Styles.ChartHeader>
                  <h3>
                    gastos por Categoría
                  </h3>
                  <Info />
                </Styles.ChartHeader>
                <Styles.ChartComponentContainer>
                  <ESubCat session={session} />
                </Styles.ChartComponentContainer>
              </Styles.ChartCard>
            <Styles.ChartCard style={{ zIndex: '0' }} >
                <Styles.ChartHeader>
                  <h3>
                    Ingresos por tipo
                  </h3>
                  <Info />
                </Styles.ChartHeader>
                <Styles.ChartComponentContainer>
                  <ICategory session={session} />
                </Styles.ChartComponentContainer>
              </Styles.ChartCard>
          </Styles.RowsWrapper>
        </Styles.MainWrapper>
      )
      :
      (
        <div className='calendar-loader'></div>
      )
      }
    </>
  )
}

export default CDashboard;