import { useState } from 'react'
import { supabase } from '../../supabaseClient'

import { LogoHorizontal, FacebookCircled, Twitter} from '../../assets/icons/Index'
import './Auth.scss'

export default function Auth({ session }) {
    const [loading, setLoading] = useState(false);

    const handleFBLogin = async () => {
       try {
            setLoading(true);
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'facebook',
                options: {
                    scopes: 'email, public_profile',
                    queryParams: {
                        picture: {
                            data: {
                                weight: 9999,
                                heigh: 9999,
                            }
                        }
                    },
                    redirectTo: 'https://admin.eljardindelosvolcanes.com/'
                },
            })

       } catch (error) {
        alert(error.error_description || error.message)
       } finally {
        setLoading(false)
       }
    }

    return (
        <div className='auth'>
            <div className='auth__topbar'>
                <LogoHorizontal />
            </div>
            <div className='auth__form'>
                <div className='authform__header'>
                    <h1>
                        Ingresar al Panel de Control
                    </h1>
                    <p>
                        Ingresa a través de Facebook.
                    </p>
                </div>
                {loading ? (
                    <p className='authform__loading'>
                        Cargando...
                    </p>
                ) : (
                        <div className='oauthoptions_wrapper'>
                            <div className='authform__oauthlogin' onClick={handleFBLogin}>
                                Facebook
                                <FacebookCircled /> 
                            </div>
                        </div>
                )}
            </div>
        </div>
    )
}