import * as React from "react"

const Remove = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 2 9 3H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2h-4l-1-1h-4ZM5 7v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V7H5Zm4.41 3.414c.255 0 .51.097.705.291L12 12.59l1.885-1.885a.998.998 0 0 1 1.41 1.41L13.41 14l1.885 1.885a.998.998 0 0 1-1.41 1.41L12 15.41l-1.885 1.885a.998.998 0 0 1-1.41-1.41L10.59 14l-1.885-1.885a.998.998 0 0 1 .705-1.7Z"
      fill="currentColor"
    />
  </svg>
)

export default Remove
