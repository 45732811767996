import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../../../supabaseClient'

import './ICategory.scss'
import '../Charts.css'
import * as CTStyles from '../CustomTooltipStyles'

const CustomTooltip = ({ active, payload, label }) => {
  if ( active && payload && payload.length ) {
      return (
          <CTStyles.MainContainer style={{ backgroundColor: 'var(--neutral-light_2' }} >
              <CTStyles.XLabel>
                {`${label}:`}
              </CTStyles.XLabel>
              <CTStyles.YLabelWrapper>
                <CTStyles.YLabelName>
                  {`${payload[0].name}:`}
                </CTStyles.YLabelName>
                <CTStyles.YLabelValue style={{ backgroundColor: 'var(--neutral-light)' }} >
                  <p>
                    {`$${payload[0].value}`}
                  </p>
                </CTStyles.YLabelValue>
              </CTStyles.YLabelWrapper>
              <CTStyles.YLabelWrapper>
                <CTStyles.YLabelName>
                  {`${payload[1].name}:`}
                </CTStyles.YLabelName>
                <CTStyles.YLabelValue style={{ backgroundColor: 'var(--neutral-light)' }} >
                  <p>
                    {`$${payload[1].value}`}
                  </p>
                </CTStyles.YLabelValue>
              </CTStyles.YLabelWrapper>
          </CTStyles.MainContainer>
      )
  }

  return null;
}

const OccupancyChart = ({ session }) => {
  // Time Formats & Eval Functions
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);
  // Time Formats & Eval Functions

  // Time Contexts & Delimiters 
  const [today, setToday] = useState(dayjs());
  const startOfSemester = useMemo(
    () => dayjs(today).startOf('year'),
    [dayjs(today).format('YYYY-MM-DD')]
  )
  const endOfSemester = useMemo(
    () => dayjs(startOfSemester).add(5, 'month'),
    [dayjs(today).format('YYYY-MM-DD')]
  )
  // Time Contexts & Delimiters

  // -------> START - useStates <-------
  const [loading, isLoading] = useState(true);
  const [gettingchartdata, setGettingChartData] = useState(true);
  const [rawdata, setRawData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  // -------> START - useStates <-------

  // -------> START - Get Chart Data <-------
  useEffect(() => {
    getChartData();
  }, [dayjs(today).format('YYYY-MM-DD')])

  const getChartData = async () => {
    setGettingChartData(true);
    try {
      let { data, error }= await supabase
        .from('income_by_category')
        .select()
        .order('month', {ascending: true})

        if (error) {
          throw error
        }
        if (data) {
          if(data.length === 0)  {
            console.log('No Finance Data Error - Empty Array')
            isLoading(false);
          } else {
            setRawData(data);
          }
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingChartData(false);
    }
  }

  useEffect(() => {
    if (gettingchartdata === false && rawdata.length !== 0) {
      rawdata.map((d) => {
        // const mes = dayjs(d.month).format('MMMM');
        // const gastos = `$${parseFloat(d.total_expenses).toFixed(2)}`;
        // const ingresos = `$${parseFloat(d.total_income).toFixed(2)}`;
        setChartData((current) => [...current, 
          {
            mes: dayjs(d.month).format('MMM'), 
            reservacion: parseFloat(d.reservacion).toFixed(2),
            otro: parseFloat(d.otro).toFixed(2)
          }
        ]);
        isLoading(false);
      })
    }
  }, [gettingchartdata, rawdata])
  // -------> END - Get Chart Data <-------

  return (
      <>
        {
          loading ?
          (
            <svg className='allentries__loader-container' viewBox="25 25 50 50">
              <circle className='allentries__loader-stroke' r="20" cy="50" cx="50"></circle>
            </svg>
          )
          :
          (
            <ResponsiveContainer width='100%' aspect={1/1}>
              <LineChart
                width={
                  320
                }
                height={
                  320
                }
                data={chartdata}
                margin={{
                  top: 20,
                  right: 0,
                  left: 0,
                  bottom: 20,
                }}
                style={{
                  background: 'none'
                }}
              >
                <defs>
                  <linearGradient id='value' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#374758' stopOpacity={0.8} />
                    <stop offset='95%' stopColor='#374758' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis type="number" domain={[0, 2000]}  />
                <XAxis dataKey='mes' stroke='gray' interval='preserveStartEnd' />
                <CartesianGrid strokeDasharray='3 3' className='ochart__grid' />
                <Tooltip content={CustomTooltip} wrapperStyle={{ outline: 'none' }} />
                <Line type="monotone" dataKey="reservacion" stroke="#7d83ff" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="otro" stroke="#dbab03"/>
              </LineChart>
            </ResponsiveContainer>
          )
        }
      </>
  )
}

export default OccupancyChart