import { useCallback, useMemo, useState, useEffect, useRef } from "react"
import dayjs from "dayjs"
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../supabaseClient'
import { DatePicker, SelectInput, TextInput, Summary, ConfirmationModal } from './components/Index'
import { motion, AnimatePresence } from 'framer-motion'

import * as Styles from "./CReservationsStyles"
import './CReservations.scss'
import { CircledLeft, CircledRight, Info, Day, Night, HalfDay, ReservationForm } from '../../../assets/icons/Index'
import { useWindowSize } from "../../../hooks/useWindowSize/useWindowSize"

export const CReservations = ({ session }) => {
// Time Context & Evaluators
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);

// Calendar Settings
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const currentDay = useMemo(() => dayjs().toDate(), [])
  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  )
  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  )
  const generateFirstDayOfEachWeek = useCallback(day => {
    const dates = [day]
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week")
      dates.push(date)
    }
    return dates
  }, [])
  const generateWeek = useCallback(day => {
    const dates = []
    for (let i = 0; i < 7; i++) {
      const date = day
        .clone()
        .add(i, "day")
        .toDate()
      dates.push(date)
    }
    return dates
  }, [])
  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    )
    return firstDayOfEachWeek.map(date => generateWeek(date))
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek])

// Render Controllers
const [supabaseloaded, setSupabaseLoaded] = useState(false);

// Data Controllers
const [reservationsdata, setReservationsData] = useState([])
const [gettingreservations, setGettingReservations] = useState(true);
const [dateslotsstatus, setDatesSlotsStatus] = useState([])
const [activeslot, setActiveSlot] = useState('day');
const [dayhasdata, setDayHasData] = useState(false);
const [nighthasdata, setNightHasData] = useState(false);
const [type, setType] = useState("status");
const [resid, setResId] = useState(null);
const [hasentry, setHasEntry] = useState(false);
const [status, setStatus] = useState();
const [name, setName] = useState();
const [email, setEmail] = useState();
const [phone, setPhone] = useState();
const [guests, setGuests] = useState();
const [fromDate, setFromDate] = useState();
const [duration, setDuration] = useState();
const [entryTime, setEntryTime] = useState();
const [toDate, setToDate] = useState();
const [exitTime, setExtTime] = useState();
const [paymentType, setPaymentType] = useState();
const [noOfPayments, setNoOfPayments] = useState();
const [amountAgreed, setAmountAgreed] = useState();
const [autoslot, setAutoSlot] = useState(false);

// Event Controllers
const [newentry, setNewEntry] = useState(false);
const [cancelmodal, setCancelModal] = useState(false);

// Data Storage
const summaryData = [
  {
    status: status,
    name: name,
    email: email,
    phone: phone,
    guests: guests,
    fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
    duration: duration,
    entryTime: entryTime,
    toDate: dayjs(toDate).format("YYYY-MM-DD"),
    exitTime: exitTime,
    paymentType: paymentType,
    noOfPayments: noOfPayments,
    amountAgreed: amountAgreed,
    autoslot: autoslot,
  }
];

// Data Cleanse
const handleResetValues = () => {
  setCancelModal(false);
  setType('status');
  setStatus(null);
  setResId(null);
  setName(null);
  setEmail(null);
  setPhone(null);
  setGuests(null);
  setFromDate(null);
  setDuration(null);
  setEntryTime(null);
  setToDate(null);
  setExtTime(null);
  setPaymentType(null);
  setNoOfPayments(null);
  setAmountAgreed(null);
  setAutoSlot(false);
}

// Form Steps Handler
const handleSteps = (string) => {
  switch (string) {
    case "closeModal":
      setNewEntry(false);
      handleResetValues();
      break;
    case "name":
      setType("name");
      break;
    case "email":
      setType("email");
      break;
    case "phone":
      setType("phone");
      break;
    case "guests":
      setType("guests");
      break;
    case "fromDate":
      setType("fromDate");
      break;
    case "rduration":
      setType("rduration");
      break;
    case "entryTime":
      setType("entryTime");
      break;
    case "12":
      if (entryTime === 'day') {
        setToDate(dayjs(fromDate).format('YYYY-MM-DD'));
        setExtTime('afternoon');
      } else if (entryTime === 'night') {
        setToDate(dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'))
        setExtTime('twilight')
      }
      setType("payment");
      setAutoSlot(true);
      break;
    case "24":
      if (entryTime === 'day') {
        setToDate(dayjs(fromDate).add(1, 'day'));
        setExtTime('twilight');
      } else if (entryTime === 'night') {
        setToDate(dayjs(fromDate).add(1, 'day'))
        setExtTime('afternoon')
      }
      setType("payment");
      setAutoSlot(true);
      break;
    case "24+":
      setType("toDate");
      break;
    case "exitTime":
      setType("exitTime");
      break;
    case "payment":
      setType("payment");
      break;
    case "multiple":
      setType("multiple");
      break;
    case "noOfPayments":
      setType("noOfPayments");
      break;
    case "amountAgreed":
      setType("amountAgreed");
      break;
    case "summary":
      setType("summary");
      break;
    case "submit":
      console.log("Submit got called");
      break;
    default:
      break;
  }
};

// Form Values Setter
const handleValue = (value) => {
  switch (type) {
    case "status":
      setStatus(value);
      break;
    case "name":
      setName(value);
      break;
    case "email":
      setEmail(value);
      break;
    case "phone":
      setPhone(value);
      break;
    case "guests":
      setGuests(value);
      break;
    case "fromDate":
      setFromDate(dayjs(value).format("YYYY-MM-DD"));
      break;
    case "rduration":
      setDuration(value);
      break;
    case "entryTime":
      setEntryTime(value);
      break;
    case "toDate":
      setToDate(dayjs(value).format("YYYY-MM-DD"));
      break;
    case "exitTime":
      setExtTime(value);
      break;
    case "payment":
      setPaymentType(value);
      break;
    case "multiple":
      setNoOfPayments(value);
      break;
    case "amountAgreed":
      setAmountAgreed(value);
      break;
    case "submit":
      break;
    default:
      break;
  }
};

// Data Fetch
  useEffect(() => {
    getPublicReservations()
  }, [])
  const getPublicReservations = async () => {
    setSupabaseLoaded(false);
    setGettingReservations(true);
    try {
      let {data, error, status} = await supabase
        .from('dates_day_night_status')
        .select(`
          *
        `)

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setReservationsData(data);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingReservations(false);
    }
  }
  useEffect(() => {
    if (gettingreservations === false) {
      getDatesSlots()
    }
  }, [gettingreservations])
  const getDatesSlots = async () => {
    try {
      let {data, error, status} = await supabase
        .from('reservations_by_day_status')
        .select(`
          *
        `)

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setDatesSlotsStatus(data);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setSupabaseLoaded(true);
    }
  }

// Event Handlers
const handleNewEntryClick = () => {
  setNewEntry(true);
}
const handleSubmit = async (entryData) => {
  setSupabaseLoaded(false);
  try {
    const { user } = session

    const updates = {
      user_id: user.id,
      created_at: new Date(),
      name: entryData[0].name,
      email: entryData[0].email,
      email: entryData[0].email,
      phone: entryData[0].phone,
      guests: entryData[0].guests,
      from_date: entryData[0].fromDate,
      from_time_of_day: entryData[0].entryTime,
      duration: entryData[0].duration,
      to_date: entryData[0].toDate,
      to_time_of_day: entryData[0].exitTime,
      payment_type: entryData[0].paymentType,
      number_of_payments: entryData[0].noOfPayments,
      amount_agreed: entryData[0].amountAgreed,
      confirmed: entryData[0].status,
      payment_status: entryData[0].confirmed
    }

    let { error } = await supabase.from('reservations').upsert(updates)

    if (error) {
      throw error
    }
  }
  catch (error) {
    alert(error.message)
  }
  finally {
    setNewEntry(false);
    getPublicReservations();
  }
};

const handleUpdate = async (entryData) => {
  try {
    const { user } = session

    const { error } = await supabase
      .from('reservations')
      .update({ 
        user_id: user.id,
        name: entryData[0].name,
        email: entryData[0].email,
        email: entryData[0].email,
        phone: entryData[0].phone,
        guests: entryData[0].guests,
        from_date: entryData[0].fromDate,
        from_time_of_day: entryData[0].entryTime,
        duration: entryData[0].duration,
        to_date: entryData[0].toDate,
        to_time_of_day: entryData[0].exitTime,
        payment_type: entryData[0].paymentType,
        number_of_payments: entryData[0].noOfPayments,
        amount_agreed: entryData[0].amountAgreed,
        confirmed: entryData[0].status,
        payment_status: entryData[0].confirmed
        })
      .eq('id', resid)

    if (error) {
      throw error
    }
  }
  catch(error) {
    alert(error.message)
  }
  finally {
    getPublicReservations();
  }
}
const handleDelete = async (string) => {
  if (string === 'Delete') {
    setType('Delete')
    setCancelModal(true);
  } else if (string === 'Delete_Confirmed') {
    try {
      const { error } = await supabase
        .from('reservations')
        .delete()
        .eq('id', resid)
  
        if (error) {
          throw error
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      handleResetValues();
      getPublicReservations();
    }
  } else if (string === 'Delete_Canceled') {
    setCancelModal(false);
    setType('existing');
  }
}

// Dates Time Slots Setter & Entry Data Finder
useEffect(() => {
  if (supabaseloaded === true) {
    setDayHasData(dateslotsstatus.some((s) =>
      dayjs(selectedDate).isSame(dayjs(s.date), 'date') && s.day_occupancy === 'occupied'
    ));
    setNightHasData(dateslotsstatus.some((s) =>
      dayjs(selectedDate).isSame(dayjs(s.date), 'date') && s.night_occupancy === 'occupied'
    ));
    if (activeslot === 'day') {
      if (dayhasdata === true) {
        setResId(reservationsdata.find((f) =>
          dayjs(selectedDate).isSame(dayjs(f.date), 'date') && f.day === 'occupied'
        )?.id)
      } else {
        setResId(null);
        handleResetValues();
      }
    } else if (activeslot === 'night') {
      if (nighthasdata === true) {
        setResId(reservationsdata.find((f) =>
          dayjs(selectedDate).isSame(dayjs(f.date), 'date') && f.night === 'occupied'
        )?.id)
      } else {
        setResId(null);
        handleResetValues();
      }
    }
  }
}, [supabaseloaded, selectedDate, activeslot, dayhasdata, nighthasdata])
  
  useEffect(() => {
    if (supabaseloaded === true) {
      if (resid !== null) {
        setStatus(reservationsdata.find((f) =>
          f.id === resid
        )?.confirmed);
        setName(reservationsdata.find((f) =>
          f.id === resid
        )?.name);
        setEmail(reservationsdata.find((f) =>
          f.id === resid
        )?.email);
        setPhone(reservationsdata.find((f) =>
          f.id === resid
        )?.phone);
        setGuests(reservationsdata.find((f) =>
          f.id === resid
        )?.guests);
        setFromDate(dayjs(reservationsdata.find((f) =>
          f.id === resid
        )?.from_date));
        setDuration(reservationsdata.find((f) =>
          f.id === resid
        )?.duration);
        setEntryTime(reservationsdata.find((f) =>
          f.id === resid
        )?.from_time_of_day);
        setToDate(dayjs(reservationsdata.find((f) =>
          f.id === resid
        )?.to_date));
        setExtTime(reservationsdata.find((f) =>
          f.id === resid
        )?.to_time_of_day);
        setPaymentType(reservationsdata.find((f) =>
          f.id === resid
        )?.payment_type);
        setNoOfPayments(reservationsdata.find((f) =>
          f.id === resid
        )?.number_of_payments);
        setAmountAgreed(reservationsdata.find((f) =>
          f.id === resid
        )?.amount_agreed);
        setType('existing');
        setHasEntry(true);
      } else {
        setType('status');
        setHasEntry(false);
      }
    }
  }, [supabaseloaded, resid])

  const windowsize = useWindowSize();
  
  return (
    <>
      { supabaseloaded ?
      (
      <>
      <AnimatePresence>
      {
        newentry === true || cancelmodal === true ? (
          <motion.div 
            className='modal-container'
            initial={{
              opacity: 0.5,
              scale: 0.6
            }}
            animate={{
              opacity: 1,
              scale: 1
            }}
            exit={{
              opacity: 0,
              scale: 0.4
            }}
            transition={{
              type: "spring",
              duration: 0.4
            }}
          >
            <DatePicker 
              onNext={(value) => handleValue(value)}
              flowValue={handleSteps}
              type={type}
              style={{
                display: type === 'status' ? 'none' : 'flex'
              }}
              startDate={selectedDate}
            />
            <SelectInput 
              onNext={(value) => handleValue(value)}
              flowValue={handleSteps}
              type={type}
              entryTime={activeslot}
            />
            <TextInput
              onNext={(value) => handleValue(value)}
              flowValue={handleSteps}
              type={type}
              style={{
                display: type === 'status' ? 'none' : 'flex'
              }}
            />
            <Summary
              flowCall={type}
              flowValue={handleSteps}
              summaryData={summaryData}
              submitData={handleSubmit}
              style={{
                display: type === 'Delete' ? 'none' : 'flex'
              }}
            />
            <ConfirmationModal
              type={type}
              flowValue={handleDelete}
            />
          </motion.div>
        ) : null
      }
      </AnimatePresence>
        <Styles.MainWrapper style={{ pointerEvents: newentry ? 'none' : 'all' }} 
           as={motion.div}
           initial={{
             opacity: 0
           }}
           whileInView={{
             opacity: 1
           }}
           transition={{
             duration: 0.4
           }}
           viewport={{
             once: true
           }}
        >
          <Styles.CalendarHeaderWrapper>
            <h3>{selectedDate.clone().format("MMMM, YYYY")}</h3>
            <div>
              <motion.div
                style={{
                  width: 'fit-content',
                  height: 'fit-content'
                }}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <CircledLeft
                  onClick={() => setSelectedDate(date => date.subtract(1, 'month'))} />
              </motion.div>
              <motion.div
                style={{
                  width: 'fit-content',
                  height: 'fit-content'
                }}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <CircledRight
                  onClick={() => setSelectedDate(date => date.add(1, 'month'))} />
              </motion.div>
            </div>
          </Styles.CalendarHeaderWrapper>
          <Styles.WeekDaysWrapper>
            {generateWeeksOfTheMonth[0].map((day, index) => (
              <Styles.WeekDayCell key={`week-day-${index}`}>
                {dayjs(day).format(windowsize.width < 1600 ? 'ddd' : 'dddd')}
              </Styles.WeekDayCell>
            ))}
          </Styles.WeekDaysWrapper>
          {generateWeeksOfTheMonth.map((week, weekIndex) => (
            <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
              {week.map((day, dayIndex) => (
                <Styles.CalendarDayCell
                  as={motion.div}
                  whileHover={{
                    scale: 1.1
                  }}
                  whileTap={{
                    scale: 0.9
                  }}
                  key={`day-${dayIndex}`}
                  onClick={() => {setSelectedDate(dayjs(day)); setActiveSlot('day')}}
                  // Idea for different click events, use a ternary operator, call a function that evaluates if a useState has been toggled by 1st click and execute code accordingly.
                  variant={selectedDate
                    .clone()
                    .toDate()
                    .getMonth() === dayjs(currentDay).isBefore(day, 'month')
                    ? "nextMonth"
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'occupied' && s.night_occupancy === 'occupied'
                    ) && dayjs(selectedDate).isSame(day, "date")
                    ? 'reservation_full_selected'
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'occupied' && s.night_occupancy === 'occupied'
                    )
                    ? 'reservation_full'
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'occupied' && s.night_occupancy === 'available'
                    ) && dayjs(selectedDate).isSame(day, "date")
                    ? 'reservation_hfday_selected'
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'occupied' && s.night_occupancy === 'available'
                    )
                    ? 'reservation_hfday'
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'available' && s.night_occupancy === 'occupied'
                    ) && dayjs(selectedDate).isSame(day, "date")
                    ? 'reservation_hfnight_selected'
                    : dateslotsstatus.some((s) => 
                      dayjs(s.date).isSame(dayjs(day)) && s.day_occupancy === 'available' && s.night_occupancy === 'occupied'
                    )
                    ? 'reservation_hfnight'
                    : dayjs(selectedDate).isSame(day, "date")
                    ? 'onlyselected'
                    : dayjs(currentDay).isAfter(day, "date")
                    ? "prevMonth"
                    : "default"
                  }
                >
                  {day.getDate()}
                  <HalfDay />
                  <div></div>
                  <div></div>
                </Styles.CalendarDayCell>
              ))}
            </Styles.CalendarContentWrapper>
          ))}
        </Styles.MainWrapper>
        <Styles.DetailsWrapper style={{ pointerEvents: newentry ? 'none' : 'all' }} 
           as={motion.div}
           initial={{
             opacity: 0
           }}
           whileInView={{
             opacity: 1
           }}
           transition={{
             duration: 0.6
           }}
           viewport={{
             once: true
           }}
        >
          <Styles.DetailsHeader>
            <h3>{selectedDate.clone().format("dddd D")}</h3>
            <div>
              <motion.div
                style={{
                  width: 'fit-content',
                  height: 'fit-content'
                }}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <CircledLeft
                  onClick={() => setSelectedDate(date => date.subtract(1, 'day'))} />
              </motion.div>
              <motion.div
                style={{
                  width: 'fit-content',
                  height: 'fit-content'
                }}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <CircledRight
                  onClick={() => setSelectedDate(date => date.add(1, 'day'))} />
              </motion.div>
            </div>
          </Styles.DetailsHeader>
          <Styles.DetailsBkdown>
            <Styles.BkdownHeader>
              <h4>
                detalles
              </h4>
              <Info />
            </Styles.BkdownHeader>
            <Styles.BkdownContent>
              <Styles.BkdownContentStatus
                as={motion.div}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
                style={{
                  backgroundColor: dateslotsstatus.some((s) =>
                  dayjs(selectedDate).isSame(s.date, "date") && s.day_occupancy === 'occupied'
                  )
                  ? '#D34423'
                  : '#3A9659',
                  border: activeslot === 'day' ? '2px solid #374758' : 'none'
                }}
                onClick={() => setActiveSlot('day')}
              >
                <Styles.ContentStatusPeriod>
                  <Day style={{ color: '#ebf2fa' }} />
                  <p>
                    día
                  </p>
                </Styles.ContentStatusPeriod>
                <Styles.ContentStatusState>
                  <p>
                    {
                     dateslotsstatus.some((s) =>
                      dayjs(selectedDate).isSame(s.date, "date") && s.day_occupancy === 'occupied'
                    )
                    ? 'Ocupado'
                    : 'Disponible'
                    }
                  </p>
                </Styles.ContentStatusState>
              </Styles.BkdownContentStatus>
              <Styles.BkdownContentStatus
                as={motion.div}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
                style={{
                  backgroundColor: dateslotsstatus.some((s) =>
                    dayjs(selectedDate).isSame(s.date, "date") && s.night_occupancy === 'occupied'
                  )
                  ? '#D34423'
                  : '#3A9659',
                  border: activeslot === 'night' ? '2px solid #374758' : 'none'
                }}
                onClick={() => setActiveSlot('night')}
              >
                <Styles.ContentStatusPeriod>
                  <Night style={{ color: '#ebf2fa' }} />
                  <p>
                    noche
                  </p>
                </Styles.ContentStatusPeriod>
                <Styles.ContentStatusState>
                  <p>
                    {
                     dateslotsstatus.some((s) =>
                      dayjs(selectedDate).isSame(s.date, "date") && s.night_occupancy === 'occupied'
                    )
                    ? 'Ocupado'
                    : 'Disponible'
                    }
                  </p>
                </Styles.ContentStatusState>
              </Styles.BkdownContentStatus>
            </Styles.BkdownContent>
          </Styles.DetailsBkdown>
          <Styles.DataWrapper>
            <AnimatePresence>
              {
                hasentry && (
                  <motion.div
                    initial={{
                      opacity: 0.2,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={{
                      type: "spring",
                      duration: 0.4
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '1rem',
                      padding: '12px',
                      backgroundColor: 'var(--neutral-light_2)',
                    }}
                  >
                    <Summary 
                      flowCall={type}
                      flowValue={handleSteps}
                      summaryData={summaryData}
                      updateData={handleUpdate}
                      deleteData={handleDelete}
                    />
                  </motion.div>
                )
              }
            </AnimatePresence>
            <Styles.NewEntryCTA onClick={handleNewEntryClick} style={{ display: hasentry === true ? 'none' : 'flex' }} 
               as={motion.div}
               whileTap={{
                 scale: 0.9
               }}
            >
              <p>
                crear nueva reservación
              </p>
              <ReservationForm />
            </Styles.NewEntryCTA>
          </Styles.DataWrapper>
        </Styles.DetailsWrapper>
    </>
    )
    :
    (
      <div className='calendar-loader'></div>
    )
    }
  </>
  )
}

export default CReservations;