import * as React from "react"

const Clear = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 1a1 1 0 0 0-1 1v7H7.717c-.987 0-1.829.72-1.979 1.695l-.111.729A.5.5 0 0 0 6.12 12h11.758a.5.5 0 0 0 .494-.576l-.111-.729A2.001 2.001 0 0 0 16.283 9H13V2a1 1 0 0 0-1-1ZM5.66 14a.5.5 0 0 0-.494.424l-.988 6.424A1 1 0 0 0 5.166 22h2.638a.999.999 0 0 0 .997-.904L9 19l.418 2.188c.089.472.5.812.98.812h3.137c.508 0 .936-.382.994-.887L15 17l.966 4.223a1 1 0 0 0 .974.777h1.895a1 1 0 0 0 .988-1.152l-.988-6.424A.5.5 0 0 0 18.34 14H5.66Z"
      fill="currentColor"
    />
  </svg>
)

export default Clear
