import * as React from "react"

const Expense = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm3.139 4.506A.8.8 0 0 1 14.4 7H13v7h-2V7H9.6a.8.8 0 0 1-.566-1.366l2.4-2.4a.799.799 0 0 1 1.131 0l2.4 2.4a.799.799 0 0 1 .174.872Z"
      fill="currentColor"
    />
  </svg>
)

export default Expense
