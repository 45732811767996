import * as React from "react"

const Income = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 2.05V9h1.4a.8.8 0 0 1 .566 1.366l-2.4 2.4a.799.799 0 0 1-1.131 0l-2.4-2.4A.799.799 0 0 1 9.6 9H11V2.05C5.947 2.552 2 6.815 2 12c0 5.523 4.477 10 10 10s10-4.477 10-10c0-5.185-3.947-9.448-9-9.95Z"
      fill="currentColor"
    />
  </svg>
)

export default Income
