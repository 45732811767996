import React from "react";
import FacebookCircled from "../../assets/icons/FacebookCircled";
import Instagram from "../../assets/icons/Instagram";
import LogoHorizontal from "../../assets/icons/LogoHorizontal";
import Twitter from "../../assets/icons/Twitter";
import WhatsApp from "../../assets/icons/WhatsApp";

import "./Footer.scss";

const Footer = () => {
  return (
  <div className="footer">
    <div className="footer__logo">
      <LogoHorizontal />
      <p>
      Calle Vecinal a las Brumas, Canton las Lomas de San Marcelino, Santa Ana, El Salvador. <br />
      Todos los Derechos Reservados, © 2022 eljardindelosvolcanes.com
      </p>
    </div>
    <div className="footer__links">
      <a 
      href="https://www.facebook.com/eljardindelosvolcanes"
      aria-label="Facebook"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          facebook
        </p>
        <FacebookCircled />
      </a>
      <a 
      href="https://twitter.com/jardinvolcanes"
      aria-label="Twitter"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          twitter
        </p>
        <Twitter />
      </a>
      <a 
      href="https://www.instagram.com/jardinvolcanes/"
      aria-label="Instagram"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          instagram
        </p>
        <Instagram />
      </a>
      <a 
      href="https://wa.link/6pqrfs"
      aria-label="WhatsApp"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          whatsapp
        </p>
        <WhatsApp />
      </a>
    </div>
  </div>
  
  )
};

export default Footer;
