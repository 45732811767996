import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../../../supabaseClient'

import './ESubCat.scss'
import '../Charts.css'
import * as CTStyles from '../CustomTooltipStyles'

const CustomTooltip = ({ active, payload, label }) => {
  if ( active && payload && payload.length ) {
      return (
          <CTStyles.MainContainer style={{ backgroundColor: 'var(--neutral-light_2)', zIndex: '2' }} >
              <CTStyles.XLabel>
                {`${label}:`}
              </CTStyles.XLabel>
              {
                payload.map((m) => (
                  <CTStyles.YLabelWrapper key={m.name}>
                    <CTStyles.YLabelName>
                      {`${m.name}:`}
                    </CTStyles.YLabelName>
                    <CTStyles.YLabelValue style={{ backgroundColor: 'var(--neutral-light)' }} >
                      <p>
                        {`$${m.value}`}
                      </p>
                    </CTStyles.YLabelValue>
                  </CTStyles.YLabelWrapper>
                ))
              }
          </CTStyles.MainContainer>
      )
  }

  return null;
}

const ESubCat = ({ session }) => {
  // Time Formats & Eval Functions
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);
  // Time Formats & Eval Functions

  // Time Contexts & Delimiters 
  const [today, setToday] = useState(dayjs());
  const startOfSemester = useMemo(
    () => dayjs(today).startOf('year'),
    [dayjs(today).format('YYYY-MM-DD')]
  )
  const endOfSemester = useMemo(
    () => dayjs(startOfSemester).add(5, 'month'),
    [dayjs(today).format('YYYY-MM-DD')]
  )
  // Time Contexts & Delimiters

  // -------> START - useStates <-------
  const [loading, isLoading] = useState(true);
  const [gettingchartdata, setGettingChartData] = useState(true);
  const [rawdata, setRawData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  // -------> START - useStates <-------

  // -------> START - Get Chart Data <-------
  useEffect(() => {
    getChartData();
  }, [dayjs(today).format('YYYY-MM-DD')])

  const getChartData = async () => {
    setGettingChartData(true);
    try {
      let { data, error }= await supabase
        .from('expenses_by_subc')
        .select()
        .order('month', {ascending: true})

        if (error) {
          throw error
        }
        if (data) {
          if(data.length === 0)  {
            console.log('No Finance Data Error - Empty Array')
            isLoading(false);
          } else {
            setRawData(data);
          }
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingChartData(false);
    }
  }

  useEffect(() => {
    if (gettingchartdata === false && rawdata.length !== 0) {
      rawdata.map((d) => {
        // const mes = dayjs(d.month).format('MMMM');
        // const gastos = `$${parseFloat(d.total_expenses).toFixed(2)}`;
        // const ingresos = `$${parseFloat(d.total_income).toFixed(2)}`;
        setChartData((current) => [...current, 
          {
            mes: dayjs(d.month).format('MMM'), 
            titulos_y_propiedades: parseFloat(d.titulos_y_propiedades).toFixed(2),
            construccion: parseFloat(d.construccion).toFixed(2),
            impuestos: parseFloat(d.impuestos).toFixed(2),
            salarios: parseFloat(d.salarios).toFixed(2),
            utilidades: parseFloat(d.utilidades).toFixed(2),
            mantenimiento: parseFloat(d.mantenimiento).toFixed(2),
            suministros: parseFloat(d.suministros).toFixed(2),
            juridico: parseFloat(d.juridico).toFixed(2),
            digital: parseFloat(d.digital).toFixed(2),
            afiliados: parseFloat(d.afiliados).toFixed(2),
            otros: parseFloat(d.otros).toFixed(2)
          }
        ]);
        isLoading(false);
      })
    }
  }, [gettingchartdata, rawdata])
  // -------> END - Get Chart Data <-------

  return (
      <>
        {
          loading ?
          (
            <svg className='allentries__loader-container' viewBox="25 25 50 50">
              <circle className='allentries__loader-stroke' r="20" cy="50" cx="50"></circle>
            </svg>
          )
          :
          (
            <ResponsiveContainer width='100%' aspect={1/1}>
              <LineChart
                width={
                  320
                }
                height={
                  320
                }
                data={chartdata}
                margin={{
                  top: 20,
                  right: 0,
                  left: 0,
                  bottom: 20,
                }}
                style={{
                  background: 'none'
                }}
              >
                <defs>
                  <linearGradient id='value' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#374758' stopOpacity={0.8} />
                    <stop offset='95%' stopColor='#374758' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis type="number" domain={[0, 3000]} />
                <XAxis dataKey='mes' stroke='gray' interval='preserveStartEnd' />
                <CartesianGrid strokeDasharray='3 3' className='ochart__grid' />
                <Tooltip content={CustomTooltip} wrapperStyle={{ outline: 'none' }} />
                <Line type="monotone" dataKey="titulos_y_propiedades" stroke="#3a9659" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="construccion" stroke="#64e890"/>
                <Line type="monotone" dataKey="impuestos" stroke="#374758"/>
                <Line type="monotone" dataKey="salarios" stroke="#15212d"/>
                <Line type="monotone" dataKey="utilidades" stroke="#483b32"/>
                <Line type="monotone" dataKey="mantenimiento" stroke="#d34423"/>
                <Line type="monotone" dataKey="suministros" stroke="#18c1f9"/>
                <Line type="monotone" dataKey="juridico" stroke="#ffa41c"/>
                <Line type="monotone" dataKey="digital" stroke="#691eeb"/>
                <Line type="monotone" dataKey="afiliados" stroke="#7d83ff"/>
                <Line type="monotone" dataKey="otros" stroke="#74c122"/>
              </LineChart>
            </ResponsiveContainer>
          )
        }
      </>
  )
}

export default ESubCat