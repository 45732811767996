import React from "react";
import { motion } from "framer-motion";

import "./Dashboard.scss";
import CDashboard from '../../components/Calendar/CDashboard/CDashboard'

const Dashboard = ({ session }) => {
  return (
    <div className="availability">
      <CDashboard session={session} />
    </div>
  );
};

export default Dashboard;
