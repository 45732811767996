import { styled, css } from '../../../stitches.config';

export const MainWrapper = styled("div", {
  display: 'flex',
  flexDirection: 'column',
  width: 'clamp(760px, 121rem, 75vw)',
  height: '100%',
  borderRadius: 32,
  padding: 'clamp(1.5rem, 2rem, 1.7vw)',
  backgroundColor: "#D4E1F0",
  gap: '24px',
  userSelect: 'none',
  zIndex: 1,

  '@bp1': {
    width: '100%',
    height: 'fit-content',
  },
});

export const CalendarHeaderWrapper = styled("div", {
  display: "flex",
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 'clamp(1em, 2em, 1.7vw)',
  color: '#374758',
  textTransform: 'uppercase',
  fontWeight: '700',
  fontStyle: 'normal',
  background: 'none',
  letterSpacing: '0.04em',
  marginBottom: 8,

  'h3': {
    background: 'none',
  },

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 24,
    background: 'none',
    alignItems: 'center',

    'svg': {
        background: 'none',
        fontSize: 'clamp(1.5em, 2.5em, 1.666vw)',
        color: '#374758',
        cursor: 'pointer'
    }
  }
});

export const WeekDaysWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: '100%',
  background: 'none',
});

export const WeekDayCell = styled("div", {
  height: 'clamp(38px, 100px, 7.3vw)',
  width: 'clamp(38px, 140px, 7.3vw)',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#374758",
  backgroundColor: '#B7CEE9',
  borderRadius: 12,
  fontSize: 'clamp(0.75em, 1.25em, 1.75vw)',
  fontWeight: '700',
  letterSpacing: '0.04em',
  textTransform: 'uppercase'
});

export const CalendarContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: '100%',
  justifyContent:  'space-between',
  background: 'none',
});

export const CalendarDayCell = styled("div", {
  height: 'clamp(38px, 100px, 7.3vw)',
  width: 'clamp(38px, 140px, 7.3vw)',
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  background: 'none',
  fontSize: 'clamp(0.75em, 1.25em, 1.75vw)',
  fontWeight: '700',
  letterSpacing: '0.04em',
  cursor: 'pointer',
  transition: 'border 0.3s ease-out, background-color 0.3s ease-out',

  'svg': {
    display: 'flex',
    position: 'absolute',
    fontSize: 'clamp(0.45em, 0.75em, 0.625vw)',
    color: '#374758',
    background: 'none',
    marginTop: 'clamp(1.5rem, 3.5rem, 2.91vw)'
  },

  'div:nth-child(2)': {
    display: 'flex',
    position: 'absolute',
    width: 'clamp(4px, 12px, 0.625vw)',
    height: 'clamp(4px, 12px, 0.625vw)',
    borderRadius: '50%',
    backgroundColor: '#374758',
    marginTop: 'clamp(1.5rem, 3.5rem, 2.91vw)',
  },

  'div:nth-child(3)': {
    display: 'flex',
    position: 'absolute',
    width: 'clamp(4px, 12px, 0.625vw)',
    height: 'clamp(4px, 12px, 0.625vw)',
    borderRadius: '50%',
    border: 'clamp(1px, 2px, 0.1vw) solid #374758',
    background: 'none',
    marginTop: 'clamp(1.5rem, 3.5rem, 2.91vw)',
  },

  variants: {
    variant: {
      default: {
        color: "#303A46",

        'svg': {
          display: 'none',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      prevMonth: {
        color: "rgba(48, 58, 70, 0.4)",
        userSelect: 'none',

        'svg': {
          display: 'none',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      onlyselected: {
        color: "#374758",
        border: 'solid 2px #374758',

        'svg': {
          display: 'none',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_full_selected: {
        color: "#374758",
        border: 'solid 2px #374758',
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'none',
        },

        'div:nth-child(2)': {
          display: 'flex',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_full: {
        color: "#374758",
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'none',
        },

        'div:nth-child(2)': {
          display: 'flex',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_hfday_selected: {
        color: "#374758",
        border: 'solid 2px #374758',
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'flex',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_hfday: {
        color: "#374758",
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'flex',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_hfnight_selected: {
        color: "#374758",
        border: 'solid 2px #374758',
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'flex',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
      reservation_hfnight: {
        color: "#374758",
        backgroundColor: '#E9BFB5',

        'svg': {
          display: 'flex',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        },

        'div:nth-child(2)': {
          display: 'none',
        },
        'div:nth-child(3)': {
          display: 'none',
        },
      },
    },
  },
});

export const DashboardWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  right: 0,
  width: 'clamp(400px, 68rem, 43vw)',
  height: '61.5rem',
  alignItems: 'flex-start',
  zIndex: '0',
  backgroundColor: '#B7CEE9',
  borderRadius: '32px',
  paddingTop: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingRight: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingBottom: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingLeft: 'clamp(5rem, 5.75rem, 6vw)',
  gap: '24px',
  userSelect: 'none',

  '@bp1': {
    position: 'relative',
    width: '100%',
    padding: 'clamp(1.5rem, 2rem, 1.7vw)',
    paddingTop: '6rem',
    top: '-4rem',
    height: 'fit-content',
  },
  '@bp2': {
    padding: '0',
    paddingTop: '6rem',
  },
});

export const ChatsAndDateWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: '50%',
  alignItems: 'flex-start',
  gap: '32px',
  background: 'none',
  order: '0',
  flexGrow: '0',
  alignSelf: 'stretch',

  '@bp1': {
    height: 'auto',
  },
  '@bp2': {
    flexDirection: 'column',
  },
})

export const ChatsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  gap: '24px',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '1',
  background: 'none',

  '@bp1': {
    height: '498px'
  },
  '@bp2': {
    height: '420px'
  }
})

export const ChatsHeaderWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  height: 'fit-content',
  gap: '16px',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '0',
  background: 'none',

  '@bp2': {
    padding: '0 clamp(1.5rem, 2rem, 1.7vw)'
  }
})

export const ChatsHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  background: 'none',
  alignItems: 'flex-start',

  'p': {
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: '500',
    textAlign: 'left',
    color: '#3F5772',
    background: 'none',
    textTransform: 'capitalize',
  },

  'h3': {
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: '500',
    textAlign: 'left',
    color: '#374758',
    background: 'none',
    textTransform: 'capitalize',
  },
})

export const ChatsBubblesWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  gap: '32px',
  justifyContent: 'space-between',
  background: 'none',

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: 'fit-content',
    alignItems: 'center',
    gap: '4px',
    background: 'none',
    
    'div': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '32px',
      height: '32px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: '#D4E1F0',
      overflow: 'hidden',

      'svg': {
        fontSize: '1.25em',
        color: '#374758',
        background: 'none'
      },

      'img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },

    'p': {
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: '500',
      color: '#374758',
      textAlign: 'center',
      background: 'none',
    }
  }
})

export const ChatsCard = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  backgroundColor: '#D4E1F0',
  borderRadius: '10px',
  padding: '16px',

  '@bp1': {
    height: 'auto',
  },
})

export const ChatsCardRow = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',

  'div:nth-child(1)': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: 'fit-content',
    alignItems: 'center',
    gap: '12px',
    background: 'none',

    'div:nth-child(1)': {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      background: 'none',
      overflow: 'hidden',

      'img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },

    'div:nth-child(2)': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: 'fit-content',
      maxWidth: '120px',
      height: 'fit-content',
      alignItems: 'flex-start',
      justifyContent: 'left',
      gap: '4px',
      background: 'none',
      overflow: 'hidden',

      'h5': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'left',
        color: '#374758',
        background: 'none',
        textTransform: 'capitalize',
      },

      'p': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
        color: '#5D7086',
        background: 'none',
        maxWidth: '120px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    }
  },

  'div:nth-child(2)': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '4px',
    background: 'none',
    
    'p': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
        color: '#5D7086',
        background: 'none',
        textTransform: 'capitalize',
    },

    'div': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      width: '100%',
      height: 'fit-content',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: 'none',

      'svg': {
        fontSize: '1em',
        color: '#5D7086',
        background: 'none'
      },

      'div': {
        width: '16px',
        height: '16px',
        backgroundColor: '#7D83FF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',

        'p': {
          fontStyle: 'normal',
          fontSize: '10px',
          fontWeight: '700',
          color: '#fff',
          background: 'none',
        }
      }
    },
  },
})

export const DetailsWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  flexFlow: 'column nowrap',
  right: '0px',
  width: 'clamp(400px, 44rem, 27.5vw)',
  height: '61.5rem',
  alignItems: 'flex-start',
  zIndex: '0',
  backgroundColor: 'rgb(183, 206, 233)',
  borderRadius: '32px',
  paddingTop: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingRight: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingBottom: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingLeft: 'clamp(5rem, 5.75rem, 6vw)',
  gap: '36px',
  userSelect: 'none',

  '@bp1': {
    position: 'relative',
    width: '100%',
    padding: 'clamp(1.5rem, 2rem, 1.7vw)',
    paddingTop: '6rem',
    top: '-4rem',
    height: 'fit-content',
  }
})

export const SelectedDateDetailsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  gap: '24px',
  background: 'none',
  order: '1',
  flexGrow: '1',

  '@bp2': {
    padding: '0 clamp(1.5rem, 2rem, 1.7vw)'
  }
})

export const DetailsHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 'fit-content',
  background: 'none',

  'h3': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 'clamp(1em, 1.5em, 1.5vw)',
    letterSpacing: '0.04em',
    color: '#374758',
    textTransform: 'uppercase',
    background: 'none',
  },

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '16px',
    background: 'none',
    
    'svg': {
      fontSize: 'clamp(1.5em, 2.5em, 1.666vw)',
      color: '#374758',
      background: 'none',
      cursor: 'pointer',
    }
  },
});

export const DetailsBkdown = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  width:'100%',
  height: 'fit-content',
  background: 'none',
  gap: '20px',
});

export const BkdownHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  background: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',

  'h4': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.875em, 1.25em, 1vw)',
    fontWeight: '700',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#374758',
    background: 'none',
    textTransform:  'uppercase',
  },

  'svg': {
    fontSize: 'clamp(1.5em, 1.5em, 1.5vw)',
    color: '#374758',
    background: 'none',
  }
});

export const BkdownContent = styled('div',{
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  gap: '8px',
  background: 'none',
});

export const BkdownContentStatus = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  borderRadius: '6px',
  backgroundColor: '#3A9659',
  alignItems:'center',
  justifyContent: 'space-between',
  padding: '6px',
  transition: 'background-color 0.4s ease-out, border 0.4s ease-out',
  cursor: 'pointer',
});

export const ContentStatusPeriod = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  background: 'none',
  gap: '8px',
  alignItems: 'center',

  'svg': {
    fontSize: 'clamp(1em, 1em, 1vw)',
    background: 'none',
  },

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1em, 1vw)',
    fontWeight: '400',
    color: '#ebf2fa',
    background: 'none',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
});

export const ContentStatusState = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'none',
  background: 'none',

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1em, 1vw)',
    fontWeight: '400',
    color: '#ebf2fa',
    background: 'none',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
});

export const DataWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  overflow: 'hidden',
});

export const NewEntryCTA =  styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  gap: '8px',
  cursor: 'pointer',
  border: '2px solid var(--neutral-dark_2)',
  borderRadius: '6px',
  transition: 'background-color 0.4s ease-out',

  '&:hover': {
    backgroundColor: 'var(--neutral-dark_2)',
    
    'p': {
      color: 'var(--neutral-light)',
    },
    'svg': {
      color: 'var(--neutral-light)',
    },
  },
  
  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1em, 1.25vw)',
    fontWeight: '600',
    color: 'var(--neutral-dark_2)',
    textTransform: 'uppercase',
  },
  'svg': {
    color: 'var(--neutral-dark_2)',
    fontSize: 'clamp(0.75em, 1em, 1.25vw)'
  },
})
