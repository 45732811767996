import React from "react";
import { motion } from "framer-motion";

import "./Finance.scss";
import CFinance from '../../components/Calendar/CFinance/CFinance'

const Finance = ({ session }) => {
  return (
    <div className="availability">
      <CFinance session={session} />
    </div>
  );
};

export default Finance;
