import { useState, useEffect } from 'react'
import { supabase } from '../../supabaseClient'
import dayjs from "dayjs"
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import styled from 'styled-components'

import './Account.scss'
import './Account.css'
import { LogOut, Save, Edit, Contact, CalendarPlus, Day, Night } from '../../assets/icons/Index'
import Avatar from '../../widgets/Avatar'

const CTAButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: var(--primary-color);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    svg {
      scale: 1.3;
    }
  }

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.75em, 1em, 1.1vw);
    text-align: right;
    text-transform: uppercase;
    color: var(--neutral-light);
    background: none;
    transition: all 0.5s ease-out;
  }

  svg {
    font-size: clamp(0.75em, 1em, 1.1vw);
    color: var(--neutral-light);
    background: none;
    transition: all 0.5s ease-out;
  }
`

const Account = ({ session }) => {
    dayjs.locale('es');

    const isBetween = require('dayjs/plugin/isBetween');
    dayjs.extend(isBetween);
  
    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
    dayjs.extend(isSameOrAfter);

    const [firstlogin, setFirstLogin] = useState(null)
    const [fbdata, setFBData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [avatar_url, setAvatarUrl] = useState(null);
    const [datavalues, setDataValues] = useState(true);

    useEffect(() => {
        console.log('I got mounted Type of Login')
        typeOfLogin()
    }, [])
    
    useEffect(() => {
        console.log('I got mounted FBData')
        if (firstlogin === true) {
            handleFBData()
        }
    }, [firstlogin])
    
    useEffect(() => {
        console.log('I got mounted SetValues')
        if (fbdata === false) {
            setValues()
        }
    }, [fbdata])

    useEffect(() => {
        console.log('I got mounted GetProfiles')
        if (datavalues === false) {
            getProfile()
        }
    }, [datavalues])

    const typeOfLogin = async () => {
        try {
            console.log('type of login started')
            setLoading(true);
            const { user } = session

            let { data, error, status } = await supabase
                .from('profiles')
                .select('email, username, phone, avatar_url')
                .eq('id', user.id)
                .single()

            if (error) {
                setFirstLogin(true);
                console.log('First Login')
            }

            if (data) {
                setUserName(data.username)
                setEmail(data.email)
                setPhone(data.phone)
                setAvatarUrl(data.avatar_url)
                setLoading(false)
                setFirstLogin(false)
                console.log('Not First Login')
            }

        }
        catch (error) {
            alert(error.message)
        }
        finally {
            setFirstLogin(false);
            console.log('type of login finished')
        }
    }

    const handleFBData = async () => {
        try {
            console.log('fbdatastarted')
            setLoading(true);
            setFBData(true);
            const { user } = session

            await window.FB.api(
                '/me',
                'GET',
                {"":null,"fields":"picture.width(800).height(800)","access_token":session.provider_token,"redirect":"true"},
                function(response) {
                    setAvatarUrl(response.picture.data.url)
                    setUserName(session.user.user_metadata.name)
                    setEmail(session.user.email)
                    console.log('FB API finished fetch')
                    setFBData(false)
                }
              );
        } 
        catch (error) {
            alert(error.message)
        } 
        finally {
            console.log('fbdataended')
        }
    }

    const setValues = async () => {
        try {
            console.log('setvalues started')
            const { user } = session

            const updates = {
                id: user.id,
                username,
                email,
                phone,
                avatar_url,
                updated_at: new Date(),
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) {
                throw error
            }
        } catch (error) {
            alert(error.message)
        } finally {
            console.log(username)
            console.log(email)
            console.log(avatar_url)
            console.log(phone)
            setDataValues(false)
            console.log('setvalues finished')
        }
    }

    const getProfile = async () => {
        try {
            console.log('getprofilestarted')
            const { user } = session

            let { data, error, status } = await supabase
                .from('profiles')
                .select('email, username, phone, avatar_url')
                .eq('id', user.id)
                .single()

            if (error && status !== 406) {
                throw error
            }
            
            if (data) {
                setUserName(data.username)
                setEmail(data.email)
                setPhone(data.phone)
                setAvatarUrl(data.avatar_url)
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
            console.log('getprofileended')
            console.log(username)
            console.log(email)
            console.log(avatar_url)
            console.log(phone)
        }
    }

    const updateProfile = async () => {
        // e.preventDefault()

        try {
            setLoading(true)
            const { user } = session

            const updates = {
                id: user.id,
                username,
                email,
                phone,
                avatar_url,
                updated_at: new Date(),
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) {
                throw error
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false);
        }
    }

    const [penamestate, setPENameState] = useState(false);
    const [peemailstate, setPEEmailState] = useState(false);
    const [pephonestate, setPEPhoneState] = useState(false);

    return (
        <div className='account'>
            {loading ? (
                <div className='auth-loader'></div>
            ) : (
                <>
                    <div className='account__detailscontainer'>
                        <div className='accdetailscontainer__header'>
                            <Avatar 
                                url={avatar_url}
                                onUpload={(url) => {
                                    setAvatarUrl(url);
                                    updateProfile({ username, email, phone, avatar_url: url })
                                }}
                            />
                            <div className='accdetheader__userinfowrapper'>
                                <h3>
                                    {username || session.user.user_metadata.name}
                                </h3>
                                <p>
                                    Te uniste el <span style={{ textTransform: 'capitalize', background: 'none' }}>{dayjs(session.user.created_at).format(`DD - MMMM - YYYY`)}</span>
                                </p>
                                <p>
                                    Haz reservado 3 veces!
                                </p>
                            </div>
                        </div>
                        <form className='accdetfields__wrapper' >
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Nombre:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <div className='accdetfiewraprow__inputedit'>
                                        <Edit onClick={() => setPENameState(true)}/>
                                    </div>
                                    <input
                                        style={{
                                            pointerEvents: penamestate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='name'
                                        type="text"
                                        defaultValue={ username || session.user.user_metadata.name || '' }
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Correo:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <div className='accdetfiewraprow__inputedit'>
                                        <Edit onClick={() => setPEEmailState(true)}/>
                                    </div>
                                    <input
                                        style={{
                                            pointerEvents: peemailstate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='email'
                                        type="text"
                                        defaultValue={ email || session.user.email || '' }
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Teléfono:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <div className='accdetfiewraprow__inputedit'>
                                        <Edit onClick={() => setPEPhoneState(true)} />
                                    </div>
                                    <input
                                        style={{
                                            pointerEvents: pephonestate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='phone'
                                        type="text"
                                        placeholder='e.g +503 7040-7668'
                                        defaultValue={ phone || '' }
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                        </form>
                        <button className='accdetform__submit' disabled={loading} onClick={updateProfile}>
                            <p>
                                Guardar Cambios
                            </p>
                            <Save />
                        </button>
                    </div>
                    <div className='account__mcontrolcontainer'>
                        <div className='accmcontrol__reshistorywrapper'>
                            <div className='accmconreshi__header'>
                                <h3>
                                    historial de reservaciones
                                </h3>
                            </div>
                            <div className='accmconreshi__dateswrapper'>
                                <div className='accmconreshidawra__row '>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Oct - 10 - 21
                                        </p>
                                        <Day />
                                    </div>
                                    <p>
                                        -
                                    </p>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Oct - 12 - 21
                                        </p>
                                        <Night style={{ color: '#374758' }} />
                                    </div>
                                </div>
                                <div className='accmconreshidawra__row '>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Ene - 06 - 21
                                        </p>
                                        <Day />
                                    </div>
                                    <p>
                                        -
                                    </p>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Ene - 09 - 21
                                        </p>
                                        <Night style={{ color: '#374758' }} />
                                    </div>
                                </div>
                                <div className='accmconreshidawra__row '>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Dic - 20 - 21
                                        </p>
                                        <Day />
                                    </div>
                                    <p>
                                        -
                                    </p>
                                    <div className='accmconreshidawraro__date'>
                                        <p>
                                            Dic - 22 - 21
                                        </p>
                                        <Night style={{ color: '#374758' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='accmconcontent__ctas'>
                                <CTAButton>
                                    <p>
                                        agendar
                                    </p>
                                    <CalendarPlus />
                                </CTAButton>
                                <CTAButton
                                    style={{
                                        backgroundColor: '#483B32'
                                    }}
                                >
                                    <p>
                                        contactar
                                    </p>
                                    <Contact />
                                </CTAButton>
                            </div>
                        </div>
                        <button type='button' className='accpan__logout' onClick={() => supabase.auth.signOut()}>
                            <p>
                                Salir
                            </p>
                            <LogOut />
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export default Account