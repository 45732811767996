import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../supabaseClient'
import ScrollContainer from 'react-indiana-drag-scroll'

import './AllEntries.scss'
import './AllEntries.css'
import { CircledLeft, Filter, ChevronDown, Search, Edit, Remove, Cancel, Save, ExportExcel, Day, Night, WeekView, Income, Expense } from '../../assets/icons/Index'
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize'
import CurrencyInput from '../../components/CurrencyInput/CurrencyInput'
import QttyInput from '../../components/QttyInput/QttyInput'

const AllEntradas = ({ session }) => {
// -------> START - Time Delimiters <-------
const [currentDay, setCurrentDay] = useState(dayjs());
const [timerangefilter, setTimeRangeFilter] = useState('c_month');
const prevThreeMonths = useMemo(
  () => dayjs(currentDay).subtract(3, 'month'),
  [currentDay]
)
const prevSixMonths = useMemo(
  () => dayjs(currentDay).subtract(6, 'month'),
  [currentDay]
)
// const prevWeek = useMemo(
//   () => dayjs(currentDay).subtract(1, 'week'),
//   [currentDay]
// )
const prevThreeWeeks = useMemo(
  () => dayjs(currentDay).subtract(3, 'week'),
  [currentDay]
)
const prevYear = useMemo(
  () => dayjs(currentDay).subtract(1, 'year'),
  [currentDay]
)
const upperLimit = useMemo(
  () => {
    if (timerangefilter === 'c_month') {
      return dayjs(currentDay).startOf('month')
    } else if (timerangefilter === 'p3_month') {
      return dayjs(prevThreeMonths).startOf('month')
    } else if (timerangefilter === 'p6_month') {
      return dayjs(prevSixMonths).startOf('month')
    } else if (timerangefilter === 'c_week') {
      return dayjs(currentDay).subtract(1, 'week')
    } else if (timerangefilter === 'p3_week') {
      return dayjs(prevThreeWeeks).startOf('week')
    } else if (timerangefilter === 'c_year') {
      return dayjs(currentDay).startOf('year')
    } else if (timerangefilter === 'p_year') {
      return dayjs(prevYear).startOf('year')
    }
  },
  [currentDay, timerangefilter]
)
const lowerLimit = useMemo(
  () => dayjs(currentDay),
  [currentDay]
)
// -------> END - Time Delimiters <-------


// -------> START - All UseStates <-------
// ---> General States <---
const [loading, isLoading] = useState(true);
const [gettingfinancedata, setGettingFinanceData] = useState(true);
const [financedata, setFinanceData] = useState([]);
const [gettingexpensesdata, setGettingExpensesData] = useState(null);
const [expensesdata, setExpensesData] =  useState();
const [gettingincomedata, setGettingIncomeData] = useState(null);
const [incomedata, setIncomeData] = useState([]);
const [gettingreservationsdata, setGettingReservationsData] = useState(null);
const [reservationsdata, setReservationsData] = useState([]);
const [entryselected, setEntrySelected] = useState(false);
const [entrytype, setEntryType] = useState('');
const [dateofentry, setDateOfEntry] = useState(dayjs());
const [pickerid, setPickerId] = useState(null);
const [timerangepicker, setTimeRangePicker] = useState(null);
const [categorypicker, setCategoryPicker] = useState(null);
const [currententrydata, setCurrentEntryData] = useState(null)
// ---> General States <---

// ---> Filter States <---
const [typefilter, setTypeFilter] = useState('all');
const [ascending, setAscending] = useState(false);
const [searchtext, setSearchText] = useState('');
const [filteredExpenses, setFilteredExpenses] = useState([]);
const [filteredIncome, setFilteredIncome] = useState([]);
// ---> Filter States <---

// ---> General Form States <---
const [entrytypepicker, setEntryTypePicker] = useState(false);
// ---> General Form States <---

// ---> Expense Form States <---
const [expensearea, setExpenseArea] = useState('');
const [expenseareapicker, setExpenseAreaPicker] = useState(false);
const [expensecategory, setExpenseCategory] = useState('');
const [expensecategorypicker, setExpenseCategoryPicker] = useState(false);
const [qttyvalue, setQttyValue] = useState('');
const [cunitvalue, setCunitValue] = useState('');
const [totalcost, setTotalCost] = useState(0);
// ---> Expense Form States <---

// ---> Income Form States <---
const [incometype, setIncomeType] = useState('');
const [incometypepicker, setIncomeTypePicker] = useState(false);
const [reservationbind, setReservationBind] = useState('');
const [paymenttype, setPaymentType] = useState('');
const [paymenttypicker, setPaymentTypePicker] = useState(false);
const [numberofpaymentsvalue, setNumberOfPaymentsValue] = useState('');
const [paymentordinalvalue, setPaymentOrdinalValue] = useState('');
const [amountagreedvalue, setAmountAgreedValue] = useState('');
const [amountpaidvalue, setAmountPaidValue] = useState('');
const [amountremainingvalue, setAmountRemainingValue] = useState(0);
const [prstatus, setPrStatus] = useState('')
// ---> Income Form States <---

// ---> Form Action States <---
const [editentry, setEditEntry] = useState(false);
// ---> Form Action States <---

// ---> Modals <---
const [deleteverificationmodal, setDeleteVerificationModal] = useState(false);
const [inputalert, setInputAlert] = useState(false);
const [inputname, setInputName] = useState('');
const [lastremnant, setLastRemnant] = useState('0');
// ---> Modals <---
// -------> END - All UseStates <-------

// -------> START - Data Refs <-------
const descriptionref = useRef();
const commentsref = useRef();
const pcommentsref = useRef();
// -------> END - Data Refs <-------


// -------> START - Data Queries <-------
// ---> Finance Data <---
useEffect(() => {
  getFinanceData();
}, [dayjs(currentDay).format('YYYY-MM-DD'), timerangefilter, ascending]) 
const getFinanceData = async () => {
  setGettingFinanceData(true);
  try {
    let { data, error } =  await supabase
      .from('all_finance_entries')
      .select()
      .gte('date_of_entry', dayjs(upperLimit).format('YYYY-MM-DD'))
      .lte('date_of_entry', dayjs(lowerLimit).format('YYYY-MM-DD'))
      .order('date_of_entry', {ascending: ascending})

      if (error) {
        throw error
      }
      if (data) {
        if (data.length === 0) {
          console.log('No Finance Data Error - Empty Array')
        } else {
          setFinanceData(data);
        }
      }
  }
  catch(error) {
    alert(error.message)
  }
  finally {
    setGettingFinanceData(false);
  }
}
// ---> Finance Data <---

// ---> Expenses Data <---
useEffect(() => {
  if (gettingfinancedata === false) {
    getExpensesData();
  }
}, [gettingfinancedata])
const getExpensesData = async () => {
  setGettingExpensesData(true);
  try {
    let { data, error } = await supabase
      .from('expenses')
      .select()
      .gte('date_of_expense', dayjs(upperLimit).format('YYYY-MM-DD'))
      .lte('date_of_expense', dayjs(lowerLimit).format('YYYY-MM-DD'))
      .order('date_of_expense', {ascending: ascending})

      if (error) {
        throw error
      }
      if (data) {
        if (data.length === 0) {
          setExpensesData(data);
        } else {
          setExpensesData(data);
        }
      }
  }
  catch(error){
    alert(error.message)
  }
  finally {
    setGettingExpensesData(false);
  }
}
// ---> Expenses Data <---

// ---> Income Data <---
useEffect(() => {
  if (gettingexpensesdata === false) {
    getIncomeData();
  }
}, [gettingexpensesdata])
const getIncomeData = async () => {
  setGettingIncomeData(true);
  try {
    let { data, error } = await supabase
      .from('income')
      .select(`
        *,
        reservations(
          *
        )
      `)
      .gte('date_of_payment', dayjs(upperLimit).format('YYYY-MM-DD'))
      .lte('date_of_payment', dayjs(lowerLimit).format('YYYY-MM-DD'))
      .order('date_of_payment', {ascending: false})

      if (error) {
        throw error
      }
      if (data) {
        if (data.length === 0) {
          setIncomeData(data);
        } else {
          setIncomeData(data);
        }
      }
  }
  catch(error){
    alert(error.message)
  }
  finally {
    setGettingIncomeData(false);
  }
}
// ---> Income Data <---

// ---> Reservations Data <---
useEffect(() => {
  if (gettingincomedata === false) {
    getReservationsData();
  }
}, [gettingincomedata])

const getReservationsData = async () => {
  setGettingReservationsData(true);
  try {
    let { data, error } = await supabase
      .from('reservations')
      .select()

      if (error) {
        throw error
      }
      if (data) {
        if(data.length === 0) {
          setReservationsData(data);
        } else {
          setReservationsData(data)
        }
      }
  }
  catch(error) {
    alert(error.message)
  }
  finally {
    setGettingReservationsData(false);
    isLoading(false);
  }
}
// ---> Reservations Data <---
// -------> END - Data Queries <-------

// -------> START - Auto Evals <-------
// ---> Total Cost & Amount Remaining Values <---
useEffect(() => {
  if (loading === false && entryselected === true && entrytype === 'expense') {
    if (qttyvalue && cunitvalue !== null) {
      setTotalCost(qttyvalue * cunitvalue)
    }
  } 
}, [loading, entryselected, entrytype, qttyvalue, cunitvalue])
useEffect(() => {
  if (loading === false && entryselected === true && incometype === 'reservation' && editentry === true) {
    if (reservationbind !== 'Seleccionar') {
      if (incomedata.some((s) => reservationbind === s.reservation_id) === true) {
        if (incomedata.some((s) => reservationbind === s.reservation_id && s.payment_type === 'multiple')) {
          setLastRemnant(`${incomedata.find((f) => reservationbind === f.reservation_id)?.amount_remaining}`);
        }
      }
    }
  }
}, [loading, entryselected, editentry, incometype, reservationbind])
useEffect(() => {
  if (loading === false && entryselected === true && entrytype === 'income' && paymenttype === 'multiple') {
    if (editentry === false) {
      setAmountRemainingValue(`${incomedata.find((f) => reservationbind === f.reservation_id)?.amount_remaining}`)
    } else if (amountagreedvalue !== null && amountpaidvalue !== null && lastremnant !== '0') {
      setAmountRemainingValue(amountagreedvalue - amountpaidvalue - (amountagreedvalue - lastremnant))
    } else if (amountagreedvalue !== null && amountpaidvalue !== null) {
      setAmountRemainingValue(amountagreedvalue - amountpaidvalue - lastremnant)
    }
  }
}, [loading, entrytype, paymenttype, entryselected, amountagreedvalue, amountpaidvalue])
// ---> Total Cost & Amount Remaining Values <---

// ---> Pickers Auto Close <---
useEffect(() => {
  const closeFormTypePicker = (e) => {
    if (e.target.id !== 'tf' && e.target.id !== 'tf_d' && e.target.id !== 'tf_dw' && e.target.id !== 'tf_dm' && e.target.id !== 'tf_dy' && e.target.id !== 'cf' && e.target.id !== 'cf_d' && e.target.id !== 'cf_da' && e.target.id !== 'cf_dg' && e.target.id !== 'cf_di' && e.target.id !== 'sf' && e.target.id !== 'et' && e.target.id !== 'etp' && e.target.id !== 'eti' && e.target.id !== 'ea'  && e.target.id !== 'eap' && e.target.id !== 'eai'  && e.target.id !== 'ec' && e.target.id !== 'ecp' && e.target.id !== 'eci' && e.target.id !== 'ei' && e.target.id !== 'eip' && e.target.id !== 'eii') {
      if (e.target.parentElement.id !== 'tf' && e.target.parentElement.id !== 'tf_d' && e.target.parentElement.id !== 'tf_dw' && e.target.parentElement.id !== 'tf_dm' && e.target.parentElement.id !== 'tf_dy' && e.target.parentElement.id !== 'cf' && e.target.parentElement.id !== 'cf_d' && e.target.parentElement.id !== 'cf_da' && e.target.parentElement.id !== 'cf_dg' && e.target.parentElement.id !== 'cf_di' && e.target.parentElement.id !== 'eti' && e.target.parentElement.id !== 'eai' && e.target.parentElement.id !== 'eci' && e.target.parentElement.id !== 'eii') {
        setPickerId(null);
        setTimeRangePicker(null);
        setCategoryPicker(null);
        setEntryTypePicker(false);
        setExpenseAreaPicker(false);
        setExpenseCategoryPicker(false);
        setIncomeTypePicker(false);
      }
    }
  }

  document.body.addEventListener('click', closeFormTypePicker);
  return () => document.body.removeEventListener('click', closeFormTypePicker)
}, [])
// ---> Pickers Auto Close <---

// ---> Selected Entry Data Fetch <---
useEffect(() => {
  if (loading === false && entryselected === true) {
    if (currententrydata.type_of_entry === 'expense') {
      setEntryType('expense');
      setExpenseArea(expensesdata.find((f) => currententrydata.id === f.id)?.area);
      setExpenseCategory(expensesdata.find((f) => currententrydata.id === f.id)?.category);
      setQttyValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.qtty}`)
      setCunitValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.c_unit}`)
      setTotalCost(expensesdata.find((f) => currententrydata.id === f.id)?.c_total)
      setDateOfEntry(expensesdata.find((f) => currententrydata.id === f.id)?.date_of_expense)
    } else if (currententrydata.type_of_entry === 'income') {
      setEntryType('income');
      setDateOfEntry(incomedata.find((f) => currententrydata.id === f.id)?.date_of_payment)
      if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'reservation') === true) {
        setIncomeType('reservation');
        setReservationBind(incomedata.find((f) => currententrydata.id === f.id)?.reservation_id)
        if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'multiple') === true) {
          setPaymentType('multiple');
          setNumberOfPaymentsValue(`${incomedata.find((f) => currententrydata.id === f.id)?.number_of_payments}`);
          setPaymentOrdinalValue(`${incomedata.find((f) => currententrydata.id === f.id)?.payment_ordinal}`);
          setAmountAgreedValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_agreed}`);
          setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
          setPrStatus(incomedata.find((f) => currententrydata.id === f.id)?.status)
        } else if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'single') === true) {
          setPaymentType('single');
          setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
        }
      } else if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'other') === true) {
        setIncomeType('other');
        setPaymentType('single');
        setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
      }
    }
  } else if (loading === false) {
    setEntrySelected(false);
    setEditEntry(false);
    setExpenseArea('');
    setExpenseCategory('');
    setQttyValue('');
    setCunitValue('');
    setTotalCost(0);
    setIncomeType('');
    setReservationBind('');
    setPaymentType('');
    setNumberOfPaymentsValue('');
    setPaymentOrdinalValue('');
    setAmountAgreedValue('');
    setAmountPaidValue('');
    setAmountRemainingValue(0);
    setEntryType('');
    setLastRemnant('0');
  }
}, [loading, entryselected])
// ---> Selected Entry Data Fetch <---
// -------> END - Auto Evals <-------

// -------> START - Click Handles & Event Handles <-------

//---> Handle All Entries Click <---
const handleAllEntriesClick = () => {
  setTypeFilter('all');
  setTimeout(() => {
    setPickerId(null);
  }, 500)
}
//---> Handle All Entries Click <---

//---> Handle Entry Row Click <---
const handleEntryRowClick = (f) => {
  setCurrentEntryData(f);
  setEntrySelected(true);
}
//---> Handle Entry Row Click <---

//---> Handle Exit Selected Entry Click <---
const handleExitSelectedEntryClick = () => {
  setEntrySelected(false);
}
//---> Handle Exit Selected Entry Click <---

//---> Handle Change Type of Entry <---
const handleChangeEntryType = (e) => {
  if (e.target.id === 'ettg' || e.target.id === 'ettgp') {
    setEntryTypePicker(!entrytypepicker);
    setEntryType('expense');
  } else if (e.target.id === 'etti' || e.target.id === 'ettip') {
    setEntryTypePicker(!entrytypepicker);
    setEntryType('income');
  }
  setExpenseArea('Seleccionar');
  setExpenseCategory('select');
  setIncomeType('select');
  setPaymentType('select');
  setLastRemnant('0');
}
//---> Handle Change Type of Entry <---

//---> Handle Cancel Edit Entry <---
const handleCancelEntryEditClick = () => {
  setEditEntry(false);
  if (currententrydata.type_of_entry === 'expense') {
    setEntryType('expense');
    setExpenseArea(expensesdata.find((f) => currententrydata.id === f.id)?.area);
    setExpenseCategory(expensesdata.find((f) => currententrydata.id === f.id)?.category);
    setQttyValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.qtty}`)
    setCunitValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.c_unit}`)
    setTotalCost(expensesdata.find((f) => currententrydata.id === f.id)?.c_total)
  } else if (currententrydata.type_of_entry === 'income') {
    setEntryType('income');
    if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'reservation') === true) {
      setIncomeType('reservation');
      setReservationBind(incomedata.find((f) => currententrydata.id === f.id)?.reservation_id)
      if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'multiple') === true) {
        setPaymentType('multiple');
        setNumberOfPaymentsValue(`${incomedata.find((f) => currententrydata.id === f.id)?.number_of_payments}`);
        setPaymentOrdinalValue(`${incomedata.find((f) => currententrydata.id === f.id)?.payment_ordinal}`);
        setAmountAgreedValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_agreed}`);
        setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
        setPrStatus(incomedata.find((f) => currententrydata.id === f.id)?.status)
      } else if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'single') === true) {
        setPaymentType('single');
        setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
      }
  } else if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'other') === true) {
    setIncomeType('other');
    setPaymentType('single');
    setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
  }
}
}
//---> Handle Cancel Edit Entry <---

const handleAlertModalClose = () => {
  setInputAlert(false);
  setInputName(null);
}
const handleFormDelete = async () => {
  setDeleteVerificationModal(false);
  let id = currententrydata.id
  isLoading(true);

  if (currententrydata.type_of_entry === 'expense') {
    try {
      const { error } = await supabase
        .from('expenses')
        .delete()
        .eq('id', id)
  
        if (error) {
          throw error
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      handleCancelEntryEditClick();
      getFinanceData();
    }
  } else if (currententrydata.type_of_entry === 'income') {
    try {
      const { error } = await supabase
        .from('income')
        .delete()
        .eq('id', id)
  
        if (error) {
          throw error
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      handleCancelEntryEditClick();
      getFinanceData();
    }
  }
}

const handleFormUpdate = async () => {
  let id = currententrydata.id
  if (entrytype === 'expense') {
    let description = descriptionref.current.textContent
    let comments = commentsref.current.textContent
    if (expensearea === 'Seleccionar') {
      setInputName('Area de Gasto');
      setInputAlert(true);
    } else if (expensecategory === 'select') {
      setInputName('Categoría de Gasto');
      setInputAlert(true);
    } else if (typeof description !== 'string' || description.trim().length <= 3) {
      setInputName('Descripción');
      setInputAlert(true);
    } else if (typeof qttyvalue !== 'string' || qttyvalue.trim().length < 1 || qttyvalue < 1) {
      setInputName('Cantidad');
      setInputAlert(true);
    } else if (typeof cunitvalue !== 'string' || cunitvalue.trim().length < 1 || cunitvalue < 1) {
      setInputName('C/Unidad');
      setInputAlert(true);
    } else if (typeof totalcost !== 'number' || totalcost < 1) {
      setInputName('Ingresa la Cantidad y/o C/Unidad');
      setInputAlert(true);
    } else {
      try {
        isLoading(true);

        const { user } = session

        const { error } = await supabase
          .from('expenses')
          .update({ 
            user_id: user.id,
            area: expensearea,
            category: expensecategory,
            description: description,
            comments: comments,
            qtty: qttyvalue,
            c_unit: cunitvalue,
            c_total: totalcost,
            updated_at: new Date()
            })
          .eq('id', id)

        if (error) {
          throw error
        }
      }
      catch(error) {
        alert(error.message)
      }
      finally {
        getFinanceData();
        handleCancelEntryEditClick();
      }
    }
  } else if (entrytype === 'income') {
    let comments = pcommentsref.current.textContent

    if (incometype === 'select') {
      setInputName('Tipo de Ingreso');
      setInputAlert(true);
    } else if (incometype === 'reservation') {
      if (reservationbind === 'Seleccionar') {
        setInputName('la reservación correspondiente');
        setInputAlert(true);
      } else if (paymenttype === 'select') {
        setInputName('Tipo de Pago');
        setInputAlert(true);
      } else if (paymenttype === 'multiple') {
        if (typeof numberofpaymentsvalue !== 'string' || numberofpaymentsvalue < 1 || numberofpaymentsvalue <= paymentordinalvalue) {
          setInputName('cantidad de cuotas');
          setInputAlert(true);
        } else if (typeof paymentordinalvalue !== 'string' || paymentordinalvalue < 1) {
          setInputName('# de cuota');
          setInputAlert(true);
        } else if (typeof amountagreedvalue !== 'string' || amountagreedvalue < 1) {
          setInputName('monto acordado');
          setInputAlert(true);
        } else if (typeof amountremainingvalue !== 'number' || amountremainingvalue < 1) {
          setInputName('monto acordado y/o el monto pagado');
          setInputAlert(true);
        } else if (typeof amountpaidvalue !== 'string' || amountpaidvalue < 1) {
          setInputName('monto pagado');
          setInputAlert(true);
        } else {
          try {
            isLoading(true);

            let { user } = session

            const { error } = await supabase
              .from('income')
              .update({
                reservation_id: reservationbind,
                user_id: user.id,
                income_type: incometype,
                comments: comments,
                payment_type: paymenttype,
                number_of_payments: numberofpaymentsvalue,
                amount_agreed: amountagreedvalue,
                payment_ordinal: paymentordinalvalue,
                amount_paid: amountpaidvalue,
                amount_remaining: amountremainingvalue,
                updated_at: new Date(),
                status: prstatus
              })
              .eq('id', id)

            if (error) {
              throw error
            }
          }
          catch(error) {
            alert(error.message)
          }
          finally {
            getFinanceData();
            handleCancelEntryEditClick();
          }
        }
      }
      
    } else {
      if (incometype === 'reservation') {
        if (paymenttype === 'single') {
          setNumberOfPaymentsValue(null);
          setPaymentOrdinalValue(null);
          setAmountAgreedValue(null);
          setAmountPaidValue(null);
          setAmountRemainingValue(null);
          setPrStatus('canceled');
        }
        
        try {
          isLoading(true);

          let { user } = session

          const { error } =  await supabase
            .from('income')
            .update({
              reservation_id: reservationbind,
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              number_of_payments: numberofpaymentsvalue,
              amount_agreed: amountagreedvalue,
              payment_ordinal: paymentordinalvalue,
              amount_paid: amountpaidvalue,
              amount_remaining: amountremainingvalue,
              updated_at: new Date(),
              status: 'canceled'
            })
            .eq('id', id)

          if (error) {
            throw error
          }
        }
        catch(error) {
          alert(error.message)
        }
        finally {
          getFinanceData();
          handleCancelEntryEditClick();
        }
      } else if (incometype === 'other') {

        try {
          isLoading(true);

          let { user } = session
          
          const { error } =  await supabase
            .from('income')
            .update({
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              amount_paid: amountpaidvalue,
              updated_at: new Date(),
              status: 'canceled'
            })
            .eq('id', id)

          if (error) {
            throw error
          }
        }
        catch(error) {
          alert(error.message)
        }
        finally {
          getFinanceData();
          handleCancelEntryEditClick();
        }
      }
    }
  }
}

// -------> END - Click Handles & Event Handles <-------

// -------> START - Hook Calls <-------
const windowsize = useWindowSize();
// -------> END - Hook Calls <-------

  return (
    <div className='allentries' style={{ alignItems: loading === true ? 'center' : 'flex-start', justifyContent: loading === true ? 'center' : 'unset', height: entryselected === true ? 'fit-content' : windowsize.width <= 1024 ? '65rem' : '61.5rem' }} >
      {
        loading ?
        (
          <svg className='allentries__loader-container' viewBox="25 25 50 50">
            <circle className='allentries__loader-stroke' r="20" cy="50" cx="50"></circle>
          </svg>
        )
        :
        (
          entryselected === true ?
          (
            <>
            <div className="cdashboard__deleteconfirmation-modal" 
              style={{
                display: deleteverificationmodal === true
                ? 'flex'
                : 'none'
              }}
            >
              <div className="cdashboard__dcmodal-title">
                <p>
                  ¿Estas Seguro?
                </p>
              </div>
              <div className="cdashboard__dcmodal-message">
                <p>
                  ¿Estas seguro que deseas borrar esta entrada? Esta acción no puede deshacerse.
                </p>
              </div>
              <div className="cdashboard__dcmodal-ctas">
                <div onClick={handleFormDelete}>
                  <p>
                    Borrar
                  </p>
                </div>
                <div onClick={() => setDeleteVerificationModal(false)}>
                  <p>
                    Cancelar
                  </p>
                </div>
              </div>
            </div>
            <div className="cdashboard__deleteconfirmation-modal" 
              style={{
                display: inputalert === true
                ? 'flex'
                : 'none'
              }}
            >
              <div className="cdashboard__dcmodal-title">
                <p>
                  ¡Parece que algo hace falta!
                </p>
              </div>
              <div className="cdashboard__dcmodal-message">
                <p>
                  Por favor ingresa el <span style={{ textDecoration: 'underline', background: 'none' }}>{inputname}</span>
                </p>
              </div>
              <div className="cdashboard__dcmodal-ctas">
                <div onClick={handleFormDelete} style={{ display: 'none' }}>
                  <p>
                    Borrar
                  </p>
                </div>
                <div onClick={handleAlertModalClose}>
                  <p>
                    Ok!
                  </p>
                </div>
              </div>
            </div>
            <div className='allentries__entryselected-header'>
              <div className='header__navwrapper-navbutton' onClick={handleExitSelectedEntryClick}>
                <CircledLeft />
              </div>
              <div className='entryselected__header-title'>
                <p>
                  {
                    currententrydata.type_of_entry === 'expense' ?
                    expensesdata.find((i) => currententrydata.id === i.id)?.description
                    :
                    currententrydata.category === 'reservation' ?
                    incomedata.find((i) => currententrydata.id === i.id)?.reservations.name
                    :
                    incomedata.find((i) => currententrydata.id === i.id)?.comments
                  }
                </p>
              </div>
            </div>
            <div className='allentries__contentwrapper' style={{ height: entryselected === true ? 'fit-content' : '94%' }}>
            <div className="financeform__header">
                  <div id="et" className="financeformheader__picker-selected" onClick={() => setEntryTypePicker(!entrytypepicker)} style={{ pointerEvents: 'none' }} >
                    <p id='etp'>
                      {
                        entrytype === 'expense'
                        ? 'Gasto'
                        : entrytype === 'income'
                        ? 'Ingreso'
                        : 'Error'
                      }
                    </p>
                    <ChevronDown id='eti' />
                    <div 
                      className="typeofregistry__picker"
                      style={{
                        display: entrytypepicker === true ? 'flex' : 'none'
                      }}
                    >
                      <div id='ettg' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettgp'>
                          Gasto
                        </p>
                      </div>
                      <div id='etti' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettip'>
                          Ingreso
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='financeform__header-buttonswrapper' >
                    <div onClick={() => setEditEntry(true)} className='financeform__header-controls' style={{ display: editentry === true ? 'none' : 'flex', backgroundColor: '#B7CEE9' }} >
                      <Edit />  
                    </div> 
                    <div onClick={handleCancelEntryEditClick} className='financeform__header-controls' style={{ display: editentry === true ? 'flex' : 'none', backgroundColor: '#B7CEE9' }} >
                      <Cancel />  
                    </div> 
                    <div onClick={handleFormUpdate}  style={{ display: editentry === true ? 'flex' : 'none', backgroundColor: '#B7CEE9' }} className='financeform__header-controls' >
                      <Save />  
                    </div> 
                    <div onClick={() => setDeleteVerificationModal(true)}  className='financeform__header-controls' style={{ backgroundColor: '#B7CEE9' }} >
                      <Remove />  
                    </div> 
                    <Income 
                        style={{
                          display: currententrydata.type_of_entry === 'income'
                          ? 'flex'
                          : 'none'
                        }}
                      />
                    <Expense 
                      style={{
                        display: currententrydata.type_of_entry === 'expense'
                        ? 'flex'
                        : 'none'
                      }}
                    />
                  </div>
                </div>
                <div className="fiform__fieldswrapper" style={{ pointerEvents: editentry === true ? 'all' : 'none' }} >
                  {
                    entrytype === 'expense'
                    ?
                    (
                      <>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                            <p>
                              area de gasto:
                            </p>
                          </div>
                          <div id='ea' className="typeof__picker" onClick={() => setExpenseAreaPicker(!expenseareapicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none', backgroundColor: '#D4E1F0' }} >
                            <p id='eap' style={{ textTransform: 'capitalize' }} >
                              {
                                expensearea
                              }
                            </p>
                            <ChevronDown id='eai' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expenseareapicker === true
                                ? 'flex'
                                : 'none'
                              }}
                            >
                              <div onClick={() => {setExpenseArea('capital'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Capital
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('operaciones'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Operaciones
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('marketing'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Marketing
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                            <p>
                              categoría:
                            </p>
                          </div>
                          <div id='ec' className="typeof__picker" onClick={() => setExpenseCategoryPicker(!expensecategorypicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none', backgroundColor: '#D4E1F0' }} >
                            <p id='ecp' style={{ textTransform: 'capitalize' }} >
                              {
                                expensecategory === 'select'
                                ? 'Seleccionar'
                                : expensecategory
                              }
                            </p>
                            <ChevronDown id='eci' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expensecategorypicker === true
                                ? 'flex'
                                : 'none'
                              }}
                            >
                              {
                                expensearea === 'capital'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('titulos y propiedades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Titulo y Propiedades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('construccion'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Construcción
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'operaciones'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('impuestos'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Impuestos
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('salarios'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Salarios
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('utilidades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Utilidades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('mantenimiento'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Mantenimiento
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('suministros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Suministros
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('juridico'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Jurídico
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'marketing'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('digital'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Digital
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('afiliados'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Afiliados
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('otros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Otros
                                      </p>
                                    </div>
                                  </>
                                )
                                :
                                (
                                  <div>
                                      <p>
                                        Selecciona una Area
                                      </p>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                descripción:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper" style={{ backgroundColor: '#D4E1F0' }}>
                            <p
                              contentEditable='true'
                              placeholder="Ingresar descripción"
                              ref={descriptionref}
                              style={{
                                whiteSpace: editentry === true
                                ? 'unset'
                                : 'nowrap'
                              }}
                            >
                              {
                                expensesdata.find((f) => currententrydata.id === f.id)?.description
                              }
                            </p>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper" style={{ backgroundColor: '#D4E1F0' }}>
                            <p
                              contentEditable='true'
                              placeholder="Ingresar comentarios"
                              ref={commentsref}
                              style={{
                                whiteSpace: editentry === true
                                ? 'unset'
                                : 'nowrap'
                              }}
                            >
                              {
                                expensesdata.find((f) => currententrydata.id === f.id)?.comments
                              }
                            </p>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                cantidad:
                              </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' className='fiformfieldrow__input' value={qttyvalue} onChange={(e) => setQttyValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/unidad:
                              </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' className='fiformfieldrow__input' value={cunitvalue} onChange={(e) => setCunitValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/total:
                              </p>
                          </div>
                          <CurrencyInput size='8' value={totalcost} type='text' className='fiformfieldrow__input' style={{ pointerEvents: 'none', backgroundColor: '#D4E1F0' }} />
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                fecha de gasto:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper" style={{ backgroundColor: '#D4E1F0' }}>
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                dayjs(currententrydata.date_of_entry).format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    : entrytype === 'income'
                    ?
                    (
                      <>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title">
                            <p>
                              tipo de ingreso:
                            </p>
                          </div>
                          <div id='ei' className="typeof__picker" onClick={() => setIncomeTypePicker(!incometypepicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none', backgroundColor: '#D4E1F0' }} >
                            <p id='eip'>
                              {
                                incometype === 'reservation'
                                ? 'Reservaciones'
                                : incometype === 'other'
                                ? 'Otros'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='eii' />
                            <div className="typeof__ddown" style={{ display: incometypepicker === true ? 'flex' : 'none' }}>
                              <div onClick={() => {setIncomeType('reservation'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Reservación
                                </p>
                              </div>
                              <div onClick={() => {setIncomeType('other'); setPaymentType('single'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Otro
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper" style={{ display: incometype === 'reservation' ? 'flex' : 'none' }} >
                          <div className='fiformfieldrow__title'>
                            <p>
                              reservación:
                            </p>
                          </div>
                          <div className="typeof__picker" style={{ pointerEvents: 'none', backgroundColor: '#D4E1F0' }} >
                            <p
                              style={{
                                textTransform: 'unset'
                              }}
                            >
                              {
                                reservationbind
                              }
                            </p>
                          </div>
                        </div>
                        <div className='entryselected__form-rowwrapper'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              tipo de pago:
                            </p>
                          </div>
                          <div id='ep' className='typeof__picker' onClick={() => setPaymentTypePicker(!paymenttypicker)} style={{ pointerEvents: incometype === 'other' ? 'none' : editentry === true ? 'all' : 'none', backgroundColor: '#D4E1F0' }} >
                            <p id='epp'>
                              {
                                paymenttype === 'multiple'
                                ? 'Cuotas'
                                : paymenttype === 'single'
                                ? 'Único'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='epi' />
                            <div className='typeof__ddown' style={{ display: paymenttypicker === true ? 'flex' : 'none' }} >
                              <div onClick={() => {setPaymentType('multiple'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Cuotas
                                </p>
                              </div>
                              <div onClick={() => {setPaymentType('single'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Único
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="entryselected__form-rowwrapper">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper" style={{ backgroundColor: '#D4E1F0' }}>
                            <p
                              contentEditable='true'
                              placeholder="de la venta de leña"
                              ref={pcommentsref}
                            >
                              {
                                incomedata.find((f) => currententrydata.id === f.id)?.comments
                              }
                            </p>
                          </div>
                        </div>
                        <div className='entryselected__form-rowwrapper' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              cantidad de cuotas:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' className='fiformfieldrow__input' value={numberofpaymentsvalue} onChange={(e) => setNumberOfPaymentsValue(e.currentTarget.value)} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className='entryselected__form-rowwrapper' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              # de cuota:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='1' type='text' className='fiformfieldrow__input' value={paymentordinalvalue} onChange={(e) => setPaymentOrdinalValue(e.currentTarget.value)} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className='entryselected__form-rowwrapper' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto acordado:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' className='fiformfieldrow__input' value={amountagreedvalue} onChange={(e) => setAmountAgreedValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className='entryselected__form-rowwrapper'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto recibido:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' className='fiformfieldrow__input' value={amountpaidvalue} onChange={(e) => setAmountPaidValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} style={{ backgroundColor: '#D4E1F0'}} />
                        </div>
                        <div className='entryselected__form-rowwrapper' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto restante:
                            </p>
                          </div>
                          <CurrencyInput size='8' value={amountremainingvalue} type='text' className='fiformfieldrow__input' style={{ backgroundColor: '#D4E1F0'}}/>
                        </div>
                        <div className='entryselected__form-rowwrapper'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              fecha de entrada
                            </p>
                          </div>
                          <div className='rowwrapper__value-wrapper' style={{ backgroundColor: '#D4E1F0' }} >
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                dayjs(currententrydata.date_of_entry).format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    :
                    (
                      <div>
                        Error
                      </div>
                    )
                  }
                </div>
            </div>
            </>
          )
          :
          ( <>
              <div className='allentries__header'>
                <div className='allentries__header-navwrapper'>
                  <NavLink className='header__navwrapper-navbutton' to='/finanzas'>
                    <CircledLeft />
                  </NavLink>
                  <div className='header__navwrapper-searchbar'>
                    <Search />
                    <input 
                      className='navwrapper__searchbar-input'
                      placeholder='Buscar...'
                      type="text" 
                      onChange={(e) => setSearchText(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className='allentries__header-filterwrapper'>
                  <div className='header__filterwrapper-picker' id='tf' onClick={() => setPickerId('t')}>
                    <p id='tf'>
                      {
                        timerangefilter === 'c_week'
                        ? 'Semana Pasada'
                        : timerangefilter === 'p3_week'
                        ? 'Ultimas 3 Semanas'
                        : timerangefilter === 'c_month'
                        ? 'Este Mes'
                        : timerangefilter === 'p_month'
                        ? 'Mes Pasado'
                        : timerangefilter === 'p3_month'
                        ? 'Ultimos 3 Meses'
                        : timerangefilter === 'p6_month'
                        ? 'Ultimos 6 Meses'
                        : timerangefilter === 'c_year'
                        ? 'Este Año'
                        : timerangefilter === 'p_year'
                        ? 'Año Pasado'
                        : 'Seleccionar'
                      }
                    </p>
                    <ChevronDown id='tf' />
                    <div className='filterwrapper__picker-ddown' id='tf_d' style={{ display: pickerid === 't' ? 'flex' : pickerid === 'tw' ? 'flex' : pickerid === 'tm' ? 'flex' : pickerid === 'ty' ? 'flex' : 'none' }}>
                      <div className='picker__ddown-option' id='tf_dw' onClick={() => setTimeRangePicker('tw')}>
                        <p id='tf_dw'>
                          Por Semana
                        </p>
                        <ChevronDown id='tf_dw' />
                        <div className='picker__hdown' style={{ display: timerangepicker === 'tw' ? 'flex' : 'none' }} >
                          <div onClick={() => setTimeRangeFilter('c_week')} >
                            <p>
                              Semana Pasada
                            </p>
                          </div>
                          <div onClick={() => setTimeRangeFilter('p3_week')} >
                            <p>
                              Ultimas 3 Semanas
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='picker__ddown-option' id='tf_dm' onClick={() => setTimeRangePicker('tm')}>
                        <p id='tf_dm'>
                          Por Mes
                        </p>
                        <ChevronDown id='tf_dm' />
                        <div className='picker__hdown'  style={{ display: timerangepicker === 'tm' ? 'flex' : 'none' }}>
                          <div onClick={() => setTimeRangeFilter('c_month')} >
                            <p>
                              Este Mes
                            </p>
                          </div>
                          <div onClick={() => setTimeRangeFilter('p3_month')} >
                            <p>
                              Ultimos 3 Meses
                            </p>
                          </div>
                          <div onClick={() => setTimeRangeFilter('p6_month')} >
                            <p>
                              Ultimos 6 Meses
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='picker__ddown-option' id='tf_dy' onClick={() => setTimeRangePicker('ty')} >
                        <p id='tf_dy'>
                          Por Año
                        </p>
                        <ChevronDown id='tf_dy' />
                        <div className='picker__hdown' style={{ display: timerangepicker === 'ty' ? 'flex' : 'none' }}>
                          <div onClick={() => setTimeRangeFilter('c_year')} >
                            <p>
                              Este Año
                            </p>
                          </div>
                          <div onClick={() => setTimeRangeFilter('p_year')} >
                            <p>
                              Ultimos 2 Años
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='header__filterwrapper-picker' id='cf' onClick={() => setPickerId('c')} >
                    <p id='cf'>
                      {
                        typefilter === 'all'
                        ? 'Todos'
                        : typefilter === 'g_capital'
                        ? 'Gastos - Capital'
                        : typefilter === 'g_operaciones'
                        ? 'Gastos - Operaciones'
                        : typefilter === 'g_marketing'
                        ? 'Gastos - Marketing'
                        : typefilter === 'i_reservaciones'
                        ? 'Ingresos - Reservaciones'
                        : typefilter === 'i_otros'
                        ? 'Ingresos - Otros'
                        : 'Seleccionar'
                      }
                    </p>
                    <ChevronDown id='cf' />
                    <div className='filterwrapper__picker-ddown' id='cf_d' style={{ display: pickerid === 'c' ? 'flex' : pickerid === 'cg' ? 'flex' : pickerid === 'ci' ? 'flex' : 'none' }} >
                      <div className='picker__ddown-option' id='cf_da' onClick={handleAllEntriesClick}>
                        <p id='cf_da'>
                          Todos
                        </p>
                      </div>
                      <div className='picker__ddown-option' id='cf_dg' onClick={() => setCategoryPicker('cg')} >
                        <p id='cf_dg'>
                          Gastos
                        </p>
                        <ChevronDown id='cf_dg' />
                        <div className='picker__hdown' style={{ display: categorypicker === 'cg' ? 'flex' : 'none' }}>
                          <div onClick={() => setTypeFilter('g_capital')}>
                            <p>
                              Capital
                            </p>
                          </div>
                          <div onClick={() => setTypeFilter('g_operaciones')}>
                            <p>
                              Operaciones
                            </p>
                          </div>
                          <div onClick={() => setTypeFilter('g_marketing')}>
                            <p>
                              Marketing
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='picker__ddown-option' id='cf_di' onClick={() => setCategoryPicker('ci')} >
                        <p id='cf_di'>
                          Ingresos
                        </p>
                        <ChevronDown id='cf_di' />
                        <div className='picker__hdown' style={{ display: categorypicker === 'ci' ? 'flex' : 'none' }}>
                          <div onClick={() => setTypeFilter('i_reservaciones')}>
                            <p>
                              Reservaciones
                            </p>
                          </div>
                          <div onClick={() => setTypeFilter('i_otros')}>
                            <p>
                              Otros
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='header__filterwrapper-picker' id='sf' onClick={() => setPickerId('s')}>
                    <p id='sf'>
                      {
                        ascending === true
                        ? 'Por Fecha ↑'
                        : 'Por Fecha ↓'
                      }
                    </p>
                    <div className='filterwrapper__picker-ddown' style={{ display: pickerid === 's' ? 'flex' : 'none' }}>
                      <div className='picker__ddown-option' onClick={() => setAscending(true)}>
                        <p>
                          Ascendente ↑
                        </p>
                      </div>
                      <div className='picker__ddown-option' onClick={() => setAscending(false)}>
                        <p>
                          Descendente ↓
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='allentries__contentwrapper'>
                <div className='contentwrapper__table-headerswrapper'>
                  <div className='table__headers'>
                    <h5>
                      Descripción
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Tipo de Entrada
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Area de Entrada
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Categoria de Entrada
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Tipo de Pago
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Cuotas Restantes
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Monto Acordado
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Monto Recibido
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Monto Restante
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      Cantidad
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      C/Unidad
                    </h5>
                  </div>
                  <div className='table__headers'>
                    <h5>
                      C/Total
                    </h5>
                  </div>
                </div>
                <ScrollContainer className='contentwrapper__table-entrieswrapper'>
                  {
                    windowsize.width <= 1024 ?
                    (
                      typefilter === 'all' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_capital' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                              f.category === 'capital' &&
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'capital'
                          ).map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_operaciones' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                              f.category === 'operaciones' &&
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'operaciones'
                          ).map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_marketing' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                              f.category === 'marketing' &&
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'marketing'
                          ).map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'i_reservaciones' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                              f.category === 'reservation' &&
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'reservation'
                          ).map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'i_otros' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'other' &&
                              Object.values(f).some((element) =>
                                typeof element === 'string' ?
                                element.toLowerCase().includes(searchtext.toLowerCase())
                                :
                                element === searchtext
                            )
                            ).map(
                              (f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'other'
                          ).map((f) => (
                            <div key={f.id} className='table__entrywrapper-mobile' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__mobile-title'>
                                <p>
                                  {
                                    f.type_of_entry === 'expense' ?
                                    (
                                      expensesdata.find((i) => f.id === i.id)?.description
                                    )
                                    :
                                    (
                                      incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                      'Reservación'
                                      : incomedata.find((i) => f.id === i.id)?.comments
                                    )
                                  }
                                </p>
                              </div>
                              <div className='mobile__data-wrapper'>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          dayjs(expensesdata.find((i) => f.id === i.id)?.date_of_expense).format('D - MMM')
                                        )
                                        :
                                        (
                                          dayjs(incomedata.find((i) => f.id === i.id)?.date_of_payment).format('D - MMM')
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          'Gasto'
                                        )
                                        :
                                        (
                                          'Ingreso'
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns'>
                                  <div className='mobile__dpill-dark'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.area
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          'Reservación'
                                          : 'Otros'
                                        )
                                      }
                                    </p>
                                  </div>
                                  <div className='mobile__dpill-light'>
                                    <p>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          expensesdata.find((i) => f.id === i.id)?.category
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                          (
                                            incomedata.find((i) => f.id === i.id)?.reservations.name
                                          )
                                          :
                                          (
                                            incomedata.find((i) => f.id === i.id)?.comments
                                          )
                                        )
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className='mobile__dwrapper-columns' style={{ width:  '132px'}}>
                                  <div className={ incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ? 'mobile__ipaid-total' : 'mobile__dpill-dark' } style={{ width: '100%' }}>
                                      {
                                        f.type_of_entry === 'expense' ?
                                        (
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                            }
                                          </p>
                                        )
                                        :
                                        (
                                          incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                          (
                                            <>
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                            <div>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_agreed}`
                                                }
                                              </p>
                                            </div>
                                            </>
                                          )
                                          :
                                          (
                                            <p>
                                              {
                                                `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                              }
                                            </p>
                                          )
                                        )
                                      }
                                  </div>
                                  <div className='mobile__ipaid-detailswrapper'>
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        <>
                                        <div className='mobile__dpill-light' style={{ width: 'fit-content' }}>
                                          <p>
                                            {
                                              expensesdata.find((i) => f.id === i.id)?.qtty
                                            }
                                          </p>
                                        </div>
                                        <div className='mobile__dpill-nobg' style={{ width: '100%' }}>
                                          <p>
                                            {
                                              `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                            }
                                          </p>
                                        </div>
                                        </>
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          <>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                Cuotas
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                                }
                                              </p>
                                            </div>
                                            <div className='mobile__dpill-light'>
                                              <p>
                                                {
                                                  `$${incomedata.find((i) => f.id === i.id)?.amount_remaining}`
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )
                                        :
                                        (
                                          <>
                                            <div className='mobile__dpill-light'style={{ width: '100%' }}>
                                              <p>
                                                Único
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      :
                      (
                        <div></div>
                      )
                    )
                    :
                    (
                      typefilter === 'all' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_capital' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'capital' &&
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'capital'
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_operaciones' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'operaciones' &&
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'operaciones'
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'g_marketing' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'marketing' &&
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'marketing'
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'i_reservaciones' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'reservation' &&
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'reservation'
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      : typefilter === 'i_otros' ?
                      (
                        searchtext !== '' ?
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'other' &&
                            Object.values(f).some((element) =>
                              typeof element === 'string' ?
                              element.toLowerCase().includes(searchtext.toLowerCase())
                              :
                              element === searchtext
                            )
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                        :
                        (
                          financedata.filter(
                            (f) =>
                            f.category === 'other'
                          ).map((f) => (
                            <div key={f.id} className='contentwrapper__table-entrywrapper' onClick={() => handleEntryRowClick(f)}>
                              <div className='entrywrapper__datawrapper' style={{ paddingLeft: '6px' }}>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.description
                                      )
                                      :
                                      (
                                        incomedata.some((i) => f.id === i.id && i.income_type === 'reservation') ?
                                        'Reservación'
                                        : incomedata.find((i) => f.id === i.id)?.comments
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' >
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.area
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        'Reservación'
                                        : 'Otros'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.category
                                      )
                                      :
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'reservation') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.reservations.name
                                        )
                                        :
                                        (
                                          incomedata.find((i) => f.id === i.id)?.comments
                                        )
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'single') ?
                                        'Único'
                                        : incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        'Cuotas'
                                        : 'Error'
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.income_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_agreed
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        `$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'income' ?
                                      (
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple') ?
                                        (
                                          incomedata.find((i) => f.id === i.id)?.amount_remaining
                                        )
                                        :
                                        (
                                          'N/A'
                                        )
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ backgroundColor: '#D4E1F0' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill' style={{ background: 'none' }}>
                                  <p
                                    style={{
                                      color: '#374758'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expense' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='entrywrapper__datawrapper'>
                                <div className='datawrapper__datapill'>
                                  <p
                                    style={{
                                      color: '#D4E1F0'
                                    }}
                                  >
                                    {
                                      f.type_of_entry === 'expens' ?
                                      (
                                        `$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                      )
                                      :
                                      (
                                        'N/A'
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      )
                      :
                      (
                        <div></div>
                      )
                    )
                  }
                </ScrollContainer>
              </div>
            </>
          )
        )
      }
    </div>
  )
}

export default AllEntradas