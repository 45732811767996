import styled from 'styled-components'

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    height: fit-content;
    gap: 8px;
    padding: 12px;
    background-color: var(--neutral-light_2);
    border-radius: 16px;
    box-shadow: 0px 0px 9px rgba(1, 17, 35, 0.02), 0px 0px 8px rgba(1, 17, 35, 0.06), 0px 0px 6px rgba(1, 17, 35, 0.1), 0px 0px 3px rgba(1, 17, 35, 0.12), 0px 0px 0px rgba(1, 17, 35, 0.12);
    border: none;
    appearance: none;
    text-shadow: none;
    outline: none;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        outline: none;
    }
`

export const XLabel = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    color: var(--neutral-dark_2);
    text-transform: capitalize;
`

export const YLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    min-width: fit-content;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
`

export const YLabelName = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    color: var(--neutral-dark_2);
    text-transform: capitalize;
`
export const YLabelValue = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--neutral-light_2);

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 1em;
        color: var(--neutral-dark_2);
        text-transform: capitalize;
    }
`