import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import {
  ComposedChart,
  Area,
  Bar,
  Cell,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../../../supabaseClient'

import './EconomyChart.scss'
import '../Charts.css'
import * as CTStyles from '../CustomTooltipStyles'

const CustomTooltip = ({ active, payload, label }) => {
  if ( active && payload && payload.length ) {
      return (
          <CTStyles.MainContainer style={{ backgroundColor: 'var(--neutral-light_2' }} >
              <CTStyles.XLabel>
                {`${label}:`}
              </CTStyles.XLabel>
              <CTStyles.YLabelWrapper>
                <CTStyles.YLabelName>
                  {`${payload[0].name}:`}
                </CTStyles.YLabelName>
                <CTStyles.YLabelValue style={{ backgroundColor: 'var(--neutral-light)' }} >
                  <p>
                    {`$${payload[0].value}`}
                  </p>
                </CTStyles.YLabelValue>
              </CTStyles.YLabelWrapper>
              <CTStyles.YLabelWrapper>
                <CTStyles.YLabelName>
                  {`${payload[1].name}:`}
                </CTStyles.YLabelName>
                <CTStyles.YLabelValue style={{ backgroundColor: 'var(--neutral-light)' }} >
                  <p>
                    {`$${payload[1].value}`}
                  </p>
                </CTStyles.YLabelValue>
              </CTStyles.YLabelWrapper>
          </CTStyles.MainContainer>
      )
  }

  return null;
}

const EconomyChart = ({ session }) => {
  // -------> START - Time Context <-------
  // Time Formats & Eval Functions
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);
  // Time Formats & Eval Functions
  
  // Time Contexts & Delimiters 
  const [today, setToday] = useState(dayjs());
  const sixMonthsBefore = useMemo(
    () => dayjs(today).subtract(6, 'month'),
    [dayjs(today).format('YYYY-MM-DD')]
  )
  // Time Contexts & Delimiters
  // -------> END - Time Context <-------
  
  // -------> START - useStates <-------
  const [loading, isLoading] = useState(true);
  const [gettingchartdata, setGettingChartData] = useState(true);
  const [rawdata, setRawData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  // -------> START - useStates <-------
  
  // -------> START - Get Chart Data <-------
  useEffect(() => {
    getChartData();
  }, [dayjs(today).format('YYYY-MM-DD')])

  const getChartData = async () => {
    setGettingChartData(true);
    try {
      let { data, error }= await supabase
        .from('total_expenses_income')
        .select()
        .gte('month', dayjs(sixMonthsBefore).format('YYYY-MM-DD'))
        .lte('month', dayjs(today).format('YYYY-MM-DD'))
        .order('month', {ascending: true})

        if (error) {
          throw error
        }
        if (data) {
          if(data.length === 0)  {
            console.log('No Finance Data Error - Empty Array')
          } else {
            setRawData(data);
          }
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingChartData(false);
    }
  }

  useEffect(() => {
    if (gettingchartdata === false && rawdata.length !== 0) {
      rawdata.map((d) => {
        // const mes = dayjs(d.month).format('MMMM');
        // const gastos = `$${parseFloat(d.total_expenses).toFixed(2)}`;
        // const ingresos = `$${parseFloat(d.total_income).toFixed(2)}`;
        setChartData((current) => [...current, 
          {
            mes: dayjs(d.month).format('MMM'), 
            gastos: parseFloat(d.total_expenses).toFixed(2), 
            ingresos: d.total_income === null ?
            0
            : parseFloat(d.total_income).toFixed(2)
          }
        ]);
        isLoading(false);
      })
    }
  }, [gettingchartdata, rawdata])
  // -------> END - Get Chart Data <-------

  return (
    <>
      {
        loading ?
        (
          <svg className='allentries__loader-container' viewBox="25 25 50 50">
            <circle className='allentries__loader-stroke' r="20" cy="50" cx="50"></circle>
          </svg>
        )
        :
        (
          <ResponsiveContainer
            width='100%'
            aspect={1/1}
          >
            <ComposedChart
              width={
                320
              }
              height={
                320
              }
              data={chartdata}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 20,
              }}
              style={{
                background: 'none'
              }}
            >
              <defs>
                <linearGradient id='gradientarea' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#374758' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#374758' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='gradienthigherexpense' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#D34423' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#D34423' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='gradientlowerexpense' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#3A9659' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#3A9659' stopOpacity={0} />
                </linearGradient>
              </defs>
              <YAxis dataKey='ingresos' stroke='gray' type="number" domain={[0, 4000]} />
              <XAxis dataKey='mes' stroke='gray' interval='preserveStartEnd' />
              <CartesianGrid strokeDasharray='3 3' className='echart__grid'  />
              <Tooltip content={CustomTooltip} wrapperStyle={{ outline: 'none' }} />
              <Bar stackId='a' dataKey='gastos' radius={12}>
                {
                  chartdata.map((data, index) => (
                    <Cell fill={data.gastos > data.ingresos ? 'url(#gradienthigherexpense)' : 'url(#gradientlowerexpense)'} />
                  ))
                }
              </Bar>
              <Area type='monotone' dataKey='ingresos' stroke='#374758' strokeWidth='4px' fillOpacity={1} fill='url(#gradientarea)' />
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
    </>
  )
}

export default EconomyChart