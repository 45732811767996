import { styled, css } from '../../../stitches.config';

export const MainWrapper = styled("div", {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: 32,
  padding: 'clamp(1.5rem, 2rem, 1.7vw)',
  backgroundColor: "#D4E1F0",
  gap: '24px',
  userSelect: 'none',
  zIndex: 1,

  '@bp1': {
    width: '100%',
    height: 'fit-content',
    overflow: 'hidden',
  },
});

export const RowsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '2rem',

  '@bp1': {
    flexDirection: 'column',
    gap: '1.5rem',
    height: 'fit-content',
  },
})

export const ChatsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  gap: '24px',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '1',
  background: 'none',

  '@bp1': {
    height: 'fit-content'
  },
  '@bp2': {
    height: 'fit-content'
  }
})

export const ChatsHeaderWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  height: 'fit-content',
  gap: '16px',
  order: '0',
  alignSelf: 'stretch',
  flexGrow: '0',
  background: 'none',
})

export const ChatsHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  background: 'none',
  alignItems: 'flex-start',

  'p': {
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: '500',
    textAlign: 'left',
    color: 'var(--neutral-dark_3)',
    background: 'none',
    textTransform: 'capitalize',
  },

  'h3': {
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: '500',
    textAlign: 'left',
    color: 'var(--neutral-dark_3)',
    background: 'none',
    textTransform: 'capitalize',
  },
})

export const ChatsBubblesWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  gap: '32px',
  justifyContent: 'space-between',
  background: 'none',

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: 'fit-content',
    alignItems: 'center',
    gap: '4px',
    background: 'none',
    
    'div': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '32px',
      height: '32px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: 'var(--neutral-light)',
      overflow: 'hidden',

      'svg': {
        fontSize: '1.25em',
        color: '#374758',
        background: 'none'
      },

      'img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },

    'p': {
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: '500',
      color: '#374758',
      textAlign: 'center',
      background: 'none',
    }
  }
})

export const ChatsCard = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  backgroundColor: 'var(--neutral-light)',
  borderRadius: '10px',
  padding: '16px',

  '@bp1': {
    height: 'auto',
  },
})

export const ChatsCardRow = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',

  'div:nth-child(1)': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: 'fit-content',
    alignItems: 'center',
    gap: '12px',
    background: 'none',

    'div:nth-child(1)': {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      background: 'none',
      overflow: 'hidden',

      'img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },

    'div:nth-child(2)': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: 'fit-content',
      maxWidth: '120px',
      height: 'fit-content',
      alignItems: 'flex-start',
      justifyContent: 'left',
      gap: '4px',
      background: 'none',
      overflow: 'hidden',

      'h5': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'left',
        color: '#374758',
        background: 'none',
        textTransform: 'capitalize',
      },

      'p': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
        color: '#5D7086',
        background: 'none',
        maxWidth: '120px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    }
  },

  'div:nth-child(2)': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '4px',
    background: 'none',
    
    'p': {
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
        color: '#5D7086',
        background: 'none',
        textTransform: 'capitalize',
    },

    'div': {
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      width: '100%',
      height: 'fit-content',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: 'none',

      'svg': {
        fontSize: '1em',
        color: '#5D7086',
        background: 'none'
      },

      'div': {
        width: '16px',
        height: '16px',
        backgroundColor: '#7D83FF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',

        'p': {
          fontStyle: 'normal',
          fontSize: '10px',
          fontWeight: '700',
          color: '#fff',
          background: 'none',
        }
      }
    },
  },
})

export const OccupancyAndEconomyWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: '50%',
  alignItems: 'flex-start',
  gap: '32px',
  background: 'none',
  order: '1',
  flexGrow: 1,
  alignSelf: 'stretch',

  '@bp1': {
    height: 'auto',
  },
  '@bp2': {
    flexDirection: 'column',
  },
})

export const ChartCard = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'none',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '8px',
  padding: '16px 0px',
  backgroundColor: 'var(--neutral-light)',
  borderRadius: '24px',
  order: '1',
  alignSelf: 'stretch',
  flexGrow: '1',

  '@bp1': {
    height: 'auto',
  }
})

export const ChartHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height:  'fit-content',
  alignItems: 'flex-start',
  padding: '0px 16px',
  background: 'none',
  justifyContent: 'space-between',

  'h3': {
    fontStyle: 'normal',
    fontSize: 'clamp(1.25em, 1.5em, 1.666vw)',
    fontWeight: '500',
    color: '#374758',
    textTransform: 'capitalize',
    background: 'none',
    textAlign: 'left',
  },

  'svg': {
    fontSize: 'clamp(1.25em, 1.5em, 1.666vw)',
    color: '#374758',
    background: 'none',
  }
})

export const ChartComponentContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',

  'div': {
    background: 'none',

    'div': {
      background: 'none',

      'svg': {
        background: 'none'
      }
    }
  }
})