import { useCallback, useMemo, useState, useEffect, useRef } from "react"
import { NavLink } from "react-router-dom"
import { motion } from 'framer-motion'
import styled from "styled-components"
import dayjs from "dayjs"
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../supabaseClient'
import ScrollContainer from 'react-indiana-drag-scroll'

import * as Styles from "./CFinanceStyles"
import './CFinance.scss'
import { CircledLeft, CircledRight, Income, Plus, Contact, Clear, Save, ChevronDown, Expense, Remove, Cancel, ReservationForm, Edit } from '../../../assets/icons/Index'
import { useWindowSize } from "../../../hooks/useWindowSize/useWindowSize"
import CurrencyInput from '../../CurrencyInput/CurrencyInput'
import QttyInput from '../../QttyInput/QttyInput'

const ViewAllCTA = styled(NavLink)`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 4px;
  background: none;
  border: 1px solid #374758;
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  transition: all 0.4s ease-out;
  text-decoration: none;

  &:hover{
    background-color: #374758;

    p {
      color: #EBF2FA;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.875em, 1em, 1vw);
    text-align: center;
    text-transform: capitalize;
    color: #374758;
    background: none;
    text-decoraction: none;
  }

  svg {
    font-size: 1em;
    color: var(--neutral-light);
    background: none;
    transition: all 0.5s ease-out;
  }
`

export const CFinance = ({ session }) => {
  // -------> START - SET DAYJS CONFIG <-------
  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);
  // -------> END - SET DAYJS CONFIG <-------

  // -------> START - SET DATES CONTEXT && CALENDAR DATES GENERATORS <-------
  const [selecteddate, setSelectedDate] = useState(dayjs())
  const currentDay = useMemo(() => dayjs().toDate(), [])
  const firstDayOfTheMonth = useMemo(
    () => selecteddate.clone().startOf("month"),
    [selecteddate]
  )
  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  )
  const firstDayOfLastWeekOfMonth = useMemo(
    () => dayjs(firstDayOfFirstWeekOfMonth).add(5, 'week'),
    [firstDayOfTheMonth, firstDayOfFirstWeekOfMonth]
  )
  const lastDayOfLastWeekOfMonth = useMemo(
    () => dayjs(firstDayOfLastWeekOfMonth).endOf('week'),
    [firstDayOfTheMonth, firstDayOfLastWeekOfMonth]
  )
  const generateFirstDayOfEachWeek = useCallback(day => {
    const dates = [day]
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week")
      dates.push(date)
    }
    return dates
  }, [])
  const generateWeek = useCallback(day => {
    const dates = []
    for (let i = 0; i < 7; i++) {
      const date = day
        .clone()
        .add(i, "day")
        .toDate()
      dates.push(date)
    }
    return dates
  }, [])
  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    )
    return firstDayOfEachWeek.map(date => generateWeek(date))
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek])
  // -------> END - SET DATES CONTEXT && CALENDAR DATES GENERATORS <-------
  
  // -------> START - SET AL HOOKS <-------
  const [supabaseloaded, setSupabaseLoaded] = useState(false);
  const [financedata, setFinanceData] = useState([]);
  const [gettingfinancedata, setGettingFinanceData] = useState(true);
  const [gettingexpenses, setGettingExpenses] = useState(true);
  const [gettingincome, setGettingIncome] = useState(true);
  const [hasdata, setHasData] = useState(false);
  const [expensesdata, setExpensesData] = useState([]);
  const [incomedata, setIncomeData] = useState([]);
  const [entryselected, setEntrySelected] = useState(false);
  const [reservationsdata, setReservationsData] = useState([]);
  const [entrytype, setEntryType] = useState('expense');
  const [entrytypepicker, setEntryTypePicker] = useState(false);
  const [expensearea, setExpenseArea] = useState('Seleccionar');
  const [expenseareapicker, setExpenseAreaPicker] = useState(false);
  const [expensecategory, setExpenseCategory] = useState('select');
  const [expensecategorypicker, setExpenseCategoryPicker] = useState(false);
  const [incometype, setIncomeType] = useState('select');
  const [incometypepicker, setIncomeTypePicker] = useState(false);
  const [paymenttype, setPaymentType] = useState('select');
  const [paymenttypicker, setPaymentTypePicker] = useState(false);
  const [deleteverificationmodal, setDeleteVerificationModal] = useState(false);
  const [inputalert, setInputAlert] = useState(false);
  const [inputname, setInputName] = useState(null);
  const [qttyvalue, setQttyValue] = useState('');
  const [numberofpaymentsvalue, setNumbeOfPaymentsValue] = useState(0);
  const [paymentordinalvalue, setPaymentOrdinalValue] = useState('1');
  const [cunitvalue, setCunitValue] = useState('');
  const [totalcost, setTotalCost] = useState(0);
  const [reservationbind, setReservationBind] = useState('Seleccionar');
  const [reservationbinpicker, setReservationBindPicker] = useState(false);
  const [amountagreedvalue, setAmountAgreedValue] = useState('');
  const [amountpaidvalue, setAmountPaidValue] = useState('');
  const [amountremainingvalue, setAmountRemainingValue] = useState(0);
  const [prstatus, setPrStatus] = useState('active');
  const [enetrigger, setEneTrigger] = useState(false);
  const [irtrigger, setIrTrigger] = useState(false);
  const [iotrigger, setIoTrigger] = useState(false);
  const [newentry, setNewEntry] = useState(false);
  const [currententrydata, setCurrentEntryData] = useState(null);
  const [editentry, setEditEntry] = useState(false);
  const [lastremnant, setLastRemnant] = useState('0');
  // -------> END - SET ALL HOOKS <-------

  // -------> START - SET ALL REFS <-------
  // Type of Entry DD
  // const entrytyperef = useRef();
  // Expense Area & Cat
  // const expensearearef = useRef();
  // const expensecategoryref = useRef();
  // Income Type & Payment Type
  // const incometyperef = useRef();
  // const paymenttyperef = useRef();
  // Expense Form Refs
  const descriptionref = useRef();
  const commentsref = useRef();
  const pcommentsref = useRef();
  const qttyref = useRef()
  const cunitref = useRef()
  const ctotalref = useRef()
  // Income Form Refs
  const agreedamountref = useRef();
  const numberofpaymentsref = useRef();
  const paymentordinalref = useRef();
  const amountpaidref = useRef();
  const amountremainingref = useRef();
  // -------> END - SET ALL REFS <-------

  // -------> START - SET ALL CLICK HANDLERS <-------
  const handleAlertModalClose = () => {
    setInputAlert(false);
    setInputName(null);
  }
  const handleEntryRowClick = (f) => {
    setCurrentEntryData(f);
    setEntrySelected(true);
  }
  const handleViewAllClick = () => {
    //  code here
  }
  const handleNewEntryClick = () => {
    setNewEntry(true);
  }
  const handleNewEntryCancelClick = () => {
    setNewEntry(false);
    setExpenseArea('Seleccionar');
    setExpenseCategory('select');
    descriptionref.current.textContent = '';
    commentsref.current.textContent = '';
    setQttyValue('');
    setCunitValue('');
    setTotalCost(0);
    setIncomeType('select');
    setReservationBind('Seleccionar');
    setPaymentType('select');
    pcommentsref.current.textContent = '';
    setNumbeOfPaymentsValue(0);
    setPaymentOrdinalValue('1');
    setAmountAgreedValue('');
    setAmountPaidValue('');
    setAmountRemainingValue(0);
    setEntryType('expense');
  }
  const handleClearForm = () => {
    if (entrytype === 'expense') {
      setExpenseArea('Seleccionar');
      setExpenseCategory('select');
      descriptionref.current.textContent = '';
      commentsref.current.textContent = '';
      setQttyValue('');
      setCunitValue('');
      setTotalCost(0);
    } else if (entrytype === 'income') {
      setIncomeType('select');
      setReservationBind('Seleccionar');
      setPaymentType('select');
      pcommentsref.current.textContent = '';
      setNumbeOfPaymentsValue(0);
      setPaymentOrdinalValue('1');
      setAmountAgreedValue('');
      setAmountPaidValue('');
      setAmountRemainingValue(0);
      setEntryType('expense');
    }
  }
  const handleChangeEntryType = (e) => {
    if (e.target.id === 'ettg' || e.target.id === 'ettgp') {
      setEntryTypePicker(!entrytypepicker);
      setEntryType('expense');
    } else if (e.target.id === 'etti' || e.target.id === 'ettip') {
      setEntryTypePicker(!entrytypepicker);
      setEntryType('income');
    }
    setExpenseArea('Seleccionar');
    setExpenseCategory('select');
    setIncomeType('select');
    setPaymentType('select');
    setLastRemnant('0');
  }
  const handleExitSelectedEntryClick = () => {
    setEntrySelected(false);
    setCurrentEntryData(null);
    setEditEntry(false);
    setExpenseArea('Seleccionar');
    setExpenseCategory('select');
    setQttyValue('');
    setCunitValue('');
    setTotalCost(0);
    setIncomeType('select');
    setReservationBind('Seleccionar');
    setPaymentType('select');
    setNumbeOfPaymentsValue(0);
    setPaymentOrdinalValue('1');
    setAmountAgreedValue('');
    setAmountPaidValue('');
    setAmountRemainingValue(0);
    setEntryType('expense');
  }

  const handleCancelEntryEditClick = () => {
    setEditEntry(false);
    if (currententrydata.type_of_entry === 'expense') {
      setEntryType('expense');
      setExpenseArea(expensesdata.find((f) => currententrydata.id === f.id)?.area);
      setExpenseCategory(expensesdata.find((f) => currententrydata.id === f.id)?.category);
      setQttyValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.qtty}`)
      setCunitValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.c_unit}`)
      setTotalCost(expensesdata.find((f) => currententrydata.id === f.id)?.c_total)
    } else if (currententrydata.type_of_entry === 'income') {
      setEntryType('income');
      if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'reservation') === true) {
        setIncomeType('reservation');
        setReservationBind(incomedata.find((f) => currententrydata.id === f.id)?.reservation_id)
        if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'multiple') === true) {
          setPaymentType('multiple');
          setNumbeOfPaymentsValue(`${incomedata.find((f) => currententrydata.id === f.id)?.number_of_payments}`);
          setPaymentOrdinalValue(`${incomedata.find((f) => currententrydata.id === f.id)?.payment_ordinal}`);
          setAmountAgreedValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_agreed}`);
          setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
          setPrStatus(incomedata.find((f) => currententrydata.id === f.id)?.status)
        } else if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'single') === true) {
          setPaymentType('single');
          setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
        }
    } else if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'other') === true) {
      setIncomeType('other');
      setPaymentType('single');
      setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
    }
  }
}

const handleFormDelete = async () => {
  setDeleteVerificationModal(false);
  let id = currententrydata.id
  setSupabaseLoaded(false);

  if (currententrydata.type_of_entry === 'expense') {
    try {
      const { error } = await supabase
        .from('expenses')
        .delete()
        .eq('id', id)
  
        if (error) {
          throw error
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      handleExitSelectedEntryClick();
      getFinanceData();
    }
  } else if (currententrydata.type_of_entry === 'income') {
    try {
      const { error } = await supabase
        .from('income')
        .delete()
        .eq('id', id)
  
        if (error) {
          throw error
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      handleExitSelectedEntryClick();
      getFinanceData();
    }
  }
}

const handleFormUpdate = async () => {
  let id = currententrydata.id
  if (entrytype === 'expense') {
    let description = descriptionref.current.textContent
    let comments = commentsref.current.textContent
    if (expensearea === 'Seleccionar') {
      setInputName('Area de Gasto');
      setInputAlert(true);
    } else if (expensecategory === 'select') {
      setInputName('Categoría de Gasto');
      setInputAlert(true);
    } else if (typeof description !== 'string' || description.trim().length <= 3) {
      setInputName('Descripción');
      setInputAlert(true);
    } else if (typeof qttyvalue !== 'string' || qttyvalue.trim().length < 1 || qttyvalue < 1) {
      setInputName('Cantidad');
      setInputAlert(true);
    } else if (typeof cunitvalue !== 'string' || cunitvalue.trim().length < 1 || cunitvalue < 1) {
      setInputName('C/Unidad');
      setInputAlert(true);
    } else if (typeof totalcost !== 'number' || totalcost < 1) {
      setInputName('Ingresa la Cantidad y/o C/Unidad');
      setInputAlert(true);
    } else {
      try {
        setSupabaseLoaded(false);

        const { user } = session

        const { error } = await supabase
          .from('expenses')
          .update({ 
            user_id: user.id,
            area: expensearea,
            category: expensecategory,
            description: description,
            comments: comments,
            qtty: qttyvalue,
            c_unit: cunitvalue,
            c_total: totalcost,
            date_of_expense: selecteddate.clone().format('YYYY-MM-DD'),
            updated_at: new Date()
            })
          .eq('id', id)

        if (error) {
          throw error
        }
      }
      catch(error) {
        alert(error.message)
      }
      finally {
        getFinanceData();
        setEneTrigger(true);
        setEditEntry(false);
      }
    }
  } else if (entrytype === 'income') {
    let comments = pcommentsref.current.textContent

    if (incometype === 'select') {
      setInputName('Tipo de Ingreso');
      setInputAlert(true);
    } else if (incometype === 'reservation') {
      if (reservationbind === 'Seleccionar') {
        setInputName('la reservación correspondiente');
        setInputAlert(true);
      } else if (paymenttype === 'select') {
        setInputName('Tipo de Pago');
        setInputAlert(true);
      } else if (paymenttype === 'multiple') {
        if (typeof numberofpaymentsvalue !== 'string' || numberofpaymentsvalue < 1 || numberofpaymentsvalue <= paymentordinalvalue) {
          setInputName('cantidad de cuotas');
          setInputAlert(true);
        } else if (typeof paymentordinalvalue !== 'string' || paymentordinalvalue < 1) {
          setInputName('# de cuota');
          setInputAlert(true);
        } else if (typeof amountagreedvalue !== 'string' || amountagreedvalue < 1) {
          setInputName('monto acordado');
          setInputAlert(true);
        } else if (typeof amountremainingvalue !== 'number' || amountremainingvalue < 1) {
          setInputName('monto acordado y/o el monto pagado');
          setInputAlert(true);
        } else if (typeof amountpaidvalue !== 'string' || amountpaidvalue < 1) {
          setInputName('monto pagado');
          setInputAlert(true);
        } else {
          try {
            setSupabaseLoaded(false);

            let { user } = session

            const { error } = await supabase
              .from('income')
              .update({
                reservation_id: reservationbind,
                user_id: user.id,
                income_type: incometype,
                comments: comments,
                payment_type: paymenttype,
                number_of_payments: numberofpaymentsvalue,
                amount_agreed: amountagreedvalue,
                payment_ordinal: paymentordinalvalue,
                amount_paid: amountpaidvalue,
                amount_remaining: amountremainingvalue,
                updated_at: new Date(),
                status: prstatus
              })
              .eq('id', id)

            if (error) {
              throw error
            }
          }
          catch(error) {
            alert(error.message)
          }
          finally {
            getFinanceData();
            setIrTrigger(true);
            setEditEntry(false);
          }
        }
      }
      
    } else {
      if (incometype === 'reservation') {
        if (paymenttype === 'single') {
          setNumbeOfPaymentsValue(null);
          setPaymentOrdinalValue(null);
          setAmountAgreedValue(null);
          setAmountPaidValue(null);
          setAmountRemainingValue(null);
          setPrStatus('canceled');
        }
        
        try {
          setSupabaseLoaded(false);

          let { user } = session

          const { error } =  await supabase
            .from('income')
            .update({
              reservation_id: reservationbind,
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              number_of_payments: numberofpaymentsvalue,
              amount_agreed: amountagreedvalue,
              payment_ordinal: paymentordinalvalue,
              amount_paid: amountpaidvalue,
              amount_remaining: amountremainingvalue,
              updated_at: new Date(),
              status: 'canceled'
            })
            .eq('id', id)

          if (error) {
            throw error
          }
        }
        catch(error) {
          alert(error.message)
        }
        finally {
          getFinanceData();
          setIrTrigger(true);
          setEditEntry(false);
        }
      } else if (incometype === 'other') {

        try {
          setSupabaseLoaded(false);

          let { user } = session
          
          const { error } =  await supabase
            .from('income')
            .update({
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              amount_paid: amountpaidvalue,
              updated_at: new Date(),
              status: 'canceled'
            })
            .eq('id', id)

          if (error) {
            throw error
          }
        }
        catch(error) {
          alert(error.message)
        }
        finally {
          getFinanceData();
          setIoTrigger(true);
        }
      }
    }
  }
}

  const handleSaveNewEntry = async () => {
    if (entrytype === 'expense') {
      let description = descriptionref.current.textContent
      let comments = commentsref.current.textContent
      if (expensearea === 'Seleccionar') {
        setInputName('Area de Gasto');
        setInputAlert(true);
      } else if (expensecategory === 'select') {
        setInputName('Categoría de Gasto');
        setInputAlert(true);
      } else if (typeof description !== 'string' || description === 'Ingresar descripción' || description.trim().length <= 3) {
        setInputName('Descripción');
        setInputAlert(true);
      } else if (typeof qttyvalue !== 'string' || qttyvalue.trim().length < 1 || qttyvalue < 1) {
        setInputName('Cantidad');
        setInputAlert(true);
      } else if (typeof cunitvalue !== 'string' || cunitvalue.trim().length < 1 || cunitvalue < 1) {
        setInputName('C/Unidad');
        setInputAlert(true);
      } else if (typeof totalcost !== 'number' || totalcost < 1) {
        setInputName('Ingresa la Cantidad y/o C/Unidad');
        setInputAlert(true);
      } else {
        setSupabaseLoaded(false);
        try {

          const { user } = session

          const updates = {
            user_id: user.id,
            area: expensearea,
            category: expensecategory,
            description: description,
            comments: comments,
            qtty: qttyvalue,
            c_unit: cunitvalue,
            c_total: totalcost,
            date_of_expense: selecteddate.clone().format('YYYY-MM-DD'),
            created_at: new Date(),
          }

          let { error } = await supabase.from('expenses').upsert(updates)

          if (error) {
            throw error
          }
        }
        catch(error) {
          alert(error.message)
        }
        finally {
          getFinanceData();
          setEneTrigger(true);
        }
      }
    } else if (entrytype === 'income') {
      let comments = pcommentsref.current.textContent

      if (incometype === 'select') {
        setInputName('Tipo de Ingreso');
        setInputAlert(true);
      } else if (incometype === 'reservation') {
        if (reservationbind === 'Seleccionar') {
          setInputName('la reservación correspondiente');
          setInputAlert(true);
        } else if (paymenttype === 'select') {
          setInputName('Tipo de Pago');
          setInputAlert(true);
        } else if (paymenttype === 'multiple') {
          if (typeof numberofpaymentsvalue !== 'string' || numberofpaymentsvalue < 1 || numberofpaymentsvalue < paymentordinalvalue) {
            setInputName('cantidad de cuotas');
            setInputAlert(true);
          } else if (typeof paymentordinalvalue !== 'string' || paymentordinalvalue < 1) {
            setInputName('# de cuota');
            setInputAlert(true);
          } else if (typeof amountagreedvalue !== 'string' || amountagreedvalue < 1) {
            setInputName('monto acordado');
            setInputAlert(true);
          } else if (typeof amountremainingvalue !== 'number' || amountremainingvalue < 0) {
            setInputName('monto acordado y/o el monto pagado');
            setInputAlert(true);
          } else if (typeof amountpaidvalue !== 'string' || amountpaidvalue < 1) {
            setInputName('monto pagado');
            setInputAlert(true);
          } else {
            setSupabaseLoaded(false);
            try {
  
              let { user } = session
  
              const updates = {
                reservation_id: reservationbind,
                user_id: user.id,
                income_type: incometype,
                comments: comments,
                payment_type: paymenttype,
                number_of_payments: numberofpaymentsvalue,
                amount_agreed: amountagreedvalue,
                payment_ordinal: paymentordinalvalue,
                amount_paid: amountpaidvalue,
                amount_remaining: amountremainingvalue,
                date_of_payment: selecteddate.clone().format('YYYY-MM-DD'),
                created_at: new Date(),
                status: prstatus,
              }
  
              let { error } = await supabase.from('income').upsert(updates)
  
              if (error) {
                throw error
              }
            }
            catch(error) {
              alert(error.message)
            }
            finally {
              getFinanceData();
              setIrTrigger(true);
            }
          }
        } else if (paymenttype === 'single') {
          setSupabaseLoaded(false);
          // setNumbeOfPaymentsValue(null);
          // setPaymentOrdinalValue(null);
          // setAmountAgreedValue(null);
          // setAmountPaidValue(null);
          // setAmountRemainingValue(null);
          setPrStatus('canceled');
          try {

            let { user } = session

            const updates = {
              reservation_id: reservationbind,
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              amount_paid: amountpaidvalue,
              date_of_payment: selecteddate.clone().format('YYYY-MM-DD'),
              created_at: new Date(),
              status: 'canceled',
            }

            let { error } = await supabase.from('income').upsert(updates)

            if (error) {
              throw error
            }
          }
          catch(error) {
            alert(error.message)
          }
          finally {
            getFinanceData();
            setIrTrigger(true);
          }
        }
        
      } else if (incometype === 'other') {
          setSupabaseLoaded(false);
        
          try {

            let { user } = session
            
            const updates = {
              user_id: user.id,
              income_type: incometype,
              comments: comments,
              payment_type: paymenttype,
              amount_paid: amountpaidvalue,
              date_of_payment: selecteddate.clone().format('YYYY-MM-DD'),
              created_at: new Date(),
              status: 'canceled',
            }

            let { error } =  await supabase.from('income').upsert(updates)

            if (error) {
              throw error
            }
          }
          catch(error) {
            alert(error.message)
          }
          finally {
            getFinanceData();
            setIoTrigger(true);
          }
        }
      }
    }
  
  // -------> END - SET ALL CLICK HANDLERS <-------  

  // -------> START - SET ALL USEFFECT CONTROLLERS <-------
  useEffect(() => {
    getFinanceData();
  }, [dayjs(firstDayOfTheMonth).format('YYYY-MM-DD')])

  const getFinanceData = async () => {
    setGettingFinanceData(true);
    try {
      let { data, error } = await supabase
        .from('all_finance_entries')
        .select()
        .gte('date_of_entry', dayjs(firstDayOfFirstWeekOfMonth).format('YYYY-MM-DD'))
        .lte('date_of_entry', dayjs(lastDayOfLastWeekOfMonth).format('YYYY-MM-DD'))

        if (error) {
          throw error
        }
        if (data) {
          if (data.length === 0) {
            console.log('No Finance Data Error - Empty Array')
          } else {
            setFinanceData(data);
          }
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingFinanceData(false);
    }
  }

  useEffect(() => {
    if (gettingfinancedata === false) {
      getExpensesData();
    }
  }, [gettingfinancedata])

  const getExpensesData = async () => {
    setGettingExpenses(true);
    try {
      let { data, error } = await supabase
        .from('expenses')
        .select()
        .gte('date_of_expense', dayjs(firstDayOfFirstWeekOfMonth).format('YYYY-MM-DD'))
        .lte('date_of_expense', dayjs(lastDayOfLastWeekOfMonth).format('YYYY-MM-DD'))
        .order('date_of_expense', {ascending: false})

        if (error) {
          throw error
        }
        if (data) {
          if (data.length === 0) {
            setExpensesData(data);
          } else {
            setExpensesData(data);
          }
        }
    }
    catch(error){
      alert(error.message)
    }
    finally {
      setGettingExpenses(false);
    }
  }

  useEffect(() => {
    if (gettingexpenses === false) {
      getIncomeData();
    }
  }, [gettingexpenses])

  const getIncomeData = async () => {
    setGettingIncome(true);
    try {
      let { data, error } = await supabase
        .from('income')
        .select(`
          *,
          reservations(
            *
          )
        `)
        .order('date_of_payment', {ascending: false})

        if (error) {
          throw error
        }
        if (data) {
          if (data.length === 0) {
            setIncomeData(data);
          } else {
            setIncomeData(data);
          }
        }
    }
    catch(error){
      alert(error.message)
    }
    finally {
      setGettingIncome(false);
    }
  }

  useEffect(() => {
    if (gettingincome === false) {
      getReservationsData();
    }
  }, [gettingincome])

  const getReservationsData = async () => {
    try {
      let { data, error } = await supabase
        .from('reservations')
        .select()

        if (error) {
          throw error
        }
        if (data) {
          if(data.length === 0) {
            setReservationsData(data);
          } else {
            setReservationsData(data)
          }
        }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setSupabaseLoaded(true);
    }
  }

  useEffect(() => {
    if (supabaseloaded === true) {
      const testvalue = financedata.some((f) => ((dayjs(f.date_of_entry).isSame(dayjs(selecteddate).format('YYYY-MM-DD')))))
      // console.log(testvalue)
      if (testvalue === true) {
        setHasData(true);
        // console.log(id)
      } else {
        setHasData(false)
      }
    }
  }, [selecteddate, supabaseloaded])
  useEffect(() => {
    if (supabaseloaded === true && entrytype === 'expense') {
      if (qttyvalue && cunitvalue !== null) {
        setTotalCost(qttyvalue * cunitvalue)
      }
    } 
  }, [supabaseloaded, entrytype, qttyvalue, cunitvalue])

  useEffect(() => {
    if (supabaseloaded === true && incometype === 'reservation' && entryselected === false) {
      if (reservationbind !== 'Seleccionar') {
        if (incomedata.some((s) => reservationbind === s.reservation_id) === true) {
          if (incomedata.some((s) => reservationbind === s.reservation_id && s.payment_type === 'multiple')) {
            setLastRemnant(`${incomedata.find((f) => reservationbind === f.reservation_id)?.amount_remaining}`);
          }
        }
      }
    }
  }, [supabaseloaded, incometype, reservationbind])

  useEffect(() => {
    if (supabaseloaded === true && entrytype === 'income' && paymenttype === 'multiple') {
      if (entryselected === true) {
        setAmountRemainingValue(`${incomedata.find((f) => reservationbind === f.reservation_id)?.amount_remaining}`)
      }
      else if (amountagreedvalue !== null && amountpaidvalue !== null && lastremnant !== '0') {
        setAmountRemainingValue(amountagreedvalue - amountpaidvalue - (amountagreedvalue - lastremnant))
      } else if (amountagreedvalue !== null && amountpaidvalue !== null) {
        setAmountRemainingValue(amountagreedvalue - amountpaidvalue - lastremnant)
      }
    }
  }, [supabaseloaded, entrytype, paymenttype, entryselected, amountagreedvalue, amountpaidvalue])
  
  useEffect(() => {
    const closeFormTypePicker = (e) => {
      if (e.target.id !== 'et' && e.target.id !== 'etp' && e.target.id !== 'eti') {
        if (e.target.parentElement.id !== 'eti') {
          setEntryTypePicker(false);
        }
      }
      if (e.target.id !== 'ea' && e.target.id !== 'eap' && e.target.id !== 'eai') {
        if (e.target.parentElement.id !== 'eai') {
          setExpenseAreaPicker(false);
        }
      }
      if (e.target.id !== 'ec' && e.target.id !== 'ecp' && e.target.id !== 'eci') {
        if (e.target.parentElement.id !== 'eci') {
          setExpenseCategoryPicker(false);
        }
      }
      if (e.target.id !== 'ei' && e.target.id !== 'eip' && e.target.id !== 'eii') {
        if (e.target.parentElement.id !== 'eii') {
          setIncomeTypePicker(false);
        }
      }
      if (e.target.id !== 'ep' && e.target.id !== 'epp' && e.target.id !== 'epi') {
        if (e.target.parentElement.id !== 'epi') {
          setPaymentTypePicker(false);
        }
      }
    }

    document.body.addEventListener('click', closeFormTypePicker);
    return () => document.body.removeEventListener('click', closeFormTypePicker)
  }, [])

  useEffect(() => {
    if (supabaseloaded === true && enetrigger === true) {
      setExpenseArea('Seleccionar');
      setExpenseCategory('select');
      setQttyValue('');
      setCunitValue('');
      setTotalCost(0);
      setEneTrigger(false);
    } else if (supabaseloaded === true && irtrigger === true) {
      setIncomeType('select');
      setReservationBind('Seleccionar');
      setPaymentType('select');
      setNumbeOfPaymentsValue(0);
      setPaymentOrdinalValue('1');
      setAmountAgreedValue('');
      setAmountPaidValue('');
      setAmountRemainingValue(0);
      setEntryType('expense');
      setIrTrigger(false);
    } else if (supabaseloaded === true && iotrigger === true) {
      setIncomeType('select');
      setReservationBind('Seleccionar');
      setPaymentType('select');
      setEntryType('expense');
      setIoTrigger(false);
    }
  }, [supabaseloaded, enetrigger, irtrigger, iotrigger])

  useEffect(() => {
    if (supabaseloaded === true && entryselected === true) {
      if (currententrydata.type_of_entry === 'expense') {
        setEntryType('expense');
        setExpenseArea(expensesdata.find((f) => currententrydata.id === f.id)?.area);
        setExpenseCategory(expensesdata.find((f) => currententrydata.id === f.id)?.category);
        setQttyValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.qtty}`)
        setCunitValue(`${expensesdata.find((f) => currententrydata.id === f.id)?.c_unit}`)
        setTotalCost(expensesdata.find((f) => currententrydata.id === f.id)?.c_total)
      } else if (currententrydata.type_of_entry === 'income') {
        setEntryType('income');
        if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'reservation') === true) {
          setIncomeType('reservation');
          setReservationBind(incomedata.find((f) => currententrydata.id === f.id)?.reservation_id)
          if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'multiple') === true) {
            setPaymentType('multiple');
            setNumbeOfPaymentsValue(`${incomedata.find((f) => currententrydata.id === f.id)?.number_of_payments}`);
            setPaymentOrdinalValue(`${incomedata.find((f) => currententrydata.id === f.id)?.payment_ordinal}`);
            setAmountAgreedValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_agreed}`);
            setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
            setPrStatus(incomedata.find((f) => currententrydata.id === f.id)?.status)
          } else if (incomedata.some((s) => currententrydata.id === s.id && s.payment_type === 'single') === true) {
            setPaymentType('single');
            setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
          }
        } else if (incomedata.some((s) => currententrydata.id === s.id && s.income_type === 'other') === true) {
          setIncomeType('other');
          setPaymentType('single');
          setAmountPaidValue(`${incomedata.find((f) => currententrydata.id === f.id)?.amount_paid}`);
        }
      }
    } else if (supabaseloaded === true  && reservationbind !== 'Seleccionar') {
      if (reservationsdata.some((s) => s.id === reservationbind && s.payment_type === 'Cuotas') === true) {
        setPaymentType('multiple');
        setNumbeOfPaymentsValue(reservationsdata.find((f) => f.id === reservationbind)?.number_of_payments);
        setAmountAgreedValue(`${reservationsdata.find((f) => f.id === reservationbind)?.amount_agreed}`);
        if (incomedata.some((s) => s.reservation_id === reservationbind)) {
          setPaymentOrdinalValue(`${(incomedata.find((f) => f.reservation_id === reservationbind)?.payment_ordinal) + 1}`)
        } else {
          setPaymentOrdinalValue(`1`);
        }
      } else if (reservationsdata.some((s) => s.id === reservationbind && s.payment_type === 'Único') === true) {
        setPaymentType('single');
        setAmountPaidValue(`${reservationsdata.find((f) => f.id === reservationbind)?.amount_agreed}`);
      }
    } else {
    setNewEntry(false);
    setEntrySelected(false);
    setEditEntry(false);
    setExpenseArea('Seleccionar');
    setExpenseCategory('select');
    setQttyValue('');
    setCunitValue('');
    setTotalCost(0);
    setIncomeType('select');
    setReservationBind('Seleccionar');
    setPaymentType('select');
    setNumbeOfPaymentsValue(0);
    setPaymentOrdinalValue('1');
    setAmountAgreedValue('');
    setAmountPaidValue('');
    setAmountRemainingValue(0);
    setEntryType('expense');
    setLastRemnant('0');
  }
  }, [supabaseloaded, reservationbind, selecteddate, entryselected])
  // -------> END - SET ALL USEFFECT CONTROLLERS <-------
  const windowsize = useWindowSize();

  return (
    <>
    { supabaseloaded ?
    (
      <>
      <div className="cdashboard__deleteconfirmation-modal" 
        style={{
          display: deleteverificationmodal === true
          ? 'flex'
          : 'none'
        }}
      >
        <div className="cdashboard__dcmodal-title">
          <p>
            ¿Estas Seguro?
          </p>
        </div>
        <div className="cdashboard__dcmodal-message">
          <p>
            ¿Estas seguro que deseas borrar esta entrada? Esta acción no puede deshacerse.
          </p>
        </div>
        <div className="cdashboard__dcmodal-ctas">
          <div onClick={handleFormDelete}>
            <p>
              Borrar
            </p>
          </div>
          <div onClick={() => setDeleteVerificationModal(false)}>
            <p>
              Cancelar
            </p>
          </div>
        </div>
      </div>
      <div className="cdashboard__deleteconfirmation-modal" 
        style={{
          display: inputalert === true
          ? 'flex'
          : 'none'
        }}
      >
        <div className="cdashboard__dcmodal-title">
          <p>
            ¡Parece que algo hace falta!
          </p>
        </div>
        <div className="cdashboard__dcmodal-message">
          <p>
            Por favor ingresa el <span style={{ textDecoration: 'underline', background: 'none' }}>{inputname}</span>
          </p>
        </div>
        <div className="cdashboard__dcmodal-ctas">
          <div onClick={handleFormDelete} style={{ display: 'none' }}>
            <p>
              Borrar
            </p>
          </div>
          <div onClick={handleAlertModalClose}>
            <p>
              Ok!
            </p>
          </div>
        </div>
      </div>
      <Styles.MainWrapper
          as={motion.div}
          initial={{
            opacity: 0
          }}
          whileInView={{
            opacity: 1
          }}
          transition={{
            duration: 0.4
          }}
          viewport={{
            once: true
          }}
      >
        <Styles.CalendarHeaderWrapper>
          <h3>{selecteddate.clone().format("MMMM, YYYY")}</h3>
          <div>
            <motion.div
              whileHover={{
                scale: 1.1
              }}
              whileTap={{
                scale: 0.9
              }}
            >
              <CircledLeft
                onClick={() => setSelectedDate((date) => date.subtract(1, 'month'))} />
            </motion.div>
            <motion.div
              whileHover={{
                scale: 1.1
              }}
              whileTap={{
                scale: 0.9
              }}
            >
              <CircledRight
                onClick={() => setSelectedDate((date) => date.add(1, 'month'))} />
            </motion.div>
          </div>
        </Styles.CalendarHeaderWrapper>
        <Styles.WeekDaysWrapper>
          {generateWeeksOfTheMonth[0].map((day, index) => (
            <Styles.WeekDayCell key={`week-day-${index}`}>
              {dayjs(day).format(windowsize.width < 1600 ? 'ddd' : 'dddd')}
            </Styles.WeekDayCell>
          ))}
        </Styles.WeekDaysWrapper>
        {generateWeeksOfTheMonth.map((week, weekIndex) => (
          <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
            {week.map((day, dayIndex) => (
              <Styles.CalendarDayCell
                as={motion.div}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
                key={`day-${dayIndex}`}
                onClick={() => {setSelectedDate(dayjs(day)); setEntrySelected(false); setReservationBind('Seleccionar')}}
                style={{
                  border: dayjs(selecteddate).isSame(dayjs(day), 'date') ? '2px solid #374758' : 'none',
                  backgroundImage: incomedata.some((s) => dayjs(s.date_of_payment).isSame(dayjs(day)) && expensesdata.some((c) => dayjs(c.date_of_expense).isSame(dayjs(day)))) 
                  ? "linear-gradient(137.82deg, #69CE8B 5.54%, #EB927C 82.83%)"
                  : 'unset',
                  backgroundColor: 
                  incomedata.some((s) => dayjs(s.date_of_payment).isSame(dayjs(day)))
                  ? '#69CE8B'
                  : expensesdata.some((s) => dayjs(s.date_of_expense).isSame(dayjs(day)))
                  ? '#E9BFB5'
                  : 'unset',
                  color: dayjs(currentDay).isAfter(day, 'date') ? 'rgba(48, 58, 70, 0.4)' : '#374758',
                }}
              >
                {day.getDate()}
                <div>
                  <Income 
                    style={{
                      display: incomedata.some((s) => dayjs(s.date_of_payment).isSame(dayjs(day))) ? 'flex' : 'none', 
                      color: dayjs(currentDay).isAfter(day, 'date') ? 'rgba(48, 58, 70, 0.4)' : '#374758'
                    }}
                  />
                  <Expense 
                    style={{
                      display: expensesdata.some((s) => dayjs(s.date_of_expense).isSame(dayjs(day))) ? 'flex' : 'none',
                      color: dayjs(currentDay).isAfter(day, 'date') ? 'rgba(48, 58, 70, 0.4)' : '#374758'
                    }}
                />
                </div>
              </Styles.CalendarDayCell>
            ))}
          </Styles.CalendarContentWrapper>
        ))}
      </Styles.MainWrapper>
      <Styles.DetailsWrapper
          as={motion.div}
          initial={{
            opacity: 0
          }}
          whileInView={{
            opacity: 1
          }}
          transition={{
            duration: 0.6
          }}
          viewport={{
            once: true
          }}
      >
        <Styles.DetailsHeader>
          <h3>
            {selecteddate.clone().format('dddd DD')}
          </h3>
          <div>
            <motion.div
              whileHover={{
                scale: 1.1
              }}
              whileTap={{
                scale: 0.9
              }}
            >
              <CircledLeft
                onClick={() => setSelectedDate((date) => date.subtract(1, 'day'))} />
            </motion.div>
            <motion.div
              whileHover={{
                scale: 1.1
              }}
              whileTap={{
                scale: 0.9
              }}
            >
              <CircledRight
                onClick={() => setSelectedDate((date) => date.add(1, 'day'))} />
            </motion.div>
          </div>
        </Styles.DetailsHeader>
        {
          hasdata === true && newentry === false && entryselected === false
          ?
          (
            <>
              <Styles.DetailsBkdown>
                <Styles.BkdownHeader>
                  <h4>
                    registros
                  </h4>
                  <ReservationForm />
                </Styles.BkdownHeader>
                <ScrollContainer className="entrieslist">
                  {
                    financedata.filter(
                      (f) => dayjs(f.date_of_entry).isSame(dayjs(selecteddate))
                    ).map(
                      (f) => (
                        <Styles.EntryRow key={f.id} onClick={() => handleEntryRowClick(f)}
                          as={motion.div}
                          whileTap={{
                            scale: 0.9
                          }}
                        >
                          <Styles.EntryRowTypeWrapper>
                            <Income
                              style={{
                                display: f.type_of_entry === 'income' ? 'flex' : 'none',
                                color: '#29BF38'
                              }}    
                            />
                            <Expense 
                              style={{
                                display: f.type_of_entry === 'expense' ? 'flex' : 'none',
                                color: '#D34423'
                              }}    
                            />
                            <div>
                              <h5>
                                {
                                  f.type_of_entry === 'income'
                                  ? incomedata.find((i) => (f.id === i.id))?.income_type === 'reservation'
                                  ? 'Reservación'
                                  : 'Otro'
                                  : f.type_of_entry === 'expense'
                                  ? expensesdata.find((i) => (f.id === i.id))?.description
                                  : 'Error'
                                }
                              </h5>
                              <p>
                                {
                                  f.type_of_entry === 'income'
                                  ? incomedata.some((s) => f.id === s.id && s.income_type === 'reservation')
                                  ? incomedata.find((i) => f.id === i.id)?.reservations.name
                                  : incomedata.find((i) => f.id === i.id)?.comments
                                  : f.type_of_entry === 'expense'
                                  ? expensesdata.find((i) => (f.id === i.id))?.area
                                  : 'Error'
                                }
                              </p>
                            </div>
                          </Styles.EntryRowTypeWrapper>
                          <Styles.EntryRowValuesWrapper>
                            <Styles.EntryRowValueTotal>
                              <h5>
                                {
                                  f.type_of_entry === 'income'
                                  ? `+$${incomedata.find((i) => f.id === i.id)?.amount_paid}`
                                  : `-$${expensesdata.find((i) => f.id === i.id)?.c_total}`
                                }
                              </h5>
                            </Styles.EntryRowValueTotal>
                            <Styles.EntryRowValueDetailsWrapper>
                              {
                                f.type_of_entry === 'income'
                                ? (
                                  <>
                                  <Styles.EntryRowValueDetailPill>
                                    <p>
                                      {
                                        incomedata.find((i) => f.id === i.id)?.payment_type === 'single'
                                        ? 'Único'
                                        : 'Cuotas'
                                      }
                                    </p>
                                  </Styles.EntryRowValueDetailPill>
                                  <Styles.EntryRowValueDetailPill>
                                    <p>
                                      {
                                        incomedata.some((s) => f.id === s.id && s.payment_type === 'multiple')
                                        ? incomedata.some((s) => f.id === s.id && s.status === 'active')
                                        ? `${incomedata.find((i) => f.id === i.id)?.payment_ordinal}/${incomedata.find((i) => f.id === i.id)?.number_of_payments}`
                                        : 'Cancelado'
                                        : 'Cancelado'
                                      }
                                    </p>
                                  </Styles.EntryRowValueDetailPill>
                                  </>
                                )
                                :
                                (
                                  <>
                                  <Styles.EntryRowValueDetailPill>
                                    <p>
                                      {
                                        expensesdata.find((i) => f.id === i.id)?.qtty
                                      }
                                    </p>
                                  </Styles.EntryRowValueDetailPill>
                                  <Styles.EntryRowValueDetailUnit>
                                    <p>
                                      {
                                        `-$${expensesdata.find((i) => f.id === i.id)?.c_unit}`
                                      }
                                    </p>
                                  </Styles.EntryRowValueDetailUnit>
                                  </>
                                )
                              }
                            </Styles.EntryRowValueDetailsWrapper>
                          </Styles.EntryRowValuesWrapper>
                        </Styles.EntryRow>
                      )
                    )
                  }
                </ScrollContainer>
                <ViewAllCTA 
                  to='/filtro'
                >
                  <p>
                    Ver Todos
                  </p>
                </ViewAllCTA>
              </Styles.DetailsBkdown>
              <Styles.AddNewEntryCTA onClick={handleNewEntryClick}
                as={motion.div}
                whileTap={{
                  scale: 0.9
                }}
              >
                <p>
                  Nueva Entrada
                </p>
                <Plus />
              </Styles.AddNewEntryCTA>
            </>
          )
          : hasdata === true && entryselected === true
          ?
          (
            <>
              <Styles.FormWrapper>
                <div className="financeform__header">
                  <div id="et" className="financeformheader__picker-selected" onClick={() => setEntryTypePicker(!entrytypepicker)} style={{ pointerEvents: 'none' }} 
                  >
                    <p id='etp'>
                      {
                        entrytype === 'expense'
                        ? 'Gasto'
                        : entrytype === 'income'
                        ? 'Ingreso'
                        : 'Error'
                      }
                    </p>
                    <ChevronDown id='eti' />
                    <div 
                      className="typeofregistry__picker"
                      style={{
                        display: entrytypepicker === true ? 'flex' : 'none'
                      }}
                    >
                      <div id='ettg' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettgp'>
                          Gasto
                        </p>
                      </div>
                      <div id='etti' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettip'>
                          Ingreso
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='financeform__header-buttonswrapper' >
                    <motion.div whileTap={{ scale: 0.9 }} onClick={handleExitSelectedEntryClick} className='financeform__header-controls'>
                      <CircledLeft />
                    </motion.div>
                    <motion.div whileTap={{ scale: 0.9 }} onClick={() => setEditEntry(true)} className='financeform__header-controls' style={{ display: editentry === true ? 'none' : 'flex' }} >
                      <Edit />  
                    </motion.div> 
                    <motion.div whileTap={{ scale: 0.9 }} onClick={handleCancelEntryEditClick} className='financeform__header-controls' style={{ display: editentry === true ? 'flex' : 'none' }} >
                      <Cancel />  
                    </motion.div> 
                    <motion.div whileTap={{ scale: 0.9 }} onClick={handleFormUpdate}  style={{ display: editentry === true ? 'flex' : 'none' }} className='financeform__header-controls' >
                      <Save />  
                    </motion.div> 
                    <motion.div whileTap={{ scale: 0.9 }} onClick={() => setDeleteVerificationModal(true)}  className='financeform__header-controls' >
                      <Remove />  
                    </motion.div> 
                    <Income 
                        style={{
                          display: currententrydata.type_of_entry === 'income'
                          ? 'flex'
                          : 'none'
                        }}
                      />
                    <Expense 
                      style={{
                        display: currententrydata.type_of_entry === 'expense'
                        ? 'flex'
                        : 'none'
                      }}
                    />
                  </div>
                </div>
                <div className="fiform__fieldswrapper" style={{ pointerEvents: editentry === true ? 'all' : 'none' }} >
                  {
                    entrytype === 'expense'
                    ?
                    (
                      <>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                            <p>
                              area de gasto:
                            </p>
                          </div>
                          <div id='ea' className="typeof__picker" onClick={() => setExpenseAreaPicker(!expenseareapicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none' }} >
                            <p id='eap' style={{ textTransform: 'capitalize' }} >
                              {
                                expensearea
                              }
                            </p>
                            <ChevronDown id='eai' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expenseareapicker === true
                                ? 'flex'
                                : 'none'
                              }}
                            >
                              <div onClick={() => {setExpenseArea('capital'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Capital
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('operaciones'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Operaciones
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('marketing'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Marketing
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                            <p>
                              categoría:
                            </p>
                          </div>
                          <div id='ec' className="typeof__picker" onClick={() => setExpenseCategoryPicker(!expensecategorypicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none' }} >
                            <p id='ecp' style={{ textTransform: 'capitalize' }} >
                              {
                                expensecategory === 'select'
                                ? 'Seleccionar'
                                : expensecategory
                              }
                            </p>
                            <ChevronDown id='eci' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expensecategorypicker === true
                                ? 'flex'
                                : 'none'
                              }}
                            >
                              {
                                expensearea === 'capital'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('titulos y propiedades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Titulo y Propiedades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('construccion'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Construcción
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'operaciones'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('impuestos'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Impuestos
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('salarios'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Salarios
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('utilidades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Utilidades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('mantenimiento'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Mantenimiento
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('suministros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Suministros
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('juridico'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Jurídico
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'marketing'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('digital'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Digital
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('afiliados'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Afiliados
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('otros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Otros
                                      </p>
                                    </div>
                                  </>
                                )
                                :
                                (
                                  <div>
                                      <p>
                                        Selecciona una Area
                                      </p>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                descripción:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="Ingresar descripción"
                              ref={descriptionref}
                              style={{
                                whiteSpace: editentry === true
                                ? 'unset'
                                : 'nowrap'
                              }}
                            >
                              {
                                expensesdata.find((f) => currententrydata.id === f.id)?.description
                              }
                            </p>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="Ingresar comentarios"
                              ref={commentsref}
                              style={{
                                whiteSpace: editentry === true
                                ? 'unset'
                                : 'nowrap'
                              }}
                            >
                              {
                                expensesdata.find((f) => currententrydata.id === f.id)?.comments
                              }
                            </p>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                cantidad:
                              </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' ref={qttyref} className='fiformfieldrow__input' value={qttyvalue} onChange={(e) => setQttyValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/unidad:
                              </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={cunitref} className='fiformfieldrow__input' value={cunitvalue} onChange={(e) => setCunitValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/total:
                              </p>
                          </div>
                          <CurrencyInput size='8' value={totalcost} type='text' ref={ctotalref} className='fiformfieldrow__input' style={{ pointerEvents: 'none' }} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                fecha de gasto:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                selecteddate.clone().format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    : entrytype === 'income'
                    ?
                    (
                      <>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title">
                            <p>
                              tipo de ingreso:
                            </p>
                          </div>
                          <div id='ei' className="typeof__picker" onClick={() => setIncomeTypePicker(!incometypepicker)} style={{ pointerEvents: editentry === true ? 'all' : 'none' }} >
                            <p id='eip'>
                              {
                                incometype === 'reservation'
                                ? 'Reservaciones'
                                : incometype === 'other'
                                ? 'Otros'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='eii' />
                            <div className="typeof__ddown" style={{ display: incometypepicker === true ? 'flex' : 'none' }}
                            >
                              <div onClick={() => {setIncomeType('reservation'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Reservación
                                </p>
                              </div>
                              <div onClick={() => {setIncomeType('other'); setPaymentType('single'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Otro
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow" style={{ display: incometype === 'reservation' ? 'flex' : 'none' }} >
                          <div className='fiformfieldrow__title'>
                            <p>
                              reservación:
                            </p>
                          </div>
                          <div className="typeof__picker" style={{ pointerEvents: 'none' }} >
                            <p
                              style={{
                                textTransform: 'unset'
                              }}
                            >
                              {
                                reservationbind
                              }
                            </p>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="de la venta de leña"
                              ref={pcommentsref}
                            >
                              {
                                incomedata.find((f) => currententrydata.id === f.id)?.comments
                              }
                            </p>
                          </div>
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              tipo de pago:
                            </p>
                          </div>
                          <div id='ep' className='typeof__picker' onClick={() => setPaymentTypePicker(!paymenttypicker)} style={{ pointerEvents: 'none' }} >
                            <p id='epp'>
                              {
                                paymenttype === 'multiple'
                                ? 'Cuotas'
                                : paymenttype === 'single'
                                ? 'Único'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='epi' />
                            <div className='typeof__ddown' style={{ display: paymenttypicker === true ? 'flex' : 'none' }} 
                            >
                              <div onClick={() => {setPaymentType('multiple'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Cuotas
                                </p>
                              </div>
                              <div onClick={() => {setPaymentType('single'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Único
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'Cuotas' ? 'flex' : 'none', pointerEvents: 'none'  }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              cantidad de cuotas:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' ref={numberofpaymentsref} className='fiformfieldrow__input' value={numberofpaymentsvalue} onChange={(e) => setNumbeOfPaymentsValue(e.currentTarget.value)} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'Cuotas' ? 'flex' : 'none', pointerEvents: 'none'  }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              # de cuota:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='1' type='text' ref={paymentordinalref} className='fiformfieldrow__input' value={paymentordinalvalue} onChange={(e) => setPaymentOrdinalValue(e.currentTarget.value)} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'Cuotas' ? 'flex' : 'none', pointerEvents: 'none'  }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto acordado:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={agreedamountref} className='fiformfieldrow__input' value={amountagreedvalue} onChange={(e) => setAmountAgreedValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto recibido:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={amountpaidref} className='fiformfieldrow__input' value={amountpaidvalue} onChange={(e) => setAmountPaidValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto restante:
                            </p>
                          </div>
                          <CurrencyInput size='8' value={amountremainingvalue} type='text' ref={amountremainingref} className='fiformfieldrow__input'/>
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              fecha de entrada
                            </p>
                          </div>
                          <div className='rowwrapper__value-wrapper'>
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                selecteddate.clone().format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    :
                    (
                      <div>
                        Error
                      </div>
                    )
                  }
                </div>
              </Styles.FormWrapper>
            </>
          )
          :
          (
            <>
              <Styles.FormWrapper>
                <div className="financeform__header">
                  <div id="et" className="financeformheader__picker-selected" onClick={() => setEntryTypePicker(!entrytypepicker)} >
                    <p id='etp'>
                      {
                        entrytype === 'expense'
                        ? 'Gasto'
                        : 'Ingreso'
                      }
                    </p>
                    <ChevronDown id='eti' />
                    <div 
                      className="typeofregistry__picker"
                      style={{
                        display: entrytypepicker === true ? 'flex' : 'none'
                      }}
                    >
                      <div id='ettg' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettgp'>
                          Gasto
                        </p>
                      </div>
                      <div id='etti' onClick={(e) => handleChangeEntryType(e)} >
                        <p id='ettip'>
                          Ingreso
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='financeform__header-buttonswrapper'>
                    <motion.div whiletap={{ scale: 0.9 }} onClick={handleNewEntryCancelClick} style={{ display: newentry === true ? 'flex' : 'none' }} className='financeform__header-controls' >
                      <Cancel />
                    </motion.div>
                    <motion.div whiletap={{ scale: 0.9 }} onClick={handleClearForm} className='financeform__header-controls' >
                      <Clear />  
                    </motion.div> 
                    <Income 
                        style={{
                          display: entrytype === 'income'
                          ? 'flex'
                          : 'none'
                        }}
                      />
                    <Expense 
                      style={{
                        display: entrytype === 'expense'
                        ? 'flex'
                        : 'none'
                      }}
                    />
                  </div>
                </div>
                <div className="fiform__fieldswrapper">
                  {
                    entrytype === 'expense'
                    ?
                    (
                      <>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                            <p>
                              area de gasto:
                            </p>
                          </div>
                          <div id='ea' className="typeof__picker" onClick={() => setExpenseAreaPicker(!expenseareapicker)}>
                            <p id='eap' style={{ textTransform: 'capitalize' }} >
                              {
                                expensearea
                              }
                            </p>
                            <ChevronDown id='eai' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expenseareapicker === true
                                ? 'flex'
                                : 'none'
                              }}
                              initial={{
                                opacity: 0.5,
                                scale: 0.5
                              }}
                              animate={{
                                opacity: 1,
                                scale: 1
                              }}
                              exit={{
                                opacity: 0,
                                scale: 0.8
                              }}
                              transition={{
                                type: 'spring',
                                duration: 0.3
                              }}
                            >
                              <div onClick={() => {setExpenseArea('capital'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Capital
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('operaciones'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Operaciones
                                </p>
                              </div>
                              <div onClick={() => {setExpenseArea('marketing'); setExpenseAreaPicker(!expenseareapicker)}} >
                                <p>
                                  Marketing
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                            <p>
                              categoría:
                            </p>
                          </div>
                          <div whileTap={{ scale: 0.9 }} id='ec' className="typeof__picker" onClick={() => setExpenseCategoryPicker(!expensecategorypicker)}>
                            <p id='ecp' style={{ textTransform: 'capitalize' }} >
                              {
                                expensecategory === 'select'
                                ? 'Seleccionar'
                                : expensecategory
                              }
                            </p>
                            <ChevronDown id='eci' />
                            <div 
                              className="typeof__ddown"
                              style={{
                                display: expensecategorypicker === true
                                ? 'flex'
                                : 'none'
                              }}
                            >
                              {
                                expensearea === 'capital'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('titulos y propiedades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Titulo y Propiedades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('construccion'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Construcción
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'operaciones'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('impuestos'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Impuestos
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('salarios'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Salarios
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('utilidades'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Utilidades
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('mantenimiento'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Mantenimiento
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('suministros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Suministros
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('juridico'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Jurídico
                                      </p>
                                    </div>
                                  </>
                                )
                                : expensearea === 'marketing'
                                ?
                                (
                                  <>
                                    <div onClick={() => {setExpenseCategory('digital'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Digital
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('afiliados'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Afiliados
                                      </p>
                                    </div>
                                    <div onClick={() => {setExpenseCategory('otros'); setExpenseCategoryPicker(!expensecategorypicker)}} >
                                      <p>
                                        Otros
                                      </p>
                                    </div>
                                  </>
                                )
                                :
                                (
                                  <div>
                                      <p>
                                        Selecciona una Area
                                      </p>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                descripción:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="Ingresar descripción"
                              ref={descriptionref}
                              style={{
                                whiteSpace: 'unset'
                              }}
                            >
                            </p>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="Ingresar comentarios"
                              ref={commentsref}
                              style={{
                                whiteSpace: 'unset'
                              }}
                            >
                            </p>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                cantidad:
                              </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' ref={qttyref} className='fiformfieldrow__input' value={qttyvalue} onChange={(e) => setQttyValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/unidad:
                              </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={cunitref} className='fiformfieldrow__input' value={cunitvalue} onChange={(e) => setCunitValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                c/total:
                              </p>
                          </div>
                          <CurrencyInput size='8' value={totalcost} type='text' ref={ctotalref} className='fiformfieldrow__input' style={{ pointerEvents: 'none' }} />
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                fecha de gasto:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                selecteddate.clone().format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    : entrytype === 'income'
                    ?
                    (
                      <>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title">
                            <p>
                              tipo de ingreso:
                            </p>
                          </div>
                          <div id='ei' className="typeof__picker" onClick={() => setIncomeTypePicker(!incometypepicker)}>
                            <p id='eip'>
                              {
                                incometype === 'reservation'
                                ? 'Reservaciones'
                                : incometype === 'other'
                                ? 'Otros'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='eii' />
                            <div className="typeof__ddown" style={{ display: incometypepicker === true ? 'flex' : 'none' }}>
                              <div onClick={() => {setIncomeType('reservation'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Reservación
                                </p>
                              </div>
                              <div onClick={() => {setIncomeType('other'); setPaymentType('single'); setIncomeTypePicker(!incometypepicker)}} >
                                <p>
                                  Otro
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow" style={{ display: incometype === 'reservation' ? 'flex' : 'none' }} >
                          <div className='fiformfieldrow__title'>
                            <p>
                              reservación:
                            </p>
                          </div>
                          <div className="typeof__picker" onClick={() => setReservationBindPicker(!reservationbinpicker)} >
                            <p
                              style={{
                                textTransform: 'unset'
                              }}
                            >
                              {
                                reservationbind
                              }
                            </p>
                            <ChevronDown />
                            <div className="typeof__ddown" style={{ display: reservationbinpicker === true ? 'flex' : 'none' }}
                            >
                              {
                                reservationsdata.filter((f) => dayjs(f.payment_status === 'active')).map(
                                  (f) => (
                                    <div key={f.id} onClick={() => {setReservationBind(f.id); setReservationBindPicker(!reservationbinpicker)}}>
                                      <p>
                                        {
                                          `${f.name} desde ${dayjs(f.from_date).format('MMM - DD')} hasta ${dayjs(f.to_date).format('MMM - DD')}`
                                        }
                                      </p>
                                    </div>
                                  )
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="fiform__fieldrow">
                          <div className="fiformfieldrow__title" >
                              <p>
                                comentarios:
                              </p>
                          </div>
                          <div className="rowwrapper__value-wrapper">
                            <p
                              contentEditable='true'
                              placeholder="de la venta de leña"
                              ref={pcommentsref}
                            >
                            </p>
                          </div>
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              tipo de pago:
                            </p>
                          </div>
                          <div id='ep' className='typeof__picker' onClick={() => setPaymentTypePicker(!paymenttypicker)} style={{ pointerEvents: 'none' }} >
                            <p id='epp'>
                              {
                                paymenttype === 'multiple'
                                ? 'Cuotas'
                                : paymenttype === 'single'
                                ? 'Único'
                                : 'Seleccionar'
                              }
                            </p>
                            <ChevronDown id='epi' />
                            <div className='typeof__ddown' style={{ display: paymenttypicker === true ? 'flex' : 'none' }} 
                            >
                              <div onClick={() => {setPaymentType('multiple'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Cuotas
                                </p>
                              </div>
                              <div onClick={() => {setPaymentType('single'); setPaymentTypePicker(!paymenttypicker)}} >
                                <p>
                                  Único
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none', pointerEvents: 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              cantidad de cuotas:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='2' type='text' ref={numberofpaymentsref} className='fiformfieldrow__input' value={numberofpaymentsvalue} onChange={(e) => setNumbeOfPaymentsValue(e.currentTarget.value)} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none', pointerEvents: 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              # de cuota:
                            </p>
                          </div>
                          <QttyInput size='4' placeholder='1' type='text' ref={paymentordinalref} className='fiformfieldrow__input' value={paymentordinalvalue} onChange={(e) => setPaymentOrdinalValue(e.currentTarget.value)} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none', pointerEvents: 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto acordado:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={agreedamountref} className='fiformfieldrow__input' value={amountagreedvalue} onChange={(e) => setAmountAgreedValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto recibido:
                            </p>
                          </div>
                          <CurrencyInput size='8' placeholder='$0.00' type='text' ref={amountpaidref} className='fiformfieldrow__input' value={amountpaidvalue} onChange={(e) => setAmountPaidValue(e.currentTarget.value.replace(/[^a-zA-Z0-9. ]/g, ''))} />
                        </div>
                        <div className='fiform__fieldrow' style={{ display: paymenttype === 'multiple' ? 'flex' : 'none' }}>
                          <div className='fiformfieldrow__title'>
                            <p>
                              monto restante:
                            </p>
                          </div>
                          <CurrencyInput size='8' value={amountremainingvalue} type='text' ref={amountremainingref} className='fiformfieldrow__input'/>
                        </div>
                        <div className='fiform__fieldrow'>
                          <div className='fiformfieldrow__title'>
                            <p>
                              fecha de entrada
                            </p>
                          </div>
                          <div className='rowwrapper__value-wrapper'>
                            <p
                              style={{
                                textTransform: 'capitalize'
                              }}
                            >
                              {
                                selecteddate.clone().format('DD - MMM - YYYY')
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )
                    :
                    (
                      <div>
                        Error
                      </div>
                    )
                  }
                </div>
              </Styles.FormWrapper>
              <Styles.AddNewEntryCTA
                as={motion.div}
                style={{
                  backgroundColor: '#3A9659'
                }}
                onClick={handleSaveNewEntry}
                whileTap={{
                  scale: 0.9
                }}
              >
                <p
                  style={{
                    color: '#EBF2FA',
                    textTransform: 'uppercase',
                    top: '-1rem'
                  }}
                >
                  guardar
                </p>
                <Save style={{ color: '#EBF2FA' }} />
              </Styles.AddNewEntryCTA>
            </>
          )
        }
      </Styles.DetailsWrapper>
    </>
    )
    :
    (
      <div className='calendar-loader'></div>
    )
  }
  </>
  )
}

export default CFinance;