import React from "react";
import { motion } from "framer-motion";

import "./Reservations.scss";
import CReservations from '../../components/Calendar/CReservations/CReservations'

const Reservations = ({ session }) => {
  return (
    <div className="availability">
      <CReservations session={session} />
    </div>
  );
};

export default Reservations;
