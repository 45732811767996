import React, { useEffect, useState, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/plugin/isBetween";
import "dayjs/plugin/isSameOrAfter";
import { motion } from "framer-motion";

import * as Styles from "./DatePickerStyles";
import {
  ChevronDown,
  CircledLeft,
  CircledRight
} from "../../../../../assets/icons/Index";

const DatePicker = ({
  onNext,
  type,
  flowValue,
  changeFromDate,
  changeToDate,
  startDate
}) => {
  // Set Time Context & 'Logical Operators'
  dayjs.locale("es");
  const isBetween = require("dayjs/plugin/isBetween");
  const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);

  const [selectedDate, setSelectedDate] = useState(dayjs(startDate));
  const [visible, isVisible] = useState(false);
  const currentDay = useMemo(() => dayjs().toDate(), []);
  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  );
  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);
  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const handleNext = () => {
    const handleFlow = () => {
      switch (type) {
        case "fromDate":
          onNext(selectedDate);
          flowValue("entryTime");
          break;
        case "toDate":
          onNext(selectedDate);
          flowValue("exitTime");
          break;
        case "fromDSummary":
          changeFromDate(selectedDate);
          flowValue("closeFD");
          break;
        case "toDSummary":
          changeToDate(selectedDate);
          flowValue("closeTD");
          break;
        default:
          break;
      }
    };
    handleFlow();
  };

  const handleCancel = () => {
    if (type === "fromDSummary") {
      flowValue("closeFD");
    } else if (type === "toDSummary") {
      flowValue("closeTD");
    } else {
      flowValue('closeModal')
    }
  };

  useEffect(() => {
    const handleVisibility = () => {
      switch (type) {
        case "fromDate":
          isVisible(true);
          break;
        case "toDate":
          isVisible(true);
          break;
        case "fromDSummary":
          isVisible(true);
          break;
        case "toDSummary":
          isVisible(true);
          break;
        default:
          isVisible(false);
          break;
      }
    };
    handleVisibility();
  }, [type]);

  return (
    <Styles.CalendarWrapper visible={visible}>
      <Styles.CalendarHeaderWrapper>
        <Styles.CHeaderTitle>
          <p>
            {type === "fromDate" || type === "fromDSummary"
              ? "fecha de entrada"
              : type === 'toDate' ?
              'fecha de salida'
              : 'fecha'
            }
          </p>
        </Styles.CHeaderTitle>
        <Styles.CHeaderDateWrapper>
          <Styles.CHeaderDate>
            {selectedDate.clone().format("MMMM, YYYY")}
          </Styles.CHeaderDate>
          <Styles.CHeaderNavWrapper>
            <Styles.CHeaderNavButton as={motion.div} whileTap={{ scale: 0.8 }}>
              <CircledLeft
                onClick={() =>
                  setSelectedDate((date) => date.subtract(1, "month"))
                }
              />
            </Styles.CHeaderNavButton>
            <Styles.CHeaderNavButton as={motion.div} whileTap={{ scale: 0.8 }}>
              <CircledRight
                onClick={() => setSelectedDate((date) => date.add(1, "month"))}
              />
            </Styles.CHeaderNavButton>
          </Styles.CHeaderNavWrapper>
        </Styles.CHeaderDateWrapper>
        <Styles.CHeaderDateWrapper style={{ marginTop: "0.5em" }}>
          <Styles.CHeaderDateSubTitle>
            {selectedDate.clone().format("dddd D")}
          </Styles.CHeaderDateSubTitle>
          <Styles.CHeaderNavWrapper>
            <Styles.CHeaderNavButton as={motion.div} whileTap={{ scale: 1.5 }}>
              <ChevronDown
                style={{ fontSize: "1em", transform: "rotate(90deg)" }}
                onClick={() =>
                  setSelectedDate((date) => date.subtract(1, "day"))
                }
              />
            </Styles.CHeaderNavButton>
            <Styles.CHeaderNavButton as={motion.div} whileTap={{ scale: 1.5 }}>
              <ChevronDown
                style={{ fontSize: "1em", transform: "rotate(-90deg)" }}
                onClick={() => setSelectedDate((date) => date.add(1, "day"))}
              />
            </Styles.CHeaderNavButton>
          </Styles.CHeaderNavWrapper>
        </Styles.CHeaderDateWrapper>
      </Styles.CalendarHeaderWrapper>
      <Styles.WeekDaysWrapper style={{ marginTop: "-0.5rem" }}>
        {generateWeeksOfTheMonth[0].map((day, index) => (
          <Styles.WeekDayCell key={`week-day-${index}`}>
            <p>{dayjs(day).format("dd")}</p>
          </Styles.WeekDayCell>
        ))}
      </Styles.WeekDaysWrapper>
      {generateWeeksOfTheMonth.map((week, weekIndex) => (
        <Styles.DaysWrapper
          key={`week-${weekIndex}`}
          style={{ marginTop: "-1rem" }}
        >
          {week.map((day, dayIndex) => (
            <Styles.DaysCell
              key={`day-${dayIndex}`}
              onClick={() => setSelectedDate(dayjs(day))}
              current={dayjs(day).isSame(dayjs(currentDay), "date")}
              selected={dayjs(day).isSame(dayjs(selectedDate), "date")}
            >
              <motion.p whileTap={{ scale: 0.75 }}>{day.getDate()}</motion.p>
            </Styles.DaysCell>
          ))}
        </Styles.DaysWrapper>
      ))}
      <Styles.CTAWrapper>
        <Styles.CTAButton
          as={motion.div}
          whileTap={{ scale: 0.9 }}
          onClick={handleCancel}
        >
          <p>Cancelar</p>
        </Styles.CTAButton>
        <Styles.CTAButton
          color="featured"
          as={motion.div}
          whileTap={{ scale: 0.9 }}
          onClick={handleNext}
        >
          <p>ok</p>
        </Styles.CTAButton>
      </Styles.CTAWrapper>
    </Styles.CalendarWrapper>
  );
};
export default DatePicker;
