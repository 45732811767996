import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../supabaseClient'
import { motion } from 'framer-motion'

import './Chat.scss'
import './Chat.css'
import { ChatBG, ChatAvatar} from '../../assets/images/Index'
import { Clip, Day, Help, Info, Night, SendMessage, Received, Twitter, FacebookCircled, EJDLVMini, CircledLeft, ReservationForm, Edit, Save, Cancel } from '../../assets/icons/Index'

const Chat = ({ session }) => {
  const [dashboardorigin, setDashboardOrigin] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.state !== null) {
      setDashboardOrigin(true);
      setCurrentProfileId(location.state.profileid)
    }
  }, [location])
  

  //  ------------> START - Setup Time Context <------------

  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);

  const currentDay = useMemo(() => dayjs().toDate(), [])
  const currentMonthFirstDay = useMemo(() => dayjs(currentDay).startOf('month'))
  const currentMonthFirstDayOfFirstWeek = useMemo(() => dayjs(currentMonthFirstDay).startOf('week'))

  //  ------------> Setup Time Context - END <------------

  //  ------------> START - Get Profiles, Reservations & Rooms Data <------------

  const [loadingrooms, setLoadingRooms] = useState(true);
  const [gettingprofiles, setGettingProfiles] = useState(true);
  const [gettingreservations, setGettingReservations] = useState(true);
  const receiverAppId = process.env.REACT_APP_OSRECEIVER_APP_ID;
  const receiverSecret = process.env.REACT_APP_OSRECEIVER_REST_API;
  const [noreservations, setNoReservations] = useState(null);
  const [gettingrooms, setGettingRooms] = useState(true);
  const [roomsdata, setRoomsData] = useState(null);
  const [gettingclientsrooms, setGettingClientsRooms] = useState(true);
  const [clientsroomsdata, setClientsRoomsData] = useState(null);
  const [noclientsroomsdata, setNoClientsRoomsData] = useState(null);
  const [roomselected, setRoomSelected] = useState(false);
  const [gettingmessages, setGettingMessages] = useState(true);
  const [nomessages, setNoMessages] = useState(null);
  const [allmessagesdata, setAllMessagesData] = useState([]);
  const [selectedroommessages, setSelectedRoomMessages] = useState(null);
  const [noactiverooms, setNoActiveRooms] = useState(null);
  const [roomhasnomessages, setRoomHasNoMessages] = useState(null);
  const [clientinfosection, setClientInfoSection] = useState('Profile');
  const [selectedclienthasnoreservations, setSelectedClientHasNoReservations] = useState(null);
  const [profilesdata, setProfilesData] = useState(null);
  const [reservationsdata, setReservationsData] = useState([]);
  const [gettingroommessages, setGettingRoomMessages] = useState(null);

  useEffect(() => {
    getProfiles();
  }, [])

  const getProfiles = async () => {
    // console.log('Getting Profiles Table')
    setGettingProfiles(true);
    try {
      let { data, error, status } = await supabase
        .from('profiles')
        .select()

      if (data) {
        // console.log('Profiles Table Has Data')
        setProfilesData(data)
      }
      
      if (error) {
        // console.log('Error While Getting Profiles Data')
        throw error
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingProfiles(false);
      // console.log('Finished Getting Profiles Table')
    }
  }

  useEffect(() => {
    if (gettingprofiles === false) {
      getReservations();
    }
  }, [gettingprofiles])

  const getReservations = async () => {
    // console.log('Getting Reservations Table')
    setGettingReservations(true);
    try {
      let { data, error, status } = await supabase
        .from('reservations')
        .select()
        .order('to_date', {ascending: true})

        if(data.length === 0) {
          // console.log('No Reservations Data')
          setNoReservations(true);
        }

        if(error && status === 406) {
          // console.log('No Reservations Data (406)')
          setNoReservations(true)
        }

        if(data.length !== 0) {
          // console.log('Reservations Data')
          setNoReservations(true);
          setReservationsData(data);
        }

        if(error && status !== 406) {
          // console.log('Error While Getting Reservations Data')
          throw error
        }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingReservations(false);
      // console.log('Finished Getting Reservations')
    }
  }

  useEffect(() => {
    if (gettingreservations === false) {
      getRooms()
    }
  }, [gettingreservations])

  const getRooms = async () => {
    // console.log('Getting Latest Rooms')
    setGettingRooms(true);
    try {
      let { data, error, status } =  await supabase
        .from('rooms')
        .select()
        .match({
          profile_id: session.user.id,
          role: 'admin'
        })
        .order('created_at', {ascending: true})
      
      if (data.length === 0) {
        // console.log('No Active Rooms')
        setNoActiveRooms(true);
      }

      if (error) {
        // console.log('Error While Getting Rooms')
        throw error
      }

      if (data.length !== 0 ) {
        // console.log('Rooms Has Data')
        setRoomsData(data);
        setNoActiveRooms(false);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingRooms(false);
      // setLoadingRooms(false);
      // console.log('Finished Getting Rooms')
    }
  }

  useEffect(() => {
    if (gettingrooms === false) {
      getClientsRooms();
    }
  }, [gettingrooms])

  const getClientsRooms = async () => {
    // console.log('Getting Clients Rooms Data')
    setGettingClientsRooms(true);
    try {
      let { data, error, status } =  await supabase
        .from('rooms')
        .select(`
          *,
          profiles(
            *
          )
        `)
        .neq('role', 'admin')
        .order('created_at', {ascending: true})


      if (data.length === 0) {
        // console.log('Query for Clients Rooms Data didn`t work')
        setNoClientsRoomsData(true);
      }

      if (error) {
        // console.log('Error While Getting Rooms Clients Data')
        setNoClientsRoomsData(true);
      }

      if (data.length !== 0) {
        // console.log('Got Data for Clients Rooms')
        setClientsRoomsData(data)
      }
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setGettingClientsRooms(false);
      // setLoadingRooms(false);
      // console.log('Finished Getting Clients Rooms Data')
    }
  }

  // console.log(clientsroomsdata);

  useEffect(() => {
    if (gettingclientsrooms === false) {
      getMessages()
    }
  }, [gettingclientsrooms])

  const getMessages = async () => {
    // console.log('Getting All Messages')
    setGettingMessages(true);
    try {
      let { data, error, status } = await supabase
        .from('messages')
        .select()
        .order('created_at', {ascending: false})

      if (data.length === 0) {
        // console.log('No Messages Found')
        setNoMessages(true);
      }

      if (error) {
        // console.log('Error Whilst Getting Messages')
        setNoMessages(true);
      }

      if (data.length !== 0) {
        // console.log('Found Messages')
        setNoMessages(false);
        setAllMessagesData(data);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingMessages(false);
      setLoadingRooms(false);
      if (dashboardorigin === true) {
        getRoomMessages();
      }
      // console.log('Finished Getting All Messages')
    }
  }

  // console.log(allmessagesdata);

  //  ------------> Get Profiles, Reservations, Rooms Data & All Messages - END <------------

  //  ------------> START - Get Selected Room Messages & Set Client Info <------------

 

  const [currentprofileid, setCurrentProfileId] = useState(null);
  const [currentroomid, setCurrentRoomId] = useState(null);
  const [paymenttype, setPaymentType] = useState('Cuotas');
  const [startdate, setStartDate] = useState('Seleccionar Fecha');
  const [enddate, setEndDate] = useState('Seleccionar Fecha');
  const [fromtimeofday, setFromTimeOfDay] = useState('day');
  const [totimeofday, setToTimeOfDay] = useState('afternoon');
  const [resstatus, setResStatus] = useState(false);

  const getRoomMessages =  async (roomsdata) => {
    setLoadingRooms(true);
    setGettingRoomMessages(true);
    let roomId = null;
    if (dashboardorigin === true) {
      roomId = location.state.roomid;
      setCurrentRoomId(location.state.roomid)
    } else {
      roomId = roomsdata.id;
      setCurrentRoomId(roomsdata.id);
      setCurrentProfileId(clientsroomsdata.filter((f) => roomsdata.id === f.id).map((f) => f.profiles.id));
    }
    // console.log(roomsdata.id)
    // console.log(roomsdata)

    try {
      let { data, error, status } =  await supabase
        .from('messages')
        .select()
        .match({
          room_id: roomId
        })
        .order('created_at', {ascending: true})

      if (data.length === 0) {
        setRoomHasNoMessages(true);
      }

      if (error) {
        throw error
      }

      if (data.length !== 0) {
        setSelectedRoomMessages(data);
        setRoomHasNoMessages(false);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setGettingRoomMessages(false);
      setRoomSelected(true);
      setLoadingRooms(false);
    }
  }

  // console.log(currentprofileid)
  // console.log(currentroomid)
  // console.log(profilesdata)

  useEffect(() => {
    if (gettingroommessages === false) {
      if (reservationsdata.some((s) => currentprofileid[0] === s.user_id)) {
        console.log('Profile Has Reservations Checked True');
        setStartDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.from_date)));
        setEndDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.to_date)));
        setFromTimeOfDay(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.from_time_of_day);
        setToTimeOfDay(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.to_time_of_day);
        setResStatus(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.confirmed);
        if (reservationsdata.some((s) => currentprofileid[0] === s.user_id && s.payment_type !== null)) {
          setPaymentType(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.payment_type);
        } else {
          setPaymentType('Por Definir')
        }
      }
    }
  }, [gettingroommessages])

  // console.log(paymenttype)
  // console.log(startdate)
  // console.log(enddate)
  // console.log(fromtimeofday)
  // console.log(totimeofday)

  //  ------------> Get Selected Room Messages & Set Client Info - END <------------

  //  ------------> START - Handle Message Submit <------------

  const messageRef = useRef();

  const sendNotification = async (content) => {
    const response = axios.post("https://onesignal.com/api/v1/notifications", {
      app_id: receiverAppId,
      headings: { en: `Tienes un nuevo mensaje de El Jardin de Los Volcanes` },
      contents: { en: content },
      tags: [{ key: "user_id", relation: "=", value: `${currentprofileid[0]}` }],
      url: 'https://eljardindelosvolcanes.com/chat'
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${receiverSecret}`,
      }
    }
    );

    return response.data;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let content = messageRef.current.value;

    if (typeof content === 'string' && content.trim().length !== 0) {
      messageRef.current.value = ''
      
      try {
        let { error } = await supabase
          .from('messages')
          .insert({ profile_id: session.user.id, room_id: currentroomid, content: content })
        if (error) {
          throw error
        }
      }
      catch (error) {
        alert(error.message)
      }
      finally {
        await sendNotification(content);
        // console.log('handleMessageInput Finished')
      }
    }

  }

  //  ------------> Handle Message Submit -END <------------

  //  ------------> START - Subscribe to Messages Table Inserts <------------
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (gettingroommessages === false) {
      if (messagesContainerRef.current) {
        setTimeout(() => {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
        }, 500)
      }
      const subscription = supabase
      .channel('public:messages')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'messages',
        filter: `room_id=eq.${currentroomid}`,
      }, payload => {
        // console.log('Subscribe Called')
        setSelectedRoomMessages((current) => [...current, payload.new])
        if (messagesContainerRef.current) {
          setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
          }, 500)
        }
      })
      .subscribe() 

      return () => {
        supabase.removeChannel(subscription)
      }
    }
  }, [gettingroommessages])

  //  ------------> Subscribe to Messages Table Inserts - END <------------
  
  //  ------------> START - Handle Exit Room Call <------------
  
  const handleExitRoom = () => {
    setRoomSelected(false);
    setFormActive(false);
    setNewReservations(false);
    setClientInfoSection('Profile');
    setResStatus(false);
    setPaymentType('Por Definir');
    setDashboardOrigin(false);
  }

  //  ------------> Handle Exit Room Call - END <------------
  //  ------------> START - Handle Profile Section Change <------------
  
  const handleProfileSectionChange = () => {
    setClientInfoSection('Profile');
    if (formisactive === true) {
      setFormActive(false);
    } else if (reservationsdata.some((s) => currentprofileid[0] === s.user_id) === false) {
      setNewReservations(false);
      setPaymentType('Por Definir');
      setResStatus(false);
    }
  }

  //  ------------> Handle Exit Room Call - END <------------
  //  ------------> START - Handle Form Edit <------------
  
  const handleFormEdit = () => {
    if (formisactive ===  true) {
      if (reservationsdata.some((s) => currentprofileid[0] === s.user_id)) {
        setStartDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.from_date)));
        setEndDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.to_date)));
        setFromTimeOfDay(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.from_time_of_day);
        setToTimeOfDay(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.to_time_of_day);
        setResStatus(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.confirmed);
        setFormActive(false);
        setEditDates(false);
        if (reservationsdata.some((s) => currentprofileid[0] === s.user_id && s.payment_type !== null)) {
          setPaymentType(reservationsdata.find((f) => currentprofileid[0] === f.user_id)?.payment_type);
        } else {
          setPaymentType('Por Definir')
        }
      }
    } else if (formisactive === false) {
      setFormActive(true);
    }
  }

  // ------------> START - Handle Date Edit Call <------------
  const handleNewEntryCancel = () => {
    setNewReservations(false);
    setStartDate(dayjs(currentDay));
    setEndDate(dayjs(currentDay).add(1, 'day'));
    setFromTimeOfDay('day');
    setToTimeOfDay('afternoon');
    setResStatus(false);
    setPaymentType('Por Definir');
  }

  // ------------> START - Handle Date Edit Call <------------
  const [editdates, setEditDates] = useState(false);

  const handleDateEdit = () => {
    if (editdates === true) {
      setStartDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.from_date)));
      setEndDate(reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => dayjs(f.to_date)));
      setEditDates(false);
    } else if (editdates === false) {
      setEditDates(true)
    }
  }
  // ------------> Handle Date Edit Call - END <------------

  //  ------------> Handle Form Edit - END <------------
  //  ------------> START - Picker State's Hooks & Click Handles <------------
  
  const [resstatuspicker, setResStatusPicker] = useState(false);
  const [paymenttypepicker, setPaymentTypePicker] = useState(false);
  const [fromtimeofdaypicker, setFromTimeOfDayPicker] = useState(false);
  const [totimeofdaypicker, setToTimeOfDayPicker] = useState(false);

  //  ------------> Picker State's Hooks & Click Handles - END <------------

  //  ------------> START - Handle Reservation Form Submit & Update <------------

    const [formisactive, setFormActive] = useState(false);
    const [newreservation, setNewReservations] = useState(false);
    const resID = useRef(null);
    const nameref = useRef(null);
    const emailref = useRef(null);
    const phoneref = useRef(null);
    const guestsref = useRef(null);
    const numberofpaymentsref = useRef(null);
    // const numberofremainingpaymentsref = useRef(null);
    const amountagreedref = useRef(null);
    // const amountremainingref = useRef(null);

    const [inputalert, setInputAlert] = useState(false);
    const [inputname, setInputName] = useState(null);

    const handleAlertModalClose = () => {
        setInputAlert(false)
        setInputName(null)
    }

    const handleResFormUpdate = async () => {
      let id = resID.current.textContent;
      let confirmed = resstatus;
      let name = nameref.current.textContent;
      let email = emailref.current.textContent;
      let phone = phoneref.current.textContent;
      let guests = guestsref.current.textContent;
      let numberofpayments = numberofpaymentsref.current.textContent;
      // let numberofremainingpayments = numberofremainingpaymentsref.current.textContent;
      // let amountremaining = amountremainingref.current.textContent
      let amountagreed = amountagreedref.current.textContent;

      if (paymenttype === 'Cuotas') {
        if (numberofpaymentsref.current.textContent === 'Por Definir') {
          numberofpayments = null;
        } else {
          numberofpayments = numberofpaymentsref.current.textContent;
        }
        
        // if (numberofremainingpaymentsref.current.textContent === 'Por Definir') {
        //   numberofremainingpayments = null;
        // } else {
        //   numberofremainingpayments = numberofremainingpaymentsref.current.textContent;
        // }
        
        // if (amountremainingref.current.textContent === 'Por Definir') {
        //   amountremaining = null;
        // } else {
        //   amountremaining = amountremainingref.current.textContent;
        // }
      } else if (paymenttype === 'Único') {
        numberofpayments = null;
        // numberofremainingpayments = null;
        // amountremaining = null;
      } else {
        setPaymentType(null);
        numberofpayments = null;
        // numberofremainingpayments = null;
        // amountremaining = null;
      }

      if (amountagreedref.current.textContent === 'Por Definir') {
        amountagreed = null;
      } else {
        amountagreed = amountagreedref.current.textContent;
      }

      if (typeof name !== 'string' || name.trim().length <= 2 || name === 'Ingresar Nombre') {
        setInputName('nombre');
        setInputAlert(true);
      } else if (typeof email !== 'string' || email.trim().length <= 2 || email === 'Ingresar Correo') {
        setInputName('correo');
        setInputAlert(true);
      } else if (typeof phone !== 'string' || phone.trim().length <= 2 || phone === 'Ingresar Teléfono') {
        setInputName('teléfono');
        setInputAlert(true);
      } else if (typeof guests !== 'string' || guests.trim().length === 0 || guests === 'E.G: 10' ) {
        setInputName('huéspedes');
        setInputAlert(true);
      } else {
        setLoadingRooms(true);
        try {
          const { error } = await supabase
            .from('reservations')
            .update({
              user_id: currentprofileid[0],
              name: name,
              email: email,
              phone: phone,
              guests: guests,
              from_date: dayjs(startdate).clone().format('YYYY-MM-DD'),
              from_time_of_day: fromtimeofday,
              to_date: dayjs(enddate).clone().format('YYYY-MM-DD'),
              to_time_of_day: totimeofday,
              payment_type: paymenttype,
              number_of_payments: numberofpayments,
              amount_agreed: amountagreed,
              updated_at: new Date(),
              confirmed: confirmed,
            })
            .eq('id', id)

          if (error) {
            throw error
          }
        }
        catch (error) {
          alert(error.message)
        }
        finally {
          getReservations();
          setFormActive(false);
          setEditDates(false);
          setLoadingRooms(false);
        }
      }
    }

    const handleFormNewEntry = async () => {
      let confirmed = resstatus;
      let name = nameref.current.textContent;
      let email = emailref.current.textContent;
      let phone = phoneref.current.textContent;
      let guests = guestsref.current.textContent;
      let numberofpayments = numberofpaymentsref.current.textContent;
      // let numberofremainingpayments = numberofremainingpaymentsref.current.textContent;
      // let amountremaining = amountremainingref.current.textContent
      let amountagreed = amountagreedref.current.textContent;

      if (paymenttype === 'Cuotas') {
        if (numberofpaymentsref.current.textContent === '') {
          numberofpayments = null;
        } else {
          numberofpayments = numberofpaymentsref.current.textContent;
        }
        
        // if (numberofremainingpaymentsref.current.textContent === '') {
        //   numberofremainingpayments = null;
        // } else {
        //   numberofremainingpayments = numberofremainingpaymentsref.current.textContent;
        // }
        
        // if (amountremainingref.current.textContent === '') {
        //   amountremaining = null;
        // } else {
        //   amountremaining = amountremainingref.current.textContent;
        // }
      } else if (paymenttype === 'Único') {
        numberofpayments = null;
        // numberofremainingpayments = null;
        // amountremaining = null;
      } else {
        setPaymentType(null);
        numberofpayments = null;
        // numberofremainingpayments = null;
        // amountremaining = null;
      }

      if (amountagreedref.current.textContent === '') {
        amountagreed = null;
      } else {
        amountagreed = amountagreedref.current.textContent;
      }

      if (typeof name !== 'string' || name.trim().length <= 2) {
        setInputName('nombre');
        setInputAlert(true);
      } else if (typeof email !== 'string' || email.trim().length <= 2) {
        setInputName('correo');
        setInputAlert(true);
      } else if (typeof phone !== 'string' || phone.trim().length <= 6) {
        setInputName('teléfono');
        setInputAlert(true);
      } else if (typeof guests !== 'string' || guests.trim().length < 1) {
        setInputName('huéspedes');
        setInputAlert(true);
      } else {
        setLoadingRooms(true);
        try {
          
    
          const updates = {
            user_id: currentprofileid[0],
            name: name,
            email: email,
            phone: phone,
            guests: guests,
            from_date: dayjs(startdate).clone().format('YYYY-MM-DD'),
            from_time_of_day: fromtimeofday,
            to_date: dayjs(enddate).clone().format('YYYY-MM-DD'),
            to_time_of_day: totimeofday,
            payment_type: paymenttype,
            number_of_payments: numberofpayments,
            amount_agreed: amountagreed,
            updated_at: new Date(),
            confirmed: confirmed,
          }
    
          let { error } = await supabase.from('reservations').upsert(updates)
    
          if (error) {
            throw(error.message)
          }
        }
        catch (error) {
          alert(error.message)
        }
        finally {
          getReservations();
          setNewReservations(false);
          setLoadingRooms(false);
        }
      }
    }
  //  ------------> Handle Reservation Form Submit & Update - END <------------

  return (
    <div className='chat'>
      {
        loadingrooms
        ? (
          <div className='chats-loader'></div>
        )
        : (
          <>
            <div className="chat__formsubmit-modal" 
              style={{
                display: inputalert === true
                ? 'flex'
                : 'none'
              }}
            >
              <div className="chat__fsmodal-title">
                <p>
                  Algo hace falta
                </p>
              </div>
              <div className="chat__fsmodal-message">
                <p>
                  Por favor ingresa el { inputalert === true ? <span style={{ textDecoration: 'underline', background: 'none' }}>{inputname}</span> : 'Error Mortal' }
                </p>
              </div>
              <div className="cdashboard__dcmodal-ctas">
                <div style= {{ display: 'none' }}>
                  <p>
                    Borrar
                  </p>
                </div>
                <div onClick={handleAlertModalClose}>
                  <p>
                    Ok!
                  </p>
                </div>
              </div>
            </div>
            <motion.div className='chatbox__container' style={{ pointerEvents: inputalert === true ? 'none' : 'all' }}
                initial={{
                  opacity: 0
                }}
                whileInView={{
                  opacity: 1
                }}
                transition={{
                  duration: 0.4
                }}
                viewport={{
                  once: true
                }}
            >
              <img src={ChatBG} alt="EJDLV Chat Background" />
              {
                roomselected === true
                ? (
              <div className='chatbox__messages-wrapper' ref={messagesContainerRef}>
                {
                  roomhasnomessages
                  ? (
                    <div className='messages__nomessages'>
                      <p>
                        Aún no hay mensajes, escribe algo...
                      </p>
                    </div>
                  )
                  : (
                      selectedroommessages.map((selectedroommessages) =>
                      <div 
                        className='messages__row-wrapper'
                        key={selectedroommessages.id}
                        style={{
                          alignItems: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                          ? 'flex-end'
                          : 'flex-start'
                        }}
                      >
                        <div 
                          className='messages__box'
                          style={{
                            borderRadius: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                            ? '16px 16px 0px 16px'
                            : '16px 16px 16px 0px',
                            backgroundColor: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                            ? '#374758'
                            : '#EBF2FA'
                          }}
                        >
                          <p
                            style={{
                              color: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                              ? '#EBF2FA'
                              : '#374758'
                            }}
                          >
                            {
                              selectedroommessages.content
                            }
                          </p>
                        </div>
                        <div 
                          className='messages__timestamp'
                          style={{
                            backgroundColor: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                            ? '#374758'
                            : '#EBF2FA'
                          }}
                        >
                          <p
                            style={{
                              color: (selectedroommessages.profile_id === '5bda195a-33f9-4719-988b-9d89bedd3536' || selectedroommessages.profile_id === 'd2c01f1b-3392-4da2-bb47-598532a521a5')
                              ? '#EBF2FA'
                              : '#374758'
                            }}      
                          >
                            {
                              dayjs(selectedroommessages.created_at).format('hh.mm A')
                            }
                          </p>
                        </div>
                      </div>
                      )
                  )
                }
              </div>
              )
              :
              (
                <div></div>
              )
              }
            <div className='chatbox__headecontainer'>
              <div 
                className='chaboxheadcont__wrapper'
                style={{
                  display: roomselected ? 'flex' : 'none'
                }}
              >
                <div>
                  <img 
                    src={
                      clientsroomsdata.filter(
                        (f) => currentroomid === f.id
                      ).map(
                        (f) => f.profiles.avatar_url
                      )
                    } 
                    alt="EJDLV Avatar" 
                  />
                </div>
                <div>
                  <h4>
                    {
                      clientsroomsdata.filter(
                        (f) => currentroomid === f.id
                      ).map(
                        (f) => f.profiles.username
                      )
                    }
                  </h4>
                  <div>
                    <div></div>
                    <p>
                      {
                        clientsroomsdata.filter(
                          (f) => currentroomid === f.id
                        ).map(
                          (f) => f.profiles.email
                        )
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div 
                className='chatbox__noroomheader'
                style={{
                  display: roomselected ? 'none' : 'flex'
                }}
              >
                <p>
                  Selecciona una conversación
                </p>
              </div>
            </div>
            <form 
              className='chatbox__messagebar'
              onSubmit={handleFormSubmit}
            >
              {/* <div className='chabomessagebar__fileattach'>
                <Clip />
                <div></div>
              </div> */}
              <input 
                className='chabomessagebar__messageinput'
                id='message'
                name='message'
                type='text'
                placeholder='Escribe tu mensaje'
                ref={messageRef}
                disabled={roomselected === true ? false : true}
              />
              <motion.button 
                className='chabomessagebar__sendbutton'
                onClick={handleFormSubmit}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <SendMessage />
              </motion.button>
            </form>
          </motion.div>
          <motion.div className='chatdetails__container' style={{ pointerEvents: inputalert === true ? 'none' : 'all' }}
               initial={{
                opacity: 0
              }}
              whileInView={{
                opacity: 1
              }}
              transition={{
                duration: 0.6
              }}
              viewport={{
                once: true
              }}
          >
            <div className='chatdetails__header'>
              {
                roomselected === true
                ?
                (
                  <>
                  <div className='chatdeails__profileheader'>
                    <div>
                      <img 
                        src={
                          clientsroomsdata.filter(
                            (f) => currentroomid === f.id
                          ).map(
                            (f) => f.profiles.avatar_url
                          )
                        } 
                        alt="Client's Avatar" 
                      />
                    </div>
                    <h4>
                      {
                        clientsroomsdata.filter(
                          (f) => currentroomid === f.id
                        ).map(
                          (f) => f.profiles.username
                        )
                      }
                    </h4>
                  </div>
                  <motion.div 
                    className='chatdetails__header-backbutton'
                    onClick={handleExitRoom}
                    whileHover={{
                      scale: 1.1
                    }}
                    whileTap={{
                      scale: 0.9
                    }}
                  >
                    <CircledLeft />
                  </motion.div>
                  </>
                )
                :
                (
                  <h4>
                    últimos chats
                  </h4>
                )
              }
            </div>
            <div className='chatrooms__wrapper'>
              {
                noactiverooms
                ? (
                  <div className='chatrooms__norooms'>
                    <p>
                      No hoy conversaciones recientes activas.
                    </p>
                  </div>
                )
                : (
                  roomselected === false
                  ?
                  (  roomsdata.map((roomsdata) => (
                      <motion.div className='chatrooms__pills' key={roomsdata.id} onClick={() => getRoomMessages(roomsdata)}
                        whileHover={{
                          scale: 1.05
                        }}
                        whiletap={{
                          scale: 0.95
                        }}
                      >
                        <div className='chatroom__pills-profilewrapper'>
                          <div>
                            <img 
                              src={
                                clientsroomsdata.filter(
                                  (f) => roomsdata.id === f.id
                                ).map(
                                  (f) => f.profiles.avatar_url
                                )
                              } 
                              alt="User Profile Avatar" 
                            />
                          </div>
                          <div>
                            <h5>
                              {
                                clientsroomsdata.filter(
                                  (f) => roomsdata.id === f.id
                                ).map(
                                  (f) => f.profiles.username
                                )
                              }
                            </h5>
                            <p>
                              {
                                allmessagesdata.find(
                                  (f) => (roomsdata.id === f.room_id && f.profile_id !== '5bda195a-33f9-4719-988b-9d89bedd3536' && f.profile_id !== 'd2c01f1b-3392-4da2-bb47-598532a521a5') 
                                )?.content
                              }
                            </p>
                          </div>
                        </div>
                        <div className='chatroom__pills-roomdetails'>
                          <div>
                            <p>
                              Hace{' '}
                              {
                                dayjs(currentDay).diff(dayjs(allmessagesdata.find(
                                  (f) => (roomsdata.id === f.room_id && f.profile_id !== session.user.id) 
                                )?.created_at), 'hour')
                              }
                              {' '}horas
                            </p>
                          </div>
                          <div>
                            <Received />
                            <Twitter 
                              style={{
                                display: clientsroomsdata.some(
                                  (s) => (roomsdata.id === s.id && s.profiles.provider === 'twitter')
                                )
                                ? 'flex'
                                : 'none'
                              }}
                            />
                            <FacebookCircled 
                              style={{
                                display: clientsroomsdata.some(
                                  (s) => (roomsdata.id === s.id && s.profiles.provider === 'facebook')
                                )
                                ? 'flex'
                                : 'none'
                              }}
                            />
                            <EJDLVMini 
                              style={{
                                display: clientsroomsdata.some(
                                  (s) => (roomsdata.id === s.id && s.profiles.provider === 'email')
                                )
                                ? 'flex'
                                : 'none'
                              }}
                            />
                            <div>
                              <p>
                                1
                              </p>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))
                  )
                  : 
                  (
                    <>
                    <div className='chatdetails__activeprofile-sectionpillswrapper'>
                      <motion.div 
                        className='activeprofile__sectionpills'
                        style={{
                          backgroundColor: clientinfosection === 'Profile' ? '#374758' : 'unset'
                        }}
                        onClick={
                          handleProfileSectionChange
                        }
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <p
                          style={{
                            color: clientinfosection === 'Profile' ? '#EBF2FA' : '#374758'
                          }}
                        >
                          Perfil
                        </p>
                      </motion.div>
                      <motion.div 
                        className='activeprofile__sectionpills'
                        style={{
                          backgroundColor: clientinfosection === 'Reservation' ? '#374758' : 'unset'
                        }}
                        onClick={
                          () => setClientInfoSection('Reservation')
                        }
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <p
                          style={{
                            color: clientinfosection === 'Reservation' ? '#EBF2FA' : '#374758'
                          }}
                        >
                          Reservación
                        </p>
                      </motion.div>
                      <motion.div
                        className='activeprofile__datamutation-controls'
                        style={{
                          display: (clientinfosection === 'Reservation' && reservationsdata.some((s) => currentprofileid[0] === s.user_id)) ? 'flex' : 'none',
                          marginLeft: 'auto'
                        }}
                        onClick={
                          handleFormEdit
                        }
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <Edit style={{ display: formisactive === true ? 'none' : 'flex' }} />
                        <Cancel style={{ display: formisactive === true ? 'flex' : 'none' }} />
                      </motion.div>
                      <motion.div
                        className='activeprofile__datamutation-controls'
                        style={{
                          display: newreservation === true ? 'flex' : 'none',
                          marginLeft: 'auto'
                        }}
                        onClick={
                          handleNewEntryCancel
                        }
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <Cancel />
                      </motion.div>
                      <motion.div
                        className='activeprofile__datamutation-controls'
                        style={{
                          display: newreservation === true ? 'flex' : 'none'
                        }}
                        onClick={handleFormNewEntry}
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <Save />
                      </motion.div>
                      <motion.div
                        className='activeprofile__datamutation-controls'
                        style={{
                          display: formisactive === true ? 'flex' : 'none'
                        }}
                        onClick={handleResFormUpdate}
                        whileTap={{
                          scale: 0.9
                        }}
                      >
                        <Save />
                      </motion.div>
                    </div>
                    {
                      clientinfosection === 'Profile'
                      ?
                      (
                        <div className='chatdetails__sectiondetails-wrapper'>
                          <div className='sectiondetails__row-wrapper'>
                            <h6>
                              nombre:
                            </h6>
                            <div className='chatrowwrapper__value-wrapper'>
                              <p
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                {
                                  clientsroomsdata.filter(
                                    (f) => currentroomid === f.id
                                  ).map(
                                    (f) => f.profiles.username
                                  )
                                }
                              </p>
                            </div>
                          </div>
                          <div className='sectiondetails__row-wrapper'>
                            <h6>
                              correo:
                            </h6>
                            <div className='chatrowwrapper__value-wrapper'>
                              <p
                                style={{
                                  textTransform: 'lowercase'
                                }}
                              >
                                {
                                  clientsroomsdata.filter(
                                    (f) => currentroomid === f.id
                                  ).map(
                                    (f) => f.profiles.email
                                  )
                                }
                              </p>
                            </div>
                          </div>
                          <div className='sectiondetails__row-wrapper'>
                            <h6>
                              teléfono:
                            </h6>
                            <div className='chatrowwrapper__value-wrapper'>
                              <p>
                                {
                                  clientsroomsdata.some(
                                    (s) => currentroomid === s.id && s.profiles.phone !== null
                                  )
                                  ?
                                  (
                                    clientsroomsdata.filter(
                                      (f) => currentroomid === f.id
                                    ).map(
                                      (f) => f.profiles.phone
                                    )
                                  )
                                  :
                                  (
                                    'No hay datos'
                                  )
                                }
                              </p>
                            </div>
                          </div>
                          <div className='sectiondetails__row-wrapper'>
                            <h6>
                              tipo de cuenta:
                            </h6>
                            <div className='chatrowwrapper__value-wrapper'>
                              <p
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                {
                                  clientsroomsdata.filter(
                                    (f) => currentroomid === f.id
                                  ).map(
                                    (f) => f.profiles.provider
                                  )
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                      :
                      (
                        <div className='chatdetails__sectiondetails-wrapper'>
                          {
                            reservationsdata.some((s) => currentprofileid[0] === s.user_id)
                            ?
                            (
                              <>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  id:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    ref={resID}
                                  >
                                    {
                                      reservationsdata.filter(
                                        (f) => currentprofileid[0] === f.user_id
                                      ).map(
                                        (f) => f.id
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  estado:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper' style={{ pointerEvents: formisactive === true ? 'all' : 'none', cursor: formisactive === true ? 'pointer' : 'unset' }} onClick={() => setResStatusPicker(!resstatuspicker)} >
                                  <p
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {
                                      resstatus === true
                                      ? 'Confirmada'
                                      : 'Por Confirmar'
                                    }
                                  </p>
                                  <div className='chatresform__picker-wrapper' style={{ display: resstatuspicker === true ? 'flex' : 'none' }}>
                                    <div onClick={() => setResStatus(false)} >
                                      <p>
                                        Por Confirmar
                                      </p>
                                    </div>
                                    <div onClick={() => setResStatus(true)} >
                                      <p>
                                        Confirmada
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  a nombre de:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={nameref}
                                  >
                                    {
                                      reservationsdata.filter(
                                        (f) => currentprofileid[0] === f.user_id
                                      ).map(
                                        (f) => f.name
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  correo:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={emailref}
                                  >
                                    {
                                      reservationsdata.filter(
                                        (f) => currentprofileid[0] === f.user_id
                                      ).map(
                                        (f) => f.email
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  teléfono:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={phoneref}
                                  >
                                    {
                                      reservationsdata.filter(
                                        (f) => currentprofileid[0] === f.user_id
                                      ).map(
                                        (f) => f.phone
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  huéspedes:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={guestsref}
                                  >
                                    {
                                      reservationsdata.filter(
                                        (f) => currentprofileid[0] === f.user_id
                                      ).map(
                                        (f) => f.guests
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  desde:
                                </h6>
                                <div className='rowwrapper__multiplevalue-wrapper'>
                                  <motion.div whileTap={{ scale: 0.9 }} className='rowwrapper__editdate-control' style={{ display: formisactive === true ? 'flex' : 'none' }} onClick={handleDateEdit}>
                                    <Edit style={{ display:  editdates === true ? 'none' : 'flex' }} />
                                    <Cancel style={{ display:  editdates === true ? 'flex' : 'none' }} />
                                  </motion.div>
                                  <div className='rowwrapper__valuevariant-wrapper' style={{ display: editdates === true ? 'none' : 'flex' }}>
                                    <p style={{ textTransform: 'capitalize' }} >
                                      {
                                        dayjs(startdate).format('D - MMM')
                                      }
                                    </p>
                                  </div>
                                  <input 
                                    className='rowwrapper__input-datewrapper'
                                    id='startdate'
                                    name='startdate'
                                    type="date" 
                                    style={{
                                      display: editdates === true ? "flex" : 'none'
                                    }}
                                    defaultValue={dayjs(startdate).format('YYYY-MM-DD')}
                                    onChange={(e) => setStartDate(dayjs(e.target.value))}
                                  />
                                  <div className='rowwrapper__valuevariant-wrapper' style={{ pointerEvents: editdates === true ? 'all' : 'none', cursor: editdates === true ? 'pointer' : 'unset' }} onClick={() => setFromTimeOfDayPicker(!fromtimeofdaypicker)} >
                                    {
                                      fromtimeofday === 'day'
                                      ? <><Day /><p>Día</p></>
                                      : <><Night style={{ color: '#374758' }} /><p>Noche</p></>
                                    }
                                    <div className='chatresform__picker-wrapper' style={{ display: fromtimeofdaypicker === true ? 'flex' : 'none', bottom: '-4.8rem' }}>
                                      <div onClick={() => setFromTimeOfDay('day')} >
                                        <p>
                                          Día
                                        </p>
                                        <Day style={{ color: '#DBAB03' }} />
                                      </div>
                                      <div onClick={() => setFromTimeOfDay('night')}>
                                        <p>
                                          Noche
                                        </p>
                                        <Night style={{ color: '#374758' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  hasta:
                                </h6>
                                <div className='rowwrapper__multiplevalue-wrapper'>
                                  <div className='rowwrapper__valuevariant-wrapper' style={{ display: editdates === true ? 'none' : 'flex' }}>
                                    <p style={{ textTransform: 'capitalize' }} >
                                      {
                                        dayjs(enddate).format('D - MMM')
                                      }
                                    </p>
                                  </div>
                                  <input 
                                    className='rowwrapper__input-datewrapper'
                                    type="date" 
                                    id='enddate'
                                    name='enddate'
                                    style={{
                                      display: editdates === true ? "flex" : 'none'
                                    }}
                                    defaultValue={dayjs(enddate).format('YYYY-MM-DD')}
                                    onChange={(e) => setEndDate(dayjs(e.target.value))}
                                  />
                                  <div className='rowwrapper__valuevariant-wrapper' style={{ pointerEvents: editdates === true ? 'all' : 'none', cursor: editdates === true ? 'pointer' : 'unset' }} onClick={() => setToTimeOfDayPicker(!totimeofdaypicker)} >
                                    {
                                      totimeofday === 'twilight'
                                      ? <><Day /><p>Día</p></>
                                      : <><Night style={{ color: '#374758' }} /><p>Noche</p></>
                                    }
                                    <div className='chatresform__picker-wrapper' style={{ display: totimeofdaypicker === true ? 'flex' : 'none', bottom: '-4.8rem' }}>
                                      <div onClick={() => setToTimeOfDay('twilight')} >
                                        <p>
                                          Día
                                        </p>
                                        <Day style={{ color: '#DBAB03' }} />
                                      </div>
                                      <div onClick={() => setToTimeOfDay('afternoon')}>
                                        <p>
                                          Noche
                                        </p>
                                        <Night style={{ color: '#374758' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  tipo de pago:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper' style={{ pointerEvents: formisactive === true ? 'all' : 'none', cursor: formisactive === true ? 'pointer' : 'unset' }} onClick={() => setPaymentTypePicker(!paymenttypepicker)} >
                                  <p
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {
                                      paymenttype === 'Cuotas'
                                      ? 'Cuotas'
                                      : paymenttype === 'Único'
                                      ? 'Único'
                                      : 'Por Definir'
                                    }
                                  </p>
                                  <div className='chatresform__picker-wrapper' style={{ display: paymenttypepicker === true ? 'flex' : 'none' }} >
                                    <div onClick={() => setPaymentType('Cuotas')} >
                                      <p>
                                        Cuotas
                                      </p>
                                    </div>
                                    <div onClick={() => setPaymentType('Único')} >
                                      <p>
                                        Único
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div 
                                className='sectiondetails__row-wrapper'
                                style={{
                                  display: paymenttype === 'Cuotas' ? 'flex' : 'none'
                                }}
                              >
                                <h6>
                                  # de cuotas:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={numberofpaymentsref}
                                  >
                                    {
                                      reservationsdata.some((s) => currentprofileid[0] === s.user_id && s.payment_type !== null)
                                      ? reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => f.number_of_payments)
                                      : 'Por Definir'
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='sectiondetails__row-wrapper'>
                                <h6>
                                  monto acordado:
                                </h6>
                                <div className='chatrowwrapper__value-wrapper'>
                                  <p
                                    contentEditable={
                                      formisactive === true ? 'true' : 'false'
                                    }
                                    ref={amountagreedref}
                                  >
                                    {
                                      reservationsdata.some((s) => currentprofileid[0] === s.user_id && s.amount_agreed !== null)
                                      ? reservationsdata.filter((f) => currentprofileid[0] === f.user_id).map((f) => f.amount_agreed)
                                      : 'Por Definir'
                                    }
                                  </p>
                                </div>
                              </div>
                              </>
                            )
                            :
                            (
                              <>
                              {
                                newreservation === false
                                ?
                                (
                                  <div className='sectiondetails__row-wrapper'>
                                    <h6
                                      style={{
                                        textTransform: 'unset'
                                      }}
                                    >
                                      No hay reservaciones
                                    </h6>
                                    <motion.div whileTap={{ scale: 0.9 }} className='rowwrapper__createreservation-cta' onClick={() => setNewReservations(true)}>
                                      <p>
                                        Crear Reservación
                                      </p>
                                      <ReservationForm />
                                    </motion.div>
                                  </div>
                                )
                                :
                                (
                                  <>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        id:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          ref={resID}
                                        >
                                          Nuevo Registro
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        estado:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper' style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setResStatusPicker(!resstatuspicker)} >
                                        <p
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {
                                            resstatus === true
                                            ? 'Confirmada'
                                            : 'Por Confirmar'
                                          }
                                        </p>
                                        <div className='chatresform__picker-wrapper' style={{ display: resstatuspicker === true ? 'flex' : 'none' }}>
                                          <div onClick={() => setResStatus(false)} >
                                            <p>
                                              Por Confirmar
                                            </p>
                                          </div>
                                          <div onClick={() => setResStatus(true)} >
                                            <p>
                                              Confirmada
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        a nombre de:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                          contentEditable='true'
                                          placeholder='Ingresar Nombre'
                                          ref={nameref}
                                        >
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        correo:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          contentEditable='true'
                                          ref={emailref}
                                          placeholder='Ingresar Correo'
                                        >
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        teléfono:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          contentEditable='true'
                                          ref={phoneref}
                                          placeholder='Ingresar Teléfono'
                                        >
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        huéspedes:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                          contentEditable='true'
                                          ref={guestsref}
                                          placeholder='e.g: 10'
                                        >
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        desde:
                                      </h6>
                                      <div className='rowwrapper__multiplevalue-wrapper'>
                                        <input 
                                          className='rowwrapper__input-datewrapper'
                                          id='startdate'
                                          name='startdate'
                                          type="date" 
                                          min={dayjs(currentDay).format('YYYY-MM-DD')}
                                          defaultValue={dayjs(currentDay).format('YYYY-MM-DD')}
                                          onChange={(e) => setStartDate(dayjs(e.target.value))}
                                        />
                                        <div className='rowwrapper__valuevariant-wrapper' style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setFromTimeOfDayPicker(!fromtimeofdaypicker)} >
                                          {
                                            fromtimeofday === 'day'
                                            ? <><Day /><p>Día</p></>
                                            : <><Night style={{ color: '#374758' }} /><p>Noche</p></>
                                          }
                                          <div className='chatresform__picker-wrapper' style={{ display: fromtimeofdaypicker === true ? 'flex' : 'none', bottom: '-4.8rem' }}>
                                            <div onClick={() => setFromTimeOfDay('day')} >
                                              <p>
                                                Día
                                              </p>
                                              <Day style={{ color: '#DBAB03' }} />
                                            </div>
                                            <div onClick={() => setFromTimeOfDay('night')}>
                                              <p>
                                                Noche
                                              </p>
                                              <Night style={{ color: '#374758' }} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        hasta:
                                      </h6>
                                      <div className='rowwrapper__multiplevalue-wrapper'>
                                        <input 
                                          className='rowwrapper__input-datewrapper'
                                          type="date" 
                                          id='enddate'
                                          name='enddate'
                                          min={dayjs(currentDay).format('YYYY-MM-DD')}
                                          defaultValue={dayjs(currentDay).add(1, 'day').format('YYYY-MM-DD')}
                                          onChange={(e) => setEndDate(dayjs(e.target.value))}
                                        />
                                        <div className='rowwrapper__valuevariant-wrapper' style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setToTimeOfDayPicker(!totimeofdaypicker)} >
                                          {
                                            totimeofday === 'twilight'
                                            ? <><Day /><p>Día</p></>
                                            : <><Night style={{ color: '#374758' }} /><p>Noche</p></>
                                          }
                                          <div className='chatresform__picker-wrapper' style={{ display: totimeofdaypicker === true ? 'flex' : 'none', bottom: '-4.8rem' }}>
                                            <div onClick={() => setToTimeOfDay('twilight')} >
                                              <p>
                                                Día
                                              </p>
                                              <Day style={{ color: '#DBAB03' }} />
                                            </div>
                                            <div onClick={() => setToTimeOfDay('afternoon')}>
                                              <p>
                                                Noche
                                              </p>
                                              <Night style={{ color: '#374758' }} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        tipo de pago:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper' style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setPaymentTypePicker(!paymenttypepicker)} >
                                        <p
                                          style={{
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          {
                                            paymenttype === 'Cuotas'
                                            ? 'Cuotas'
                                            : paymenttype === 'Único'
                                            ? 'Único'
                                            : 'Por Definir'
                                          }
                                        </p>
                                        <div className='chatresform__picker-wrapper' style={{ display: paymenttypepicker === true ? 'flex' : 'none' }} >
                                          <div onClick={() => setPaymentType('Cuotas')} >
                                            <p>
                                              Cuotas
                                            </p>
                                          </div>
                                          <div onClick={() => setPaymentType('Único')} >
                                            <p>
                                              Único
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div 
                                      className='sectiondetails__row-wrapper'
                                      style={{
                                        display: paymenttype === 'Cuotas' ? 'flex' : 'none'
                                      }}
                                    >
                                      <h6>
                                        # de cuotas:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          contentEditable='true'
                                          ref={numberofpaymentsref}
                                          placeholder='e.g: 6'
                                        >
                                        </p>
                                      </div>
                                    </div>
                                    <div className='sectiondetails__row-wrapper'>
                                      <h6>
                                        monto acordado:
                                      </h6>
                                      <div className='chatrowwrapper__value-wrapper'>
                                        <p
                                          contentEditable='true'
                                          ref={amountagreedref}
                                          placeholder="e.g: 800.00"
                                        >
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              </>
                            )
                          }
                        </div>
                      )
                    }
                    </>
                  )
                )
              }
            </div>
          </motion.div>
        </>
        )
      }
      
    </div>
  )
}

export default Chat