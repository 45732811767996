import { styled, css } from '../../../stitches.config';

export const MainWrapper = styled("div", {
  display: 'flex',
  flexDirection: 'column',
  width: 'clamp(760px, 121rem, 75vw)',
  height: '100%',
  borderRadius: 32,
  padding: 'clamp(1.5rem, 2rem, 1.7vw)',
  backgroundColor: "#D4E1F0",
  gap: '24px',
  userSelect: 'none',
  zIndex: 1,

  '@bp1': {
    width: '100%',
    height: 'fit-content',
  },
});

export const CalendarHeaderWrapper = styled("div", {
  display: "flex",
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 'clamp(1em, 2em, 1.7vw)',
  color: '#374758',
  textTransform: 'uppercase',
  fontWeight: '700',
  fontStyle: 'normal',
  background: 'none',
  letterSpacing: '0.04em',
  marginBottom: 8,

  'h3': {
    background: 'none',
  },

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: 24,
    background: 'none',
    alignItems: 'center',

    'svg': {
        background: 'none',
        fontSize: 'clamp(1.5em, 3em, 2.5vw)',
        color: '#374758',
        cursor: 'pointer'
    }
  }
});

export const WeekDaysWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: '100%',
  background: 'none',
});

export const WeekDayCell = styled("div", {
  height: 'clamp(38px, 100px, 7.3vw)',
  width: 'clamp(38px, 140px, 7.3vw)',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#374758",
  backgroundColor: '#B7CEE9',
  borderRadius: 12,
  fontSize: 'clamp(0.75em, 1.25em, 1.75vw)',
  fontWeight: '700',
  letterSpacing: '0.04em',
  textTransform: 'uppercase'
});

export const CalendarContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: '100%',
  justifyContent:  'space-between',
  background: 'none',
});

export const CalendarDayCell = styled("div", {
  height: 'clamp(38px, 100px, 7.3vw)',
  width: 'clamp(38px, 140px, 7.3vw)',
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  background: 'none',
  fontSize: 'clamp(0.75em, 1.25em, 1.75vw)',
  fontWeight: '700',
  letterSpacing: '0.04em',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-out, border 0.3s ease-out',
  color: '#374758',

  'svg': {
    display: 'flex',
    position: 'absolute',
    fontSize: 'clamp(0.675em, 1em, 0.875vw)',
    color: '#374758',
    background: 'none',
    marginTop: 'clamp(1.5rem, 3.5rem, 2.91vw)'
  },

  'div': {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: 'fit-content',
    height: 'fit-content',
    background: 'none',
    gap: '2px',
    alignItems: 'center',

    'svg': {
      display: 'flex',
      position: 'relative',
      fontSize: 'clamp(0.675em, 1em, 0.875vw)',
      color: '#FFFFFF',
      background: 'none',
    },
  },

});

export const DetailsWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  right: 0,
  width: 'clamp(400px, 44rem, 27.5vw)',
  height: '61.5rem',
  alignItems: 'flex-start',
  zIndex: '0',
  backgroundColor: '#B7CEE9',
  borderRadius: '32px',
  paddingTop: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingRight: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingBottom: 'clamp(1.5rem, 2rem, 1.7vw)',
  paddingLeft: 'clamp(5rem, 5.75rem, 6vw)',
  gap: '36px',
  userSelect: 'none',

  '@bp1': {
    position: 'relative',
    width: '100%',
    padding: 'clamp(1.5rem, 2rem, 1.7vw)',
    paddingTop: '6rem',
    top: '-4rem',
    height: 'fit-content',
  }
});

export const DetailsHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 'fit-content',
  background: 'none',

  'h3': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 'clamp(1em, 1.5em, 1.5vw)',
    letterSpacing: '0.04em',
    color: '#374758',
    textTransform: 'uppercase',
    background: 'none',
  },

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '16px',
    background: 'none',
    
    'svg': {
      fontSize: 'clamp(1.5em, 2.5em, 2vw)',
      color: '#374758',
      background: 'none',
      cursor: 'pointer',
    }
  },
});

export const DetailsBkdown = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  width:'100%',
  height: 'fit-content',
  background: 'none',
  gap: '20px',
});

export const BkdownHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  background: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',

  'h4': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.875em, 1.25em, 1.25vw)',
    fontWeight: '700',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#374758',
    background: 'none',
    textTransform:  'uppercase',
  },

  'svg': {
    fontSize: 'clamp(1em, 1.5em, 1.5vw)',
    color: '#374758',
    background: 'none',
  }
});

export const BkdownContent = styled('div',{
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  gap: '8px',
  background: 'none',
});

export const BkdownContentStatus = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  borderRadius: '6px',
  backgroundColor: '#D4E1F0',
  alignItems:'center',
  justifyContent: 'space-between',
  padding: '6px',
});

export const EntryRow = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px',
  backgroundColor: '#B7CEE9',
  borderRadius: '6px',
  cursor: 'pointer',
  textDecoration: 'none',
})

export const EntryRowTypeWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  maxWidth: '200px',
  height: '100%',
  alignItems: 'center',
  gap: '8px',
  background: 'none',
  overflow: 'hidden',

  'svg': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    background: 'none',
    fontSize: '2.625em',
  },

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: 'none',
    overflow: 'hidden',

    'h5': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1em',
      color: '#374758',
      background: 'none',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    'p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.75em',
      color: '#607387',
      background: 'none',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
    },
  }
})

export const EntryRowValuesWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: 'fit-content',
  maxWidth: '120px',
  height: '100%',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  overflow: 'hidden',
  background: 'none',
})

export const EntryRowValueTotal = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'flex-end',
  background: 'none',

  'h5': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '1em',
    color: '#374758',
    background: 'none',
    width: '100%',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
})

export const EntryRowValueDetailsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',
  gap: '6px'
})

export const EntryRowValueDetailPill = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 5px',
  borderRadius: '6px',
  backgroundColor: '#D4E1F0',

  'p': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    color: '#374758',
    background: 'none',
  }
})

export const EntryRowValueDetailUnit = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  background: 'none',

  'p': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    color: '#607387',
    background: 'none',
  }
})

export const ViewAllCTA = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px',
  gap: '4px',
  border: '1px solid #374758',
  borderRadius: '6px',
  transition: 'background-color 0.4s ease-out',
  background: 'none',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#374758',

    'p': {
      color: '#EBF2FA',
    }
  },

  'p': {
    color: '#374758',
    background: 'none',
    fontWeight: '400',
    fontSize: 'clamp(0.875em, 1em, 1vw)',
  }
})

export const AddNewEntryWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  background: 'none',
  alignItems: 'center',
  justifyContent: 'center',
})

export const AddNewEntryCTA = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#374758',
  padding: '6px',
  gap: '4px',
  borderRadius: '6px',
  top: '-1.5rem',
  cursor: 'pointer',

  'p': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 'clamp(0.875em, 1em, 1vw)',
    color: '#D4E1F0',
    background: 'none',
    textTansform: 'capitalize',
  },

  'svg': {
    fontSize: 'clamp(0.875em, 1em, 1vw)',
    color: '#D4E1F0',
    background: 'none',
  }
})

export const ContentStatusPeriod = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  background: 'none',
  gap: '8px',
  alignItems: 'center',

  'svg': {
    fontSize: 'clamp(1em, 1.25em, 1.25vw)',
    background: 'none',
  },

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1.25em, 1.25vw)',
    fontWeight: '400',
    color: '#374758',
    background: 'none',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
});

export const ContentStatusState = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'none',
  background: 'none',

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1.25em, 1.25vw)',
    fontWeight: '400',
    color: '#374758',
    background: 'none',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
});

export const DetailsAvailable = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  gap: '20px',
  alignItems: 'flex-start',
  background: 'none',
});

export const AvailableHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',

  'h4': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.875em, 1.25em, 1.25vw)',
    fontWeight: '700',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#374758',
    background: 'none',
    textTransform: 'uppercase',
  },
  

  'svg': {
    fontSize:'clamp(1.25em, 1.5em, 1.25vw)',
    color: '#374758',
    background: 'none',
  },
});

export const AvailableContent = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width : '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  gap: '8px',
  background: 'none',
});

export const AvailableContentContainer = styled('div', {
  display: 'flex',
  position:'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  borderRadius: '6px',
  backgroundColor: '#D4E1F0',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '6px',
});

export const ContentDay = styled('div',{
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  background: 'none',
  alignItems: 'flex-start',

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1.25em, 1.25vw)',
    fontWeight: '400',
    color: '#374758',
    background: 'none',
    textAlign: 'left',
    textTransform: 'capitalize',
  }
});

export const ContentPeriods = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  background: 'none',
  alignItems: 'center',
  gap: '8px',

  'svg': {
    fontSize: 'clamp(1em, 1.5em, 1.25vw)',
    background: 'none',
  }
});

export const ContentMore = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '6px',
  background: 'none',

  'svg': {
    fontSize: 'clamp(1em, 1.5em, 1.25vw)',
    color: '#374758',
    background: 'none',
  },
});

export const AvailableCta = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  marginTop: '28px',
});

export const FormWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  gap: '20px',
  alignItems: 'flex-start',
  background: 'none',
});

export const FormHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',

  'h3': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 'clamp(0.875em, 1.25em, 1.25vw)',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: '#374758',
    background: 'none',
  },

  'svg': {
    fontSize: 'clamp(1em, 1.5em, 1.5vw)',
    color: '#374758',
    background: 'none',
  },
});

export const FormContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  gap: '12px',
  background: 'none',
});

export const FormContainerField = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#D4E1F0',
  borderRadius: '6px',
  padding: '6px',
});

export const FieldTitle = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  background: 'none',

  'p': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 'clamp(0.75em, 1em, 1vw)',
    color: '#374758',
    textTransform: 'uppercase',
    background: 'none',
    textAlign: 'left',
  },
});

export const FieldInput = styled('input', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  maxWidth: 'clamp(120px, 290px, 15.2vw)',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#B7CEE9',
  border: 'none',
  fontStyle: 'normal',
  fontSize: 'clamp(0.75em, 1.25em, 1vw)',
  fontWeight: '400',
  color: '#374758',
  textAlign: 'center',
  textDecoration: 'none',
  textShadow: 'none',
  boxShadow: 'none',
  outline: 'none',
  appearance:'none',
  transition: 'all 0.3s ease-out',

  '&:focus': {
    border: 'none',
    appearance: 'none',
    textShadow: 'none',
    boxShadow: 'none',
    outline: 'none'
  },

  '&::placeholder': {
    color: 'rgba(48, 58, 70, 0.6)',
  },
});

export const FieldInputDateWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'none',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
  background: 'none',
});

export const DateNavigator = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'none',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  gap: '6px',
  background: 'none',

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'none',
    width: 'fit-content',
    height: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#B7CEE9',
    borderRadius: '6px',
    cursor: 'pointer',
    padding: '4px',

    'svg': {
      fontSize: 'clamp(1em, 1.25em, 1vw)',
      color: '#374758',
      background: 'none',
    }
  },
})

export const FieldInputDates = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: 'fit-content',
  maxWidth: '292px',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#B7CEE9',
  fontStyle: 'normal',
  fontSize: 'clamp(0.75em, 1.25em, 1vw)',
  fontWeight: '400',
  color: '#374758',
  textAlign: 'center',
  textTransform: 'capitalize',
  userSelect: 'none',
})

export const FieldInputDateTime = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'none',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#B7CEE9',
  cursor: 'pointer',

  'svg': {
    fontSize: 'clamp(1em, 1.25em, 1vw)',
    background: 'none',
  },

  'p': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 'clamp(0.75em, 1.25em, 1vw)',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#374758',
    background: 'none',
  },
});

export const FieldInputDateTimePicker = styled('div', {
  display: 'none',
  position: 'absolute',
  flexDirection: 'column',
  flexWrap: 'none',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '6px',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#D4E1F0',
  bottom: '-5.3rem',
  overflow: 'hidden',
  zIndex: 2,

  'div': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    borderRadius: '4px',
    padding: '4px',
    gap: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',

    '&:hover': {
      backgroundColor: '#B7CEE9'
    },

    'svg': {
      fontSize: 'clamp(1em, 1.25em, 1vw)',
      background: 'none',
    },
  
    'p': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 'clamp(0.75em, 1.25em, 1vw)',
      textAlign: 'left',
      textTransform: 'capitalize',
      color: '#374758',
      background: 'none',
    },
  }
})

export const FormCtas = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  background: 'none',
  marginTop: '12px',
  marginBottom: '12px'
});

export const FormFAQs = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  padding: '12px',
  gap: '20px',
  backgroundColor: '#DBAB03',
  borderRadius: '6px'
});

export const FormFAQsHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',


  'h4': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 'clamp(0.875em, 1.25em, 1.25vw)',
    letterSpacing: '0.04em',
    color: '#15212D',
    textTransform: 'uppercase',
    background: 'none',
  },

  'svg': {
    fontSize: 'clamp(1em, 1.5em, 1.5vw)',
    color: '#15212D',
    background: 'none',
  },
});

export const FormFAQsContent = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'none',
  width: '100%',
  height: 'fit-content',
  alignItems: 'flex-start',
  gap: '12px',
  background: 'none'
});

export const FAQsContentRow = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'none',

  'p': {
    fontStyle: 'normal',
    fontSize: 'clamp(0.75em, 1.25em, 1vw)',
    fontWeight: '400',
    color: '#15212D',
    background: 'none',
  }
})