import { Route, Routes, useLocation } from "react-router";
import axios from 'axios';
import OneSignal from 'react-onesignal';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Auth, Dashboard, Reservations, Finance, AllEntradas, Chat } from "./pages/Index";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";

function App() {
  const location = useLocation();
  const oneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID
  const [osinitialized, setOsInitialized] = useState(false);
  
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  useEffect(() => {
    if (session) {
      initOneSignal();
    }
  }, [session])

  const initOneSignal = async () => {
    try {
      const response = await OneSignal.init({
        appId: oneSignalAppId,
        notifyButton: {
          enable: true,
        }
      })
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setOsInitialized(true);
    }
  }

  useEffect(() => {
    if (osinitialized === true) {
      const init = async () => {
        const playerId = await createNewPlayer();
        // await subscribeToChatRoom(playerId);
      };

      init();
    }
  }, [osinitialized])

  const createNewPlayer = async () => {
    const { user } = session

    const response = await axios.post("https://onesignal.com/api/v1/players", {
      app_id: oneSignalAppId,
      device_type: 2,
      identifier: user.id,
      language: "es",
      timezone: -6.0,
    });

    return response.data.id
  }

  return (
    <>
      {!session ? (
        <Auth />
      ) : 
      (
      <>
      <Navbar />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Dashboard session={session}/>} />
          <Route path="/reservaciones" element={<Reservations session={session} />} exact />
          <Route path="/finanzas" element={<Finance session={session} />} exact />
          <Route path="/filtro" element={<AllEntradas session={session} />} exact />
          {/* <Route path="/cuenta" element={<Account key={session.user.id} session={session}/>} /> */}
          <Route path="/chat" element={<Chat session={session} />} exact />
        </Routes>
      <Footer />
      </>
      )
      }
    </>
  );
}

export default App;
